<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-xl font-bold text-th-text-secondary">{{ leave ? 'Leave Team' : 'Delete Team'}}</div>
        </div>
        <div>
          <div class="flex flex-col w-full">
            <span class="text-th-text-secondary uppercase pb-2 text-sm">Are you sure you want to {{ leave ? 'leave ' : 'delete ' }} {{ teamName }}?</span>
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="!submitted"
            v-on="teamName ? { click: confirm } : {}"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase"
            :class="{'opacity-60': !teamName, 'cursor-pointer': teamName}"
          >{{ leave ? 'Leave' : 'Delete'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'

export default {
  components: {
    CloseIcon,
  },
  props: {
    leave: {
      type: Boolean,
      required: false,
    },
  },
  data () {
    return {
      visible: false,
      item: undefined,
      categoryId: undefined,
      teamName: undefined,
      collectionDescription: undefined,
      result: undefined,
      submitted: false,
    }
  },
  methods: {
    open (team) {
      this.visible = true
      this.teamName = team
    },
    close () {
      this.visible = false
    },
    async confirm () {
      if (this.leave === true) {
        this.$emit('leave')
      } else {
        this.$emit('delete')
      }
      this.visible = false
    },
  },
}
</script>

<style>
</style>
