<template>
  <div v-if="user">
    <Toast
      v-model="showProfileUpdateError"
      type="danger"
      msg="Error updating profile. Please try again."
      :autoClose="3"
    />
    <Toast
      v-model="showPasswordResetSuccess"
      type="success"
      msg="Password successfully updated."
      :autoClose="3"
    />
    <Toast
      v-model="showPasswordResetError"
      type="danger"
      msg="Error resetting password. Please try again."
      :autoClose="3"
    />
    <Toast
      v-model="showProfileUpdateSuccess"
      type="success"
      msg="Profile successfully updated."
      :autoClose="3"
    />
    <Modal
      ref="resetCompletionModal"
      title="Are you sure you want to reset your challenge completions?"
      submitLabel="Yes - Reset"
      cancelLabel="No - Cancel"
      @submit="resetChallengeCompletions"
    >
      <div>
        Resetting your Completions will reset your score to 0 and re-enable jeopardy
        questions that you've already answered correctly.
      </div>
    </Modal>
    <div class="border-b border-th-text pb-6 mb-4">
      <div class="text-th-text text-3xl font-extrabold uppercase mb-4">
        Logout
      </div>
      <div
        @click="logout"
        class="bg-th-secondary border-th-text-secondary border-2 text-th-text-secondary text-opacity-75 px-4 py-2 w-full lg:w-1/5 font-bold text-center rounded-md cursor-pointer"
      >
        LOGOUT
      </div>
    </div>
    <div class="border-b border-th-text pb-6 mb-4 space-y-4">
      <div class="text-th-text text-3xl font-extrabold uppercase">
        Profile Information
      </div>
      <div class="space-y-4">
        <div class="flex flex-col border-b border-th-text w-full lg:w-1/2 pr-2">
          <span class="text-th-text uppercase pb-2 font-bold"
            >Email Address</span
          >
          <div class="text-th-text">{{ user.email }}</div>
        </div>
        <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
          <div class="flex flex-col w-full">
            <ValidatedInputField
              label="First Name"
              type="text"
              v-model="$v.profile.firstName.$model"
              :fieldError="$v.profile.firstName.$invalid"
              :errorMessage="errorMessage('firstName')"
            />
          </div>
          <div class="flex flex-col w-full">
            <ValidatedInputField
              label="Last Name"
              type="text"
              v-model="$v.profile.lastName.$model"
              :fieldError="$v.profile.lastName.$invalid"
              :errorMessage="errorMessage('lastName')"
            />
          </div>
        </div>
        <div class="grid lg:grid-cols-2 gap-4">
          <div v-for="field in theme?.customUserFields"
              :key="field._id" class="flex flex-col w-full">
            <ValidatedInputField
              :label="field.name"
              type="text"
              v-model.trim="$v.profile.customUserFields[field._id].$model"
              :fieldError="$v.profile.customUserFields[field._id].$invalid"
              :errorMessage="errorMessage('customField')"
            />
          </div>
        </div>
        <div class="flex justify-end">
          <div
            @click="!$v.profile.$invalid ? updateProfile() : ''"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md uppercase"
            :class="{'opacity-50' : $v.profile.$invalid, 'opacity-100 cursor-pointer': !$v.profile.$invalid}"
          >
            Update Profile
          </div>
        </div>
      </div>
    </div>

    <div class="border-b border-th-text pb-6 mb-4 space-y-4">
      <div class="text-th-text text-opacity-75 text-3xl font-extrabold uppercase">
        Change Password
      </div>
      <div>
        <div class="font-bold">Password Complexity Requirements</div>
        <ul class="list-disc list-inside text-sm">
          <li v-for="req in passwordRequirements" :key="req">{{ req }}</li>
        </ul>
      </div>
      <form @submit.prevent="resetPassword">
        <input type="text" autocomplete="username" class="hidden" />
        <div class="space-y-4">
          <div class="flex flex-col w-full lg:w-1/2 pr-2">
            <span class="text-th-text uppercase pb-2 font-bold"
              >Current Password</span
            >
            <input
              type="password"
              autocomplete="new-password"
              v-model="$v.passwordReset.currentPassword.$model"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded px-2 py-2 focus:outline-none"
            />
          </div>
          <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
            <div class="flex flex-col w-full">
              <span class="text-th-text uppercase pb-2 font-bold"
                >New Password</span
              >
              <input
                type="password"
                v-model="$v.passwordReset.newPassword.$model"
                autocomplete="new-password"
                class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded px-2 py-2 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full">
              <span class="text-th-text text-opacity-50 uppercase pb-2 font-bold"
                >Confirm New Password</span
              >
              <input
                type="password"
                v-model="$v.passwordReset.newPasswordConfirmation.$model"
                autocomplete="new-password"
                class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded px-2 py-2 focus:outline-none"
              />
            </div>
          </div>
          <div class="flex justify-end">
            <div
              @click="!$v.passwordReset.$invalid ? resetPassword() : ''"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md uppercase"
              :class="{'opacity-50' : $v.passwordReset.$invalid, 'opacity-100 cursor-pointer': !$v.passwordReset.$invalid}"
            >
              Update Password
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="pb-6 mb-4">
      <SelectInput
        class="mb-4 w-96"
        v-model="completionSelection"
        inputLabel="Completions"
        :options="completionOptions"
        :undefinedOption="false"
      />
      <div v-if="completionSelection === 'Lessons'" class="text-center">
        <div
          class="flex flex-row items-center text-lg text-center justify-center uppercase font-bold py-2 border-b border-th-text text-th-text"
        >
          <div class="w-1/2">Lesson</div>
          <div class="w-1/2">Completed On</div>
        </div>
        <div v-if="user.completions.lessons.length === 0" class="italic py-4">
          No completed lessons.
        </div>
        <div v-else
          v-for="(completion, index) in user.completions.lessons"
          :key="`user-lesson-${index}`"
          class="flex text-center py-4 border-b border-th-text"
        >
          <div class="w-1/2 line-clamp-1">{{ completion.lesson.title }}</div>
          <div class="w-1/2">{{ completion.timestamp | simpleDate }}</div>
        </div>
      </div>

      <div v-else-if="completionSelection === 'Practice Modules'" class="text-center">
        <div
          class="flex flex-row items-center text-lg text-center justify-center uppercase font-bold py-2 border-b border-th-text text-th-text"
        >
          <div class="w-1/2">Practice Module</div>
          <div class="w-1/2">Completed On</div>
        </div>
        <div v-if="user.completions.practiceModules.length === 0" class="italic py-4">
          No completed practice modules.
        </div>
        <div v-else
          v-for="(completion, index) in user.completions.practiceModules"
          :key="`user-practiceModule-${index}`"
          class="flex text-center py-4 border-b border-th-text"
        >
          <div class="w-1/2 line-clamp-1">{{ completion.practiceModule.title }}</div>
          <div class="w-1/2">{{ completion.timestamp | simpleDate }}</div>
        </div>
      </div>

      <div v-else-if="completionSelection === 'Challenges'" class="text-center">
        <div class="flex flex-row justify-end">
          <div class="flex flex-col mb-4">
            <div
              class="bg-th-secondary text-th-text-secondary border-th-text-secondary border-2 px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
              @click="$refs.resetCompletionModal.open()"
            >
              Reset Completions
            </div>
          </div>
        </div>

        <div
          class="flex flex-row items-center text-lg text-center justify-center uppercase font-bold py-2 border-b border-th-text text-th-text"
        >
          <div class="w-1/3">Question</div>
          <div class="w-1/3">Points</div>
          <!-- <div class="w-1/4">Assisted?</div> -->
          <div class="w-1/3">Completed On</div>
        </div>
        <div v-if="user.completions.challenges.length === 0" class="italic py-4">
          No completed challenges.
        </div>
        <div
          v-for="(completion, index) in user.completions.challenges"
          :key="`user-completion-${index}`"
          class="flex text-center py-4 border-b border-th-text"
        >
          <div class="w-1/3 line-clamp-1">{{ completion.question }}</div>
          <div
            class="w-1/3 font-bold text-th-text"
          >
            {{ getPointValue(completion.level) }}
          </div>
          <!-- <div class="w-1/4 line-clamp-1">
            {{ completion.assisted ? "YES" : "NO" }}
          </div> -->
          <div class="w-1/3">{{ completion.timestamp | simpleDate }}</div>
        </div>
      </div>

      <div v-else-if="completionSelection === 'Assessments'" class="text-center">
        <div
          class="flex flex-row items-center text-lg text-center justify-center uppercase font-bold py-2 border-b border-th-text text-th-text"
        >
          <div class="w-1/3">Assessment</div>
          <div class="w-1/3">Result</div>
          <div class="w-1/3">Passed</div>
          <div class="w-1/3">Submitted On</div>
        </div>
        <div v-if="submissions.length === 0" class="italic py-4">
          No submitted assessments.
        </div>
        <div v-else
          v-for="(submission, index) in submissions"
          :key="`user-submission-${index}`"
          class="flex text-center py-4 border-b border-th-text"
        >
          <div class="w-1/4 line-clamp-1">{{ submission.assessment.title }}</div>
          <div class="w-1/4">{{ Math.floor(submission.score * 100) / 100 + '%' }}</div>
          <div class="w-1/4">{{ submission.score >= submission.assessment.passScore }}</div>
          <div class="w-1/4">{{ submission.submittedAt | simpleDate }}</div>
        </div>
      </div>
      <div v-else-if="completionSelection === 'Badges'" class="text-center">
        <div
          class="flex flex-row items-center text-lg text-center justify-center uppercase font-bold py-2 border-b border-th-text text-th-text"
        >
          <div class="w-1/2">Badge</div>
          <div class="w-1/2">Completed On</div>
        </div>
        <div v-if="user.completions.badges.length === 0" class="italic py-4">
          No completed badges.
        </div>
        <div v-else
          v-for="(completion, index) in user.completions.badges"
          :key="`user-badge-${index}`"
          class="flex text-center py-4 border-b border-th-text"
        >
          <div class="w-1/2 line-clamp-1">{{ completion.badge.title }}</div>
          <div class="w-1/2">{{ completion.timestamp | simpleDate }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Modal from '@/components/utilities/Modal.vue'
import SelectInput from '@/components/utilities/SelectInput.vue'
import ValidatedInputField from '@/components/utilities/ValidatedInputField.vue'
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators'
import { complexPassword, passwordRequirements } from '@/utilities/complexPassword'

const ERROR_MESSAGE_MAP = {
  firstName: 'Please provide a value for first name',
  lastName: 'Please provide a value for last name',
  customField: 'Please provide a value for this field',
}

export default {
  components: {
    Modal,
    SelectInput,
    ValidatedInputField,
  },
  async mounted () {
    await this.$store.dispatch('auth/fetchProfile')
    await this.$store.dispatch('compete/fetchUserSubmissions', { userId: this.user.id })
  },
  created () {
    this.profile.customUserFields = this.customFieldMap
  },
  computed: {
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    user () {
      return this.$store.getters['auth/user']
    },
    sstFilter () {
      return this.$store.getters['auth/sstFilterEnabled']
    },
    completionOptions () {
      return this.theme?.completionTrackingEnabled ? ['Lessons', 'Practice Modules', 'Challenges', 'Assessments', 'Badges'] : ['Challenges', 'Assessments', 'Badges']
    },
    submissions () {
      return this.$store.getters['compete/submissions']
    },
    customFieldMap () {
      const fieldMap = {}
      if (this.theme && this.user) {
        for (const fieldDef of this.theme?.customUserFields) {
          const userField = this.user?.customUserFields?.find((fieldValue) => fieldValue.definition === fieldDef._id)
          fieldMap[fieldDef._id] = userField?.value
        }
      }
      return fieldMap
    },
    customFieldValidations () {
      const validations = {}
      if (this.theme) {
        for (const field of this.theme?.customUserFields) {
          validations[field._id] = { required: requiredIf(() => field.required) }
        }
      }
      return validations
    },
  },
  data () {
    return {
      showProfileUpdateError: false,
      showProfileUpdateSuccess: false,
      selected: '',
      profile: {
        firstName: this.$store.getters['auth/user'].firstName,
        lastName: this.$store.getters['auth/user'].lastName,
        customUserFields: this.customFieldMap,
      },
      showPasswordResetSuccess: false,
      showPasswordResetError: false,
      passwordReset: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      passwordRequirements,
      completionSelection: this.$store.getters['metadata/siteMetadata']?.completionTrackingEnabled ? 'Lessons' : 'Challenges',
      siteMetadata: undefined,
    }
  },
  validations () {
    return {
      profile: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        customUserFields: {
          ...this.customFieldValidations,
        },
      },
      passwordReset: {
        currentPassword: {
          required,
        },
        newPassword: {
          required,
          complexPassword,
        },
        newPasswordConfirmation: {
          sameAs: sameAs(function () {
            return this.passwordReset.newPassword
          }),
        },
      },
    }
  },
  methods: {
    errorMessage (field) {
      return ERROR_MESSAGE_MAP[field]
    },
    async updateProfile () {
      const success = await this.$store.dispatch('auth/updateUser', this.profile)
      if (success) {
        this.showProfileUpdateSuccess = true
      } else {
        this.showProfileUpdateError = true
      }
    },
    getPointValue (challengeLevel) {
      switch (challengeLevel) {
        case 'BRONZE':
          return 100
        case 'SILVER':
          return 300
        case 'GOLD':
          return 500
      }
    },
    toggleSST () {
      this.$store.commit('auth/toggleSST')
    },
    resetChallengeCompletions () {
      this.$store.dispatch('auth/resetChallengeCompletions')
    },
    async resetPassword () {
      this.showPasswordResetError = false
      this.$v.passwordReset.$touch()
      if (this.$v.passwordReset.$invalid) {
        console.log('Error....')
        this.showPasswordResetError = true
        return
      }
      const success = await this.$store.dispatch('auth/resetPassword', {
        currentPassword: this.$v.passwordReset.currentPassword.$model,
        newPassword: this.$v.passwordReset.newPassword.$model,
      })
      if (success) {
        this.showPasswordResetSuccess = true
        this.passwordReset.currentPassword = ''
        this.passwordReset.newPassword = ''
        this.passwordReset.newPasswordConfirmation = ''
      } else {
        this.showPasswordResetError = true
      }
    },
    async logout () {
      if (USE_SSO) {
        await this.$auth.signOut({
          postLogoutRedirectUri: `${window.location.origin}/logout`,
        })
      } else {
        this.$router.push({ name: 'logout' })
      }
    },
  },
  watch: {
    user () {
      if (this.user) {
        this.profile.firstName = this.user.firstName
        this.profile.lastName = this.user.lastName
        this.profile.customUserFields = this.customFieldMap
      }
    },
  },
}
</script>

<style></style>
