<template>
  <div>
    <div class="flex flex-row justify-between">
      <span
        class="text-2xl font-bold p-2 rounded-md hover:bg-white hover:bg-opacity-20 cursor-pointer flex-shrink"
        @click="$router.push({name: 'teams'})"
      >
        <BackArrow class="inline-block w-6 h-6 mr-2 fill-current" />
        <span>Back to Profile</span>
      </span>
    </div>
    <removeItemModal ref="removeItemModal" @delete="removeItem"></removeItemModal>
    <div class="flex flex-col space-x-4 mt-6">
      <div class="text-3xl uppercase font-bold border-b-2 border-th-text text-center pb-2 flex flex-col items-center">
        <div v-if="collection !== undefined">{{ collection.name }}</div>
        <div v-if="collection !== undefined" class="text-base font-thin normal-case">{{collection.description}}</div>
      </div>
      <div v-if="lessons.length > 0">
        <div class="text-left text-2xl font-bold mt-4 mb-4">Lessons</div>
        <div class="flex justify-center">
          <div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div v-for="lesson in lessons" :key="lesson.item._id">
                <LessonCard :item="lesson.item" itemType="lesson" :removeButton="isUserTeamLead" @delete="showRemoveItemModal" @getLessons="getCollection" @getModules="getCollection" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="practiceModules.length > 0">
        <div class="text-left text-2xl font-bold mt-8 mb-4">Practice Modules</div>
        <div class="flex justify-center">
          <div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div v-for="practice in practiceModules" :key="practice.item._id">
                <LessonCard :item="practice.item" itemType="practice" :removeButton="isUserTeamLead" @delete="showRemoveItemModal" @getLessons="getCollection" @getModules="getCollection" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LessonCard from '@/components/learn/LessonCard.vue'
import { getCollectionById } from 'team-service'
import BackArrow from '@/assets/back-arrow.svg'
import RemoveItemModal from '@/components/profile/RemoveItemModal.vue'

export default {
  components: {
    LessonCard,
    BackArrow,
    RemoveItemModal,
  },
  props: {
    collectionId: {
      type: String,
      required: false,
    },
    teamId: {
      type: String,
      required: false,
    },
  },
  created () {
    this.getCollection()
  },
  computed: {
    isUserTeamLead () {
      return this.teams?.find(team => team._id === this.teamId)?.lead._id === this.user._id
    },
    user () {
      return this.$store.getters['auth/user']
    },
    teams () {
      return this.$store.getters['team/teams']
    },
  },
  data () {
    return {
      collection: undefined,
      practiceModules: [],
      lessons: [],
    }
  },
  methods: {
    async getCollection () {
      const tempLessons = []
      const tempPracticeModules = []
      this.collection = await getCollectionById(this.teamId, this.collectionId)
      this.collection.items.forEach(item => {
        if (item.itemType === 'Lesson') {
          tempLessons.push(item)
        } else if (item.itemType === 'PracticeModule') {
          tempPracticeModules.push(item)
        }
      })
      this.lessons = tempLessons
      this.practiceModules = tempPracticeModules
    },
    showRemoveItemModal (item) {
      this.$refs.removeItemModal.open(item)
    },
    async removeItem (id) {
      const result = await this.$store.dispatch('team/removeItemFromCollection', { teamId: this.teamId, collectionId: this.collectionId, itemId: id })
      this.getCollection()
      if (result.result) {
      } else {
        console.log(result.errors)
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
ul.dashed > li:before {
  content: "-";
}
</style>
