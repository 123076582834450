import * as BadgeService from 'badge-service'

export default {
  namespaced: true,
  state: {
    badges: [],
  },
  getters: {
    badges: (state) => state.badges,
    badgeById: (state) => (id) => state.badges.find(badge => badge._id === id),
  },
  mutations: {
    updateBadges (state, badges) {
      state.badges = badges
    },
  },
  actions: {
    async getAll ({ commit }) {
      const badges = await BadgeService.getAll()
      commit('updateBadges', badges)
    },
    async getById (context, id) {
      return await BadgeService.getById(id)
    },
  },
}
