<template>
  <div class="flex items-center justify-center bg-th-secondary border-2 h-16 rounded border-th-text-secondary px-2 py-2">
      <div class="text-th-text-secondary text-xl">
        <div v-if="coinIcon==='BronzeDevOpsCoin'">100</div>
        <div v-else-if="coinIcon==='SilverDevOpsCoin'">300</div>
        <div v-else>500</div>
      </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
  computed: {
    coinIcon () {
      switch (this.challenge.level) {
        case 'BRONZE':
          return 'BronzeDevOpsCoin'
        case 'SILVER':
          return 'SilverDevOpsCoin'
        case 'GOLD':
          return 'GoldDevOpsCoin'
      }
      return undefined
    },
  },

}
</script>

<style>
</style>
