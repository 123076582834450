import SecureLS from 'secure-ls'
import UserService from 'user-service'

const SecureStorage = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
})

const USER_STORAGE_KEY = 'dca-user'
const TOKEN_STORAGE_KEY = `${USE_SSO ? 'okta-' : ''}dca-token`

// add catch for occasional 'malformed utf-8 data' bug. keys return undefined which triggers clearStorage and logout in index.js
function getSecureKey (keyName) {
  try {
    return SecureStorage.get(keyName)
  } catch (err) {
    return undefined
  }
}

const state = {
  user: getSecureKey(USER_STORAGE_KEY),
  token: getSecureKey(TOKEN_STORAGE_KEY),
  sstFilter: false,
}

const getters = {
  user: state => {
    return state.user ? JSON.parse(state.user) : undefined
  },
  isAuthenticated: state => {
    return state.token && state.user
  },
  sstFilterEnabled: state => {
    return state.sstFilter
  },
}

const mutations = {
  saveUser: (state, user) => {
    state.user = JSON.stringify(user)
    SecureStorage.set(USER_STORAGE_KEY, JSON.stringify(user))
  },
  saveToken: (state, token) => {
    state.token = token
    SecureStorage.set(TOKEN_STORAGE_KEY, token)
  },
  toggleSST: state => {
    state.sstFilter = !state.sstFilter
  },
  updateUser: async (state, updates) => {
    return await UserService.updateProfile(state.user.id, updates)
  },
  clearStorage: state => {
    state.user = undefined
    state.token = undefined
    SecureStorage.remove(USER_STORAGE_KEY)
    SecureStorage.remove(TOKEN_STORAGE_KEY)
  },
}

const actions = {
  async updateUser ({ getters }, updates) {
    return await UserService.updateProfile(getters.user.id, updates)
  },
  async resetChallengeCompletions ({ getters }) {
    return await UserService.resetChallengeCompletions(getters.user.id)
  },
  async fetchProfile ({ getters }) {
    return await UserService.fetchProfile(getters.user.id)
  },
  async resetPassword ({ getters }, { currentPassword, newPassword }) {
    return await UserService.resetPassword(
      getters.user.id,
      currentPassword,
      newPassword,
    )
  },
  async logout ({ state, commit }) {
    console.log('Logging out user...')

    if (state.token) {
      try {
        await UserService.logout()
      } catch (e) {
        console.log(e)
      }
    }
    commit('clearStorage')
  },
}

export default { namespaced: true, state, getters, mutations, actions }
