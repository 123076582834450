import Axios from 'axios'

export default {
  async getCategories () {
    try {
      const response = await Axios.get('/practice/categories')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getModules () {
    try {
      const response = await Axios.get('/practice/modules')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getModule (id) {
    try {
      const response = await Axios.get(`/practice/categories/${id}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async searchModules (query, limit = 10) {
    try {
      const response = await Axios.get('practice/modules/search', {
        params: {
          query: query,
          limit: limit,
        },
      })
      return response.data.data
    } catch (err) {
      console.error(err)
      return undefined
    }
  },
  async upVoteModule (moduleId) {
    try {
      const response = await Axios.put(`/practice/modules/upVote/${moduleId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async downVoteModule (moduleId) {
    try {
      const response = await Axios.put(`/practice/modules/downVote/${moduleId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async addModuleComment (moduleId, payload) {
    try {
      const response = await Axios.put(`/practice/modules/addComment/${moduleId}`, { payload: payload })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async removeModuleComment (moduleId, payload) {
    try {
      const response = await Axios.patch(`/practice/modules/removeComment/${moduleId}`, { payload: payload })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async addModuleCompletion (moduleId) {
    try {
      const response = await Axios.put(`/practice/modules/complete/${moduleId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async removeModuleCompletion (moduleId) {
    try {
      const response = await Axios.delete(`/practice/modules/complete/${moduleId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async addModuleStarRating (moduleId, payload) {
    try {
      const response = await Axios.post(`/practice/modules/addStarRating/${moduleId}`, { payload: payload })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async removeModuleStarRating (moduleId, userId) {
    try {
      const response = await Axios.patch(`/practice/modules/deleteStarRating/${moduleId}`, { user: userId })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
}
