<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg text-th-text-secondary border-2 border-th-text-secondary relative"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="dismiss" />
      <div class="px-4 py-4">
        <div class="text-xl font-bold uppercase pb-2 mb-2 border-b border-th-text-secondary">{{title || ''}}</div>
        <div>
          <slot></slot>
        </div>
        <div class="flex pt-4 mt-2 justify-between flex-row-reverse">
          <div
            class="border-2 border-th-text-secondary bg-th-text-secondary text-th-secondary px-4 py-2 text-white font-semibold uppercase rounded-lg text-center cursor-pointer text-sm"
            @click="submit"
          >{{submitLabel}}</div>
          <div
            class="border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary px-4 py-2 font-semibold uppercase rounded-lg text-center cursor-pointer text-sm"
            @click="dismiss"
          >{{cancelLabel}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'
export default {
  components: {
    CloseIcon,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    preSubmit: {
      type: Function,
      default: undefined,
    },
    submitLabel: {
      type: String,
      default: 'Submit',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
  },
  data () {
    return {
      visible: false,
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    dismiss () {
      this.visible = false
      this.$emit('dismissed')
    },
    submit () {
      if (this.preSubmit) {
        if (!this.preSubmit()) {
          this.$emit('submit')
          this.visible = false
        }
      } else {
        this.$emit('submit')
        this.visible = false
      }
    },
  },
}
</script>
