<template>
  <div v-if="visible" class="fixed inset-0 z-50 flex bg-opacity-50 bg-black transition-opacity duration-1000 lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative text-th-text-secondary"
    >
      <CloseIcon v-if="showCloseIcon" class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-col items-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-xl font-bold">{{ modalText ? modalText : alternateText }}</div>
        </div>
        <div
          class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase cursor-pointer"
          @click="$router.push({ name: 'login' });"
        >Register / Login</div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'

export default {
  components: {
    CloseIcon,
  },
  props: {
    modalText: {
      type: String,
      required: false,
    },
    showCloseIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      visible: false,
      alternateText: '',
    }
  },
  computed: {},
  methods: {
    open (msg) {
      if (msg) this.alternateText = msg
      this.visible = true
    },
    close () {
      this.visible = false
      this.$emit('closed')
    },
  },
  watch: {},
}
</script>
