<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative"
    >
      <CloseIcon
        class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2"
        @click.stop="close(); resetAddCollection()"
      />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center border-b border-th-text-secondary pb-4">
          <div class="text-xl text-th-text-secondary font-bold">
            Add
            <span class="font-normal italic">{{ item.title }}</span> To Collection
          </div>
        </div>
        <div>
          <div class="flex flex-col w-full">
            <div v-for="collectionInfo in userCollections" :key="collectionInfo.collection._id">
              <div class="flex flex-row content-center border-b border-th-secondary py-2 text-th-text-secondary">
                <div class="flex flex-col cursor-pointer hover:opacity-80"
                  @click.stop="$router.push({name:'teams-collection', params:{teamId: collectionInfo.team._id, collectionId: collectionInfo.collection._id}})"
                >
                  <div class="flex flex-row space-x-2 ">
                    <div class="uppercase font-bold text-sm">{{ collectionInfo.collection.name }}</div>
                    <div class="opacity-60">Lessons: {{ getLessons(collectionInfo.collection) }} </div>
                    <div class="opacity-60">Practice Modules: {{ getPracticeModules(collectionInfo.collection) }}</div>
                  </div>
                  <div>shared with {{collectionInfo.team.name}}</div>
                </div>
                <div class="flex flex-grow justify-end">
                  <div v-if="!collectionInfo.included">
                    <md-button
                      class="md-primary opacity-60 md-raised focus:outline-none rounded-md"
                      @click.stop="addItemToCollection(collectionInfo.team._id, collectionInfo.collection._id); collectionInfo.included = !collectionInfo.included"
                    >+ Add</md-button>
                  </div>
                  <div v-else>
                    <md-button
                      class="md-primary md-raised focus:outline-none rounded-md"
                      @click.stop="removeItemFromCollection(collectionInfo.team._id, collectionInfo.collection._id); collectionInfo.included = !collectionInfo.included"
                    >- Remove</md-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="userCollections.length === 0" class="flex flex-col items-center text-th-text-secondary border-b border-th-text-secondary">
              <TeamIcon class="w-20 py-4 fill-current" />
              <div
                class="font-normal normal-case text-center"
              >You don't have any collections. Create one for your team to share curated collections with your teammates.</div>
            </div>
            <div class="border-t border-th-text-secondary mb-3"></div>
            <md-button v-if="addCollectionFlag && teamsLeadByUser != 0"
              class="md-primary md-raised m-0 focus:outline-none rounded-md"
              @click.stop="toggleAddCollection()"
            >+ New Collection</md-button>
            <div v-if="!addCollectionFlag && teamsLeadByUser != 0">
              <div class="flex flex-row">
                <div class="inline-block mx-2">
                  <span class="text-th-text-secondary uppercase pb-2 text-sm">Team to add Collection:  </span>
                </div>
                <SelectInput
                  class="flex w-1/2"
                  v-model="teamForAddCollection"
                  :options="teamNameLeadByUser"
                  :undefinedOption="false"
                />
              </div>
              <div class="flex flex-col w-full">
                <span class="uppercase text-th-text-secondary pb-2 text-sm mx-2">Collection Name</span>
                <input
                  type="text"
                  v-model="collectionName"
                  autocomplete="off"
                  :disabled="submitted"
                  class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none mx-2"
                />
                <span class="uppercase text-th-text-secondary pb-2 mt-4 text-sm mx-2">Collection Description</span>
                <input
                  type="text"
                  v-model="collectionDescription"
                  autocomplete="off"
                  :disabled="submitted"
                  class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none mx-2"
                />
              </div>
              <div class="flex flex-row mt-2 w-full">
                <md-button
                  :md-ripple="false"
                  @click.stop="toggleAddCollection(); addCollectionToTeam()"
                  class="w-1/2 md-primary md-raised focus:outline-none flex-none"
                >
                  <div>Submit</div>
                </md-button>
                <md-button
                  :md-ripple="false"
                  @click.stop="toggleAddCollection(); resetAddCollection()"
                  class="w-1/2 md-primary md-raised focus:outline-none "
                >
                  <div>Cancel</div>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'
import TeamIcon from '@/assets/team-icon.svg'
import SelectInput from '@/components/utilities/SelectInput.vue'

export default {
  components: {
    CloseIcon,
    TeamIcon,
    SelectInput,
  },
  mounted () {
    this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    userTeams () {
      return this.$store.getters['team/teams']
    },
    teamsLeadByUser () {
      return this.userTeams.filter(team => team.lead._id === this.user._id)
    },
    teamNameLeadByUser () {
      return this.userTeams.filter(team => team.lead._id === this.user._id).map(team => team.name)
    },
  },
  props: {
    itemType: {
      type: String,
      required: true,
    },
  },
  created () {
    this.getCollections()
  },
  data () {
    return {
      visible: false,
      itemName: undefined,
      result: undefined,
      submitted: false,
      userCollections: [],
      item: undefined,
      createCollectionFlag: false,
      addCollectionFlag: true,
      teamForAddCollection: '',
      collectionName: '',
      collectionDescription: '',
    }
  },
  watch: {
    userTeams () {
      this.getCollections()
    },
  },
  methods: {
    async getCollections () {
      const collections = []
      this.teamsLeadByUser.forEach(team => {
        team.lists.forEach(collection => {
          collections.push({ collection, team, included: false })
        })
      })
      collections.forEach(collectionInfo => {
        collectionInfo.included = this.collectionContainsItem(collectionInfo.collection.items)
      })
      this.userCollections = collections
    },
    async addItemToCollection (teamId, collectionId) {
      const result = await this.$store.dispatch('team/addItemToCollection', {
        teamId,
        collectionId,
        itemToAdd: {
          itemType: this.itemType,
          itemId: this.item._id,
        },
      })
      if (result) {
        this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      } else {
        console.log(result.errors)
      }
    },
    async removeItemFromCollection (teamId, collectionId) {
      const result = await this.$store.dispatch('team/removeItemFromCollection', { teamId, collectionId, itemId: this.item._id })
      if (result.result) {
        this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      } else {
        console.log(result.errors)
      }
    },
    async addCollectionToTeam () {
      const teamId = this.teamsLeadByUser.find(team => team.name === this.teamForAddCollection)._id
      await this.$store.dispatch('team/createCollection', { collection: { name: this.collectionName, description: this.collectionDescription }, id: teamId })
      // this.closeCreateCollectionModal()
      // this.$emit('refresh', this.team._id)
      // if (result.result) {
      //   this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      // } else {
      //   console.log(result.errors)
      // }
      // console.log("TEAM: "+JSON.stringify(this.teamForAddCollection) + " NAME: " + this.collectionName +" Description: " +this.collectionDescription)
      this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      this.getCollections()
      this.resetAddCollection()
    },
    resetAddCollection () {
      this.teamForAddCollection = ''
      this.collectionName = ''
      this.collectionDescription = ''
    },
    collectionContainsItem (collectionItems) {
      return this.item && collectionItems.some(item => item.item._id === this.item._id)
    },
    open (item) {
      this.visible = true
      this.item = item
      this.userCollections.forEach(collectionInfo => {
        collectionInfo.included = this.collectionContainsItem(collectionInfo.collection.items)
      })
    },
    close () {
      this.visible = false
    },
    toggleAddCollection () {
      this.addCollectionFlag = !this.addCollectionFlag
    },
    getLessons (collection) {
      return collection.items.filter(obj => obj.itemType === 'Lesson').length
    },
    getPracticeModules (collection) {
      return collection.items.filter(obj => obj.itemType === 'PracticeModule').length
    },
  },
}
</script>

<style>
</style>
