<template>
  <div class="h-full flex flex-col content-end px-4 py-2 bg-th-secondary border-th-text-secondary border-2 rounded-md">
    <div class="text-th-text-secondary text-sm">{{ formatQuestionData(question, questionNum) }}</div>
    <div class="border-b text-th-text-secondary border-th-text-secondary py-2 text-lg font-normal">{{  question.question }}</div>
    <div>
      <div class="divide-th-text-secondary border-th-text-secondary mx-2" v-if="question.type === 'CHOICE'">
        <div v-for="(option, optionNum) in question.options" :key="optionNum" class="flex items-center my-8">
          <md-radio :value="option" v-model="answer" class="text-th-text-secondary">{{ option }}</md-radio>
        </div>
      </div>
      <div v-else-if="question.type === 'FREE_TEXT'">
        <div class="flex flex-col w-full">
          <input
            type="text"
            v-model="answer"
            autocomplete="off"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-xl px-2 py-1 focus:outline-none"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionNum: {
      type: Number,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      answer: undefined,
    }
  },
  methods: {
    formatQuestionData (question, index) {
      const points = question.points ? question.points : Math.floor((1 / this.totalQuestions * 100) * 100) / 100
      const pointsText = `${points} ${points === 1 ? 'pt' : 'pts'}`
      return `Question ${index + 1} (${pointsText}):`
    },
  },
  watch: {
    answer (val) {
      this.$emit('answerChanged', val)
    },
  },
}
</script>

<style lang="postcss">
.card-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 1);
}
</style>
