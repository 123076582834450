var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col items-start w-full"},[_c('div',{staticClass:"flex justify-between w-full"},[_c('div',{staticClass:"flex flex-1 flex-col"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('AchieveIcon',{staticClass:"w-10 h-10 fill-current mr-4"}),_c('div',{staticClass:"text-4xl font-bold"},[_vm._v(_vm._s(_vm.contest ? _vm.contest.title : ''))])],1),_c('div',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.contest ? _vm.contest.description : ''))])]),_c('div',{staticClass:"hidden lg:flex"},[_c('div',{staticClass:"flex flex-col space-y-4 mr-4"},[(_vm.userCoinCounts)?_c('ScoreboardCard',{attrs:{"item":{
              name: `${_vm.user.firstName} ${_vm.user.lastName}`,
              team: '---',
              rank: _vm.user.rank,
              overallScore: _vm.userScore,
              score: _vm.userCoinCounts,
            }}}):_vm._e()],1),_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"flex flex-col items-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'scoreboard-main' })}}},[_c('ArrowRightCircle',{staticClass:"fill-current w-10 h-10 mb-4"}),_c('div',{staticClass:"text-center"},[_vm._v("View Scoreboard")])],1)])])]),_c('div',{staticClass:"lg:hidden"},[_c('div',{staticClass:"mt-6 flex space-x-4 justify-center w-full"},[(_vm.userCoinCounts)?_c('ScoreboardCard',{attrs:{"item":{
            name: `${_vm.user.firstName} ${_vm.user.lastName}`,
            team: '---',
            rank: _vm.user.rank,
            overallScore: _vm.userScore,
            score: _vm.userCoinCounts
          }}}):_vm._e(),_c('div',{staticClass:"hidden sm:flex flex-col justify-center items-center"},[_c('div',{staticClass:"flex flex-col items-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'scoreboard-main' })}}},[_c('ArrowRightCircle',{staticClass:"fill-current w-10 h-10 mb-2"}),_c('div',{staticClass:"text-center"},[_vm._v("View Scoreboard")])],1)])],1)]),_c('div',{staticClass:"w-full mt-8 md:mt-10"},[(_vm.contest)?_c('jeopardy-board',{attrs:{"contest":_vm.contest},on:{"updateScoreCard":_vm.fetchContestScoreForUser}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }