<!-- eslint-disable vue/no-unused-components -->
<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative overflow-auto max-h-96"
    >
      <CloseIcon
        class="cursor-pointer absolute right-0 w-8 h-8 border-th-text-secondary text-th-text-secondary fill-current mr-2 mt-2"
        @click.stop="close"
      />
      <div v-if="!item.completed" class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center pb-4 text-th-text-secondary">
          <div class="text-xl font-bold capitalize">
            Complete {{ type }}
            <span class="font-normal italic">{{ item.title }}</span>
          </div>
        </div>

        <div class="text-th-text-secondary">
          Marking this {{ type }} as complete is purely based on the honor system and used only for tracking purposes. Completion status can be viewed on individual
          {{ type }} cards and the completions section of your profile page. Completions will also be included in backend reports and metrics for site administrators.
        </div>

        <div class="mt-4 text-th-text-secondary" v-if="item.commentsEnabled || item.ratingsEnabled">
          <span class="italic">Optional: </span>
          Leave a comment or a rating for this {{ type }}!
        </div>

        <div class="flex flex-row flex-nowrap mb-2" v-if="item.ratingsEnabled">
          <StarRating :star-size="20" v-model="starRating" v-if="theme.useStarRatings"></StarRating>
          <div class="flex flex-row flex-nowrap" v-else>
            <div class="flex flex-row flex-nowrap mr-4 text-th-text-secondary" >
              <button
                @click.stop="upVote"
                :class="`mr-0.5 focus:outline-none ${upVoted ? 'opacity-100' : 'opacity-60'}`"
              >
                <ThumbUpIcon />
              </button>
              <span class="text-sm">{{ upVoteCount }}</span>
            </div>
            <div class="flex flex-row flex-nowrap text-th-text-secondary">
              <button
                @click.stop="downVote"
                :class="`mr-0.5 focus:outline-none ${downVoted ? 'opacity-100' : 'opacity-60'}`"
              >
                <ThumbDownIcon />
              </button>
              <span class="text-sm">{{ downVoteCount }}</span>
            </div>
          </div>
        </div>

        <div class="border-2 border-th-text-secondary px-4 py-2 rounded-md" v-if="item.commentsEnabled">
          <textarea
            class="focus:outline-none w-full bg-transparent resize-none placeholder-th-text-secondary"
            v-model="commentText"
            placeholder="Add a comment..."
          ></textarea>
        </div>

        <div class="mt-3">
          <div
            @click="addCompletion"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase opacity-100 cursor-pointer"
          >Complete</div>
        </div>
      </div>

      <div v-else class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center pb-4 text-th-text-secondary">
          <div class="text-xl font-bold capitalize">
            Remove Completion for {{ type }}
            <span class="font-normal italic">{{ item.title }}</span>
          </div>
        </div>

        <div class="text-th-text-secondary">
          Removing the completion will mark this {{ type }} as incomplete and remove it from your completions history.
        </div>

        <div class="mt-3">
          <div
            @click="removeCompletion"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase opacity-100 cursor-pointer"
          >Remove Completion</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'
import ThumbUpIcon from '@/assets/thumb-up.svg'
import ThumbDownIcon from '@/assets/thumb-down.svg'
import StarRating from 'vue-star-rating'

export default {
  components: {
    CloseIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    StarRating,
  },
  props: {
    item: {
      type: Object,
    },
    type: {
      type: String,
      default: 'lesson',
    },
  },
  data () {
    return {
      visible: false,
      originalRating: { upVoted: false, downVoted: false },
      commentText: '',
      upVoted: false,
      downVoted: false,
      upVoteCount: 0,
      downVoteCount: 0,
      starRating: 0,
    }
  },
  watch: {
    item () {
      this.originalRating = this.item?.userRating
      this.upVoted = this.originalRating?.upVoted
      this.downVoted = this.originalRating?.downVoted
      this.upVoteCount = this.item?.upVoteCount || 0
      this.downVoteCount = this.item?.downVoteCount || 0
    },
  },
  methods: {
    reset () {
      this.commentText = ''
      this.starRating = 0
    },
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
      this.reset()
    },
    upVote () {
      this.upVoted = !this.upVoted
      this.upVoteCount += this.upVoted ? 1 : -1
      if (this.downVoted) {
        this.downVoted = false
        this.downVoteCount -= 1
      }
    },
    downVote () {
      this.downVoted = !this.downVoted
      this.downVoteCount += this.downVoted ? 1 : -1
      if (this.upVoted) {
        this.upVoted = false
        this.upVoteCount -= 1
      }
    },
    addCompletion () {
      this.$emit('addCompletion', {
        comment: this.commentText !== '' && this.commentText.length > 0 ? this.commentText : undefined,
        upVoteChanged: this.upVoted !== this.originalRating.upVoted && !this.downVoted,
        downVoteChanged: this.downVoted !== this.originalRating.downVoted && !this.upVoted,
        starRating: this.starRating,
      })
      this.close()
    },
    removeCompletion () {
      this.$emit('removeCompletion')
      this.close()
    },
  },
  computed: {
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
  },
}
</script>

<style>
</style>
