<template>
  <div>
    <div class="flex flex-col items-center">
      <div class="flex w-full justify-between pb-4">
        <div class="flex flex-col">
          <div class="flex flex-row items-center">
            <AchieveIcon class="w-10 h-10 fill-current mr-4" />
            <div class="text-4xl font-bold">{{ contest ? contest.title : '' }}</div>
          </div>
          <div
            class="text-lg"
          >{{ contest ? contest.description : '' }}</div>
        </div>
      </div>
        <div class="font-semibold pb-2" v-if="userTeamsInTournament.length > 0"> {{ `Team: ${allTeams.find(team => team._id === userTeamsInTournament[0]).name}` }}</div>

      <div v-if="contest && selectedStage && contest.stages.length > 0" class="border-b-2 border-th-text w-full items-center justify-center sm:space-x-4 flex flex-col sm:flex-row">
        <SelectInput
          @input="stageSelected"
          :undefinedOption="false"
          class="mb-4 my-2 w-full sm:w-96"
          inputLabel="Tournament Stage"
          :value="selectedStage ? selectedStage._id : undefined"
          :options="contestStages.map(stage => {
            return {
              key: stage._id,
              name: stage.title,
            }
          })"
        />
      </div>

        <div v-if="selectedStage" class="flex flex-wrap mt-4 justify-center w-full">
          <div v-for="match of selectedStage.matches" :key="match._id" class="py-4 sm:px-4 w-full lg:w-1/2 xl:w-1/3">
            <div class="w-full flex flex-col p-2 rounded-lg border-4 border-th-text-secondary bg-th-secondary text-th-text-secondary">
              <div class="text-xl font-bold text-center">{{ match.location }}</div>
              <div v-show="match.date" class="font-semibold text-center text-sm">{{ matchDateString(match.date) }} {{ matchTimeString(match.date) }}</div>
              <div class="flex justify-between space-x-4 font-semibold border-b-2 border-th-text-secondary">
                <div class="px-4">Team</div>
                <div class="px-4">Score</div>
              </div>
              <div
                v-for="(team, index) of sortTeamsByScore(match.teams)"
                :key="team.team"
                :class="{
                  'border-b border-th-text-secondary': index !== match.teams.length - 1,
                  'bg-white bg-opacity-20': userTeamsInTournament.includes(team.team) && team.team !== match.victor,
                  'bg-green-600 text-white': team.team === match.victor
                }"
                class="flex justify-between p-2">
                <div class="truncate">{{ allTeams.find(allTeam => allTeam._id === team.team).name }}</div>
                <div class="ml-4">{{ team.points }}</div>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import AchieveIcon from '@/assets/achieve.svg'
import SelectInput from '@/components/utilities/SelectInput.vue'

import CompeteService from 'compete-service'

export default {
  components: {
    AchieveIcon, SelectInput,
  },
  async mounted () {
    await this.$store.dispatch('team/fetchAllTeams')
    // this.$store.dispatch('compete/fetchScores')
    await this.$store.dispatch('compete/fetchCompeteData')
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedStage: undefined,
    }
  },
  computed: {
    leaderBoard () {
      return this.$store.getters['compete/highScores'](3)
    },
    user () {
      return this.$store.getters['auth/user']
    },
    contest () {
      return this.$store.getters['compete/contestForId'](this._id)
    },
    allTeams () {
      return this.$store.getters['team/allTeams']
    },
    userTeamsInTournament () {
      if (!this.contest) return []
      const tournamentTeamIds = new Set()
      const userTeamIds = this.user.teams.map(team => team._id)
      this.contest.stages.map(stage => stage.matches.map(match => match.teams)).flat(2).map(team => tournamentTeamIds.add(team.team))
      return [...tournamentTeamIds].filter(teamId => userTeamIds.includes(teamId))
    },
    contestStages () {
      return this.contest ? this.contest.stages : []
    },
  },
  methods: {
    matchDateString (dateTime) {
      return new Date(dateTime).toLocaleString().split(',')[0]
    },
    matchTimeString (dateTime) {
      const time = new Date(dateTime).toLocaleString().split(' ')[1]
      const amOrPm = new Date(dateTime).toLocaleString().split(' ')[2]
      const timeDigits = time.split(':')
      return `${timeDigits[0]}:${timeDigits[1]} ${amOrPm}`
    },
    async fetchHighScores () {
      this.leaderBoard = await CompeteService.fetchHighScores()
    },
    stageSelected (stageId) {
      this.selectedStage = this.contestStages.find(stage => stage._id === stageId)
    },
    sortTeamsByScore (teams) {
      // slice() called because otherwise throwing "You may have an infinite update loop in a component render function."
      // something to do with sort mutating so it re-renders and slice produces a shallow copy
      return teams.slice().sort((a, b) => b.points - a.points)
    },
  },
  watch: {
    contest () {
      this.selectedStage = this.contest.stages.length > 0 ? this.contest.stages[0] : null
    },
  },
}
</script>

<style>
</style>
