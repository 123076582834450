<template>
  <div>
    <div class="text-th-text text-opacity-50 uppercase font-extrabold">{{label}}</div>
    <input
      :type="type"
      :value="value"
      @input="$emit('input',$event.target.value)"
      @blur="$emit('blur')"
      :autocomplete="autocomplete"
      class="bg-th-secondary w-full rounded text-2xl text-th-text-secondary border-2 border-th-text-secondary px-2 py-1 focus:outline-none"
      :class="{'border-2 border-red-700': fieldError}"
    />
    <div class="text-th-text-primary font-semibold text-xs uppercase py-1" v-if="fieldError">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    fieldError: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: 'This field has an error',
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
  },
}
</script>

<style>
</style>
