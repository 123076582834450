<template>
  <div>
    <LoginModal ref="loginModal" />
    <addToCollectionModal
      v-if="$store.getters['auth/user']"
      ref="addToCollectionModal"
      itemType="PracticeModule">
    </addToCollectionModal>
    <updateCompletionModal
      ref="completionModal"
      type="practiceModule"
      :item="selectedModule"
      @addCompletion="addCompletion"
      @removeCompletion="removeCompletion"
    ></updateCompletionModal>
    <lessonCommentModal
      ref="commentModal"
      :item="selectedModule"
      @addComment="addComment"
      @removeComment="removeComment"
    ></lessonCommentModal>

    <div class="flex flex-col items-start">
      <div class="flex flex-row items-center">
        <PracticeIcon class="w-10 h-10 fill-current mr-4" />
        <div class="text-4xl font-bold">Practice</div>
      </div>
      <div
        class="text-lg mb-3"
      >{{ this.$parent.siteMetadata ? this.$parent.siteMetadata.practiceDescription : '' }}</div>

      <div class="flex items-center rounded-full px-2 bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary mb-3 w-full md:w-3/4 lg:w-1/2 xl:w-1/4">
        <SearchIcon class="w-6 h-6 mr-2 fill-current" />
        <input
          v-model="searchInput"
          class="h-10 flex-1 bg-transparent focus:outline-none placeholder-current"
          type="text"
          placeholder="Search Practice content"
        />
        <CloseIcon
          v-if="searchInput.length"
          class="w-6 h-6 ml-4 cursor-pointer fill-current"
          @click="searchInput = ''"
        />
      </div>

      <div v-if="isLoading" class="mt-8 text-xl text-center w-full">Loading Content...</div>
      <div v-if="searchInput === '' && !searchLoading && !linkedModule" class="w-full pt-8">
        <div class="flex flex-col space-y-10">
          <div class="py-2" v-for="category in categories" :key="category._id">
            <div class="border-b-2 border-th-text pb-2 mb-4">
              <div class="text-2xl font-bold">{{category.title}}</div>
              <div class="font-normal">{{category.description}}</div>
            </div>
                <CarouselWrapper>
                  <slide
                    class="w-full h-1/4 md:w-1/2 lg:w-1/3 xl:w-1/4 px-4"
                    v-for="practiceMod in category.modules"
                    :key="practiceMod._id"
                  >
                    <LessonCard
                      :ref="practiceMod._id"
                      :query="searchInput"
                      :item="practiceMod"
                      itemType="practice"
                      :shareButton="true"
                      @share="showAddToCollectionModal"
                      @showLoginModal="showLoginModal"
                      @showCommentModal="showCommentModal"
                      @showCompleteModal="showCompleteModal"
                      @getModules="getModules"
                    />
                  </slide>
                </CarouselWrapper>
          </div>
        </div>
      </div>
      <div v-else-if="linkedModule" class="w-full pt-8">
        <div class="border-b-2 border-th-text py-2 mb-4">
          <div class="text-2xl font-bold">Searched Practice Module</div>
          <router-link :to="{name: 'practice-main'}" class="underline" >See all modules</router-link>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 justify-items-center px-4">
          <div class="w-full" v-for="practiceModule in modules.filter((module) => module._id === linkedModule)" :key="practiceModule._id">
            <div>
              <LessonCard
                :ref="practiceModule._id"
                :query="searchInput"
                :item="practiceModule"
                itemType="practice"
                :shareButton="true"
                @share="showAddToCollectionModal"
                @showLoginModal="showLoginModal"
                @showCommentModal="showCommentModal"
                @showCompleteModal="showCompleteModal"
                @getModules="getModules"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full pt-8">
        <div class="border-b-2 border-th-text py-2 mb-4">
          <div class="text-2xl font-bold">Search All Practice Modules</div>
          <div class="font-normal">{{ this.searchLoading ? 'Loading...' : filteredModules.length + ' Matches' }}</div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 justify-items-center px-4">
          <div class="w-full" v-for="practiceModule in filteredModules" :key="practiceModule._id">
            <div>
              <LessonCard
                :ref="practiceModule._id"
                :query="searchInput"
                :item="practiceModule"
                itemType="practice"
                :shareButton="true"
                @share="showAddToCollectionModal"
                @showLoginModal="showLoginModal"
                @showCommentModal="showCommentModal"
                @showCompleteModal="showCompleteModal"
                @getModules="getModules"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

import CloseIcon from '@/assets/close-x.svg'
import PracticeIcon from '@/assets/practice.svg'
import SearchIcon from '@/assets/search.svg'
import LessonCard from '@/components/learn/LessonCard.vue'
import AddToCollectionModal from '@/components/profile/AddToCollectionModal.vue'
import LessonCommentModal from '@/components/learn/LessonCommentModal'
import LoginModal from '@/components/utilities/LoginModal'
import UpdateCompletionModal from '@/components/learn/UpdateCompletionModal'

import CarouselWrapper from '@/components/utilities/CarouselWrapper.vue'
import { Slide } from 'vue-carousel'

export default {
  components: {
    CloseIcon,
    PracticeIcon,
    SearchIcon,
    LessonCard,
    CarouselWrapper,
    Slide,
    AddToCollectionModal,
    LessonCommentModal,
    LoginModal,
    UpdateCompletionModal,
  },
  computed: {
    categories () {
      return this.$store.getters['practice/categories']
    },
    modules () {
      return this.$store.getters['practice/modules']
    },
  },
  async mounted () {
    if (this.categories.length === 0) this.isLoading = true
    await this.$store.dispatch('practice/fetchPracticeData')
    this.isLoading = false
  },
  props: {
    linkedModule: String,
  },
  data () {
    return {
      isLoading: false,
      searchInput: '',
      searchLoading: false,
      filteredModules: [],
      debouncedQueryWatch: undefined,
      selectedModule: undefined,
    }
  },
  created () {
    this.debouncedQueryWatch = debounce(async query => this.search(query), 500)
  },
  methods: {
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    showAddToCollectionModal (item) {
      this.$refs.addToCollectionModal.open(item)
    },
    closeAddToCollectionModal () {
      this.$refs.addToCollectionModal.close()
    },
    showCommentModal (item) {
      this.selectedModule = item
      this.$refs.commentModal.open()
    },
    closeCommentModal () {
      this.$refs.commentModal.close()
    },
    showCompleteModal (item) {
      this.selectedModule = item
      this.$refs.completionModal.open()
    },
    closeCompleteModal () {
      this.$refs.completionModal.close()
    },
    addComment (commentText) {
      this.$refs[this.selectedModule._id][0].addComment(commentText)
    },
    removeComment (comment) {
      this.$refs[this.selectedModule._id][0].removeComment(comment)
    },
    addCompletion (completion) {
      this.$refs[this.selectedModule._id][0].addCompletion(completion)
    },
    removeCompletion () {
      this.$refs[this.selectedModule._id][0].removeCompletion()
    },
    getModules () {
      this.$store.dispatch('practice/fetchPracticeData')
    },
    search (query) {
      const searchString = query.toLowerCase().trim()

      this.filteredModules = this.modules.filter((module) => module.description.toLowerCase().includes(searchString))
      console.log(this.filteredModules)
      this.searchLoading = false
    },
  },
  watch: {
    searchInput: function (newVal) {
      this.searchLoading = true
      this.filteredModules = []
      this.debouncedQueryWatch(newVal)
    },
    categories: function (newVal) {
      if (this.selectedModule) {
        for (const category of newVal) {
          const updatedModule = category.modules.find(module => module._id === this.selectedModule._id)
          if (updatedModule) {
            this.selectedModule = updatedModule
            return
          }
        }
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.vs-carousel__slide {
flex: 0 0 auto;
}
</style>
