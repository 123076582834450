<template>
  <div class="text">
    <div ref="textWrapper" class="relative">
      <div ref="text" v-if="html" v-html="html" :class="`${!isExpanded && 'line-clamp-3'} font-normal text-th-text-secondary`" />
      <div v-else ref="text" :class="{ 'line-clamp-3': !isExpanded }">
        {{ text }}
      </div>
      <button v-if="showMoreButton" @click="handleButtonClick" class="flex justify-between absolute bottom-0 right-0 bg-th-secondary focus:outline-none">
        <div v-if="!isExpanded">...</div>
        <div class="pl-1 underline">{{ isExpanded ? 'Show less' : 'Show more' }}</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
    },
    html: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      isExpanded: false,
      showMoreButton: false,
    }
  },
  mounted () {
    this.checkTextOverflow()
  },
  methods: {
    handleButtonClick (event) {
      event.stopPropagation()
      this.toggleText()
    },
    toggleText () {
      this.isExpanded = !this.isExpanded
    },
    checkTextOverflow () {
      this.$nextTick(() => {
        const element = this.$refs.text
        if (element.scrollHeight > element.clientHeight) {
          this.showMoreButton = true
        }
      })
    },
  },
}
</script>
