<template>
  <div>
    <div v-if="game">
      <div class="flex lg:hidden">
        <div class="relative w-full">
          <select
            class="block appearance-none w-full bg-th-secondary border-2 border-th-text-secondary text-th-text-secondary py-3 px-4 pr-8 rounded leading-tight focus:outline-none"
            id="control-position"
            v-model="selectedCategory"
          >
            <option v-for="category in categories" :key="category._id" :value="category._id">{{category.title}}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-th-text">
            <CaratIcon class="fill-current h-4 w-4" />
          </div>
        </div>
      </div>
      <div class="hidden lg:flex flex-col mb-6">
        <div class="flex flex-row mb-3 border-b-2 border-th-text-secondary space-x-3 items-center">
          <div v-for="category in categories" :key="category._id" :style="`width:${100/categories.length}%`">
            <div class="text-center font-bold pb-3">{{category.title}}</div>
          </div>
        </div>
        <div class="flex flex-row space-x-3">
          <div
            v-for="category in categories"
            :key="category._id"
            class="flex flex-col"
            :style="`width:${100/categories.length}%`"
          >
            <div class="flex flex-col space-y-3">
              <JeopardyCard
                v-for="challenge in game[category._id]"
                :key="challenge._id"
                :challenge="challenge"
                class="flex items-center justify-start bg-th-secondary border-th-text-secondary px-2 py-2"
                :class="{'opacity-25':hasCompleted(challenge._id), 'cursor-pointer': !hasCompleted(challenge._id)}"
                @click.native="hasCompleted(challenge._id) ? null : showModalWithChallenge(challenge)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-3 items-center lg:hidden py-6">
        <JeopardyCard
          v-for="challenge in challengesForCategory"
          :key="challenge._id"
          :challenge="challenge"
          class="w-full flex items-center justify-start bg-th-secondary border-th-text-secondary px-2 py-2"
          :class="{'opacity-25':hasCompleted(challenge._id), 'cursor-pointer': !hasCompleted(challenge._id)}"
          @click.native="hasCompleted(challenge._id) ? null : showModalWithChallenge(challenge)"
        />
      </div>
      <!-- disabling new board funtionality in refactoring compete -->
      <div v-if="!allChallengesCompleted && contest.maxChallengesToDisplayPerCategory !== 0"
        class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
        @click="generateNewGameBoard()"
      >Get New Challenges</div>
      <div v-if="allChallengesCompleted"
        class="bg-th-navbar text-th-text-navbar text-opacity-75 px-4 py-2 font-bold text-center rounded-md uppercase"
      >All Challenges Completed!</div>
    </div>
    <div v-else class="text-center py-4 font-bold text-th-text uppercase text-xl">Loading game board...</div>
    <ChallengeModal ref="challengeModal" :submit="checkAnswer" @updateScore="$emit('updateScoreCard')" @closed="$store.dispatch('compete/fetchScores')" />
  </div>
</template>

<script>
import CaratIcon from '@/assets/carat.svg'
import JeopardyCard from '@/components/compete/JeopardyCard.vue'
import ChallengeModal from '@/components/compete/ChallengeModal.vue'
import CompeteService from 'compete-service'
export default {
  components: {
    CaratIcon, JeopardyCard, ChallengeModal,
  },
  async created () {
    await this.$store.dispatch('compete/fetchCompeteData')
    this.selectedCategory = this.contest.categories[0]
    this.generateNewGameBoard()
  },
  props: {
    contest: {
      type: Object,
      required: false,
    },
  },
  computed: {
    challengesForCategory () {
      return this.game[this.selectedCategory]
    },
    challenges () {
      return this.$store.getters['compete/challenges']
    },
    categories () {
      return this.$store.getters['compete/categories'].filter(category => {
        return this.contest.categories.includes(category._id) && category.active
      })
    },
    allChallengesCompleted () {
      const allChallenges = this.categories.map(category => category.challenges).flat()
      return allChallenges.every(challenge => this.hasCompleted(challenge._id))
    },
  },
  data () {
    return {
      selectedCategory: undefined,
      game: undefined,
    }
  },
  methods: {
    generateRandomIndex (lengthOfArray) {
      return Math.floor(Math.random() * lengthOfArray)
    },
    generateRandomChallengeArrayOfFixedLength (challenges, challengeArrayLength) {
      let randomIndex
      const fixedLengthChallengeArray = []

      const incompleteChallenges = challenges.filter(challenge => !this.hasCompleted(challenge._id))
      for (let i = 0; i < challengeArrayLength; i++) {
        if (incompleteChallenges.length === 0) break
        randomIndex = this.generateRandomIndex(incompleteChallenges.length)
        fixedLengthChallengeArray.push(incompleteChallenges[randomIndex])
        incompleteChallenges.splice(randomIndex, 1)
      }

      const sortedFixedLengthChallengeArray = this.sortChallengesByPoints(fixedLengthChallengeArray)

      if (sortedFixedLengthChallengeArray.length < challengeArrayLength) {
        const numberOfCompletedChallengedToAdd = challengeArrayLength - sortedFixedLengthChallengeArray.length
        const completedChallenges = challenges.filter(challenge => this.hasCompleted(challenge._id))
        for (let i = 0; i < numberOfCompletedChallengedToAdd; i++) {
          if (completedChallenges.length === 0) break
          randomIndex = this.generateRandomIndex(completedChallenges.length)
          sortedFixedLengthChallengeArray.push(completedChallenges[randomIndex])
          completedChallenges.splice(randomIndex, 1)
        }
      }
      return sortedFixedLengthChallengeArray
    },
    showModalWithChallenge (challenge) {
      this.$refs.challengeModal.open(challenge)
    },
    hasCompleted (id) {
      return this.$store.getters['compete/hasCompleted'](id)
    },
    async generateNewGameBoard () {
      const newGame = {}
      for (const category of this.categories) {
        newGame[category._id] = this.contest.maxChallengesToDisplayPerCategory !== 0 ? this.generateRandomChallengeArrayOfFixedLength(category.challenges, this.contest.maxChallengesToDisplayPerCategory) : this.sortChallengesByPoints(category.challenges)
      }
      this.game = newGame
    },
    async checkAnswer (challengeId, answer) {
      return await CompeteService.checkAnswer(challengeId, answer)
    },
    sortChallengesByPoints (challenges) {
      // slice() called because otherwise throwing "You may have an infinite update loop in a component render function."
      // something to do with sort mutating so it re-renders and slice produces a shallow copy
      return challenges.slice().sort((a, b) => {
        if (a.level === 'GOLD') return 1
        if (a.level === 'SILVER' && b.level === 'BRONZE') return 1
        return -1
      })
    },
  },
}
</script>

<style>
</style>
