<template>
  <div v-if="assessment">
    <div class="flex flex-col items-center">
      <div class="text-4xl font-bold">{{ assessment.title }}</div>
      <div class="text-lg font-normal">{{ assessment.description }}</div>
    </div>
    <div>
      <ul class="list-none border-b-2 border-th-text lg:px-8 pb-2 mt-8 mb-4 space-y-1 lg:items-center content-center flex flex-col lg:flex-row justify-between">
        <li class="font-bold w-64 text-left">
          {{ assessment.questions }} Question{{ assessment.questions !== 1 ? 's' : '' }} (100 pts)
        </li>
        <li class="font-bold w-64 text-left lg:text-center">
          Passing Score: <span class="font-normal ">{{ assessment.passScore }} pts</span>
        </li>
        <li class="font-bold w-64 text-left lg:text-right">
          {{ dateText }}: <span class="font-normal">{{ dateValue }}</span>
        </li>
      </ul>
    </div>

    <div v-if="this.ifThereIsSubmission && !started" class="flex flex-col items-center text-base">
      <div class="w-full sm:w-96 flex-1 sm:full bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary p-4 font-bold text-center rounded-md">
        <div class="text-center uppercase">
          <div class="pb-4" v-if="this.highestSubmission.score >= submission.assessment.passScore">
            Assessment Complete
          </div>
        </div>
        <div class="flex flex-row justify-between gap-10">
          <div class="flex flex-col items-start gap-4">
            <div v-if="this.latestSubmission" class="text-left">
              <div class="text-sm font-medium">Latest Submission</div>
              <div class="font-">{{ this.latestSubmission.score.toString().slice(0,5) }} points of 100</div>
              <div class="text-sm" v-if="this.latestSubmission.score >= submission.assessment.passScore">You Passed</div>
              <div class="text-sm" v-else>Did Not Pass</div>
              <div class="font-normal text-sm">{{ new Date(this.latestSubmission.submittedAt).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
            </div>
            <div v-if="this.highestSubmission" class="text-left">
              <div class="text-sm font-medium">Best Submission</div>
              <div class="font-">{{ this.highestSubmission.score.toString().slice(0,5) }} points of 100</div>
              <div class="text-sm" v-if="this.highestSubmission.score >= submission.assessment.passScore">You Passed</div>
              <div class="text-sm" v-else>Did Not Pass</div>
              <div class="font-normal text-sm">{{ new Date(this.highestSubmission.submittedAt).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-end gap-4">
            <div class="text-right">
              <div class="text-sm font-medium">Submissions</div>
              <div class="font-normal">{{ this.latestSubmission.numberOfSubmissions }}</div>
            </div>
            <div class="text-right">
              <div class="text-sm font-medium">Remaining Attempts</div>
              <div class="font-normal">{{ assessment.unlimitedAttempts ? "Unlimited": this.assessment.numberOfAttemptsAllowed - this.latestSubmission.numberOfSubmissions }}</div>
              <md-button v-if="this.submissionsLeft" @click="start" class="md-primary md-raised focus:outline-none -mr-1">Take Again</md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!this.ifThereIsSubmission && !started" class="flex flex-col items-center pt-16">
        <div class="text-xl mb-3"> {{ assessment.unlimitedAttempts ? "Unlimited Attempts" : "Attempts: " + assessment.numberOfAttemptsAllowed }} </div>
      <div
        @click="isOpen ? start() : ''"
        class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md uppercase"
        :class="{ 'cursor-pointer': isOpen, 'opacity-50 cursor-default': !isOpen }"
      >
        Start Now
      </div>
      <div v-if="!isOpen" class="mt-4">
        {{ notOpenText }}
      </div>
    </div>
    <div v-else-if="started" class="flex flex-col w-full items-center py-8">
      <div class="flex flex-col w-full xl:w-1/2 md:w-3/4 space-y-8">
        <div v-for="(question, questionNum) in questions" :key="questionNum">
          <QuestionCard :question="question" :questionNum="questionNum" :totalQuestions="questions.length" @answerChanged="(val) => answers[questionNum] = val"/>
        </div>
      </div>
      <div class="flex flex-col items-center pt-16">
        <div
          @click="submit"
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
        >
          Submit
        </div>
      </div>
    </div>
    <LoginModal ref="loginModal" />
  </div>
</template>

<script>
import CompeteService from 'compete-service'

import QuestionCard from '@/components/compete/assessments/QuestionCard.vue'
import LoginModal from '@/components/utilities/LoginModal'

const AssessmentState = {
  BeforeOpen: 0,
  Open: 1,
  AfterClose: 2,
  Archived: 3,
}

export default {
  components: {
    QuestionCard,
    LoginModal,
  },
  props: {
    _id: {
      type: String,
      required: false,
    },
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    assessment () {
      return this.$store.getters['compete/assessmentById'](this._id)
    },
    submission () {
      return this.$store.getters['compete/submissionForId'](this._id)
    },
    siteMetadata () {
      return this.$store.getters['metadata/siteMetadata']
    },
    assessmentState () {
      if (this.assessment.archived) {
        return AssessmentState.Archived
      } else if (this.assessment.openAt && new Date(this.assessment.openAt) > new Date()) {
        return AssessmentState.BeforeOpen
      } else if (this.assessment.closeAt && new Date(this.assessment.closeAt) < new Date()) {
        return AssessmentState.AfterClose
      }
      return AssessmentState.Open
    },
    dateText () {
      switch (this.assessmentState) {
        case AssessmentState.Archived:
          return 'Closed'
        case AssessmentState.BeforeOpen:
          return 'Opens'
        case AssessmentState.AfterClose:
          return 'Closed'
        case AssessmentState.Open:
          return 'Closes'
      }
      return undefined
    },
    dateValue () {
      if (this.assessmentState === AssessmentState.Archived) {
        return 'Archived'
      } else if (this.assessmentState === AssessmentState.BeforeOpen) {
        return new Date(this.assessment.openAt).toLocaleString()
      }
      return this.assessment.closeAt ? new Date(this.assessment.closeAt).toLocaleString() : 'Never'
    },
    isOpen () {
      return this.assessmentState === AssessmentState.Open
    },
    notOpenText () {
      switch (this.assessmentState) {
        case AssessmentState.Archived:
          return 'Assessment has been archived.'
        case AssessmentState.BeforeOpen:
          return `Assessment will open ${this.dateValue}.`
        case AssessmentState.AfterClose:
          return `Assessment closed on ${this.dateValue}.`
      }
      return undefined
    },
    // get all submisisons
    submissions () {
      return this.$store.getters['compete/submissionsForId'](this._id)
    },
    // get highest submission
    highestSubmission () {
      return this.$store.getters['compete/highestSubmission'](this._id)
    },
    // get the latest submission
    latestSubmission () {
      return this.$store.getters['compete/latestSubmission'](this._id)
    },
    // check if there is a submission
    ifThereIsSubmission () {
      return this.submissions.length > 0
    },
    // if there are any more submissions left
    submissionsLeft () {
      if (this.latestSubmission) {
        if (this.assessment.unlimitedAttempts) {
          return true
        } else {
          return this.latestSubmission.numberOfSubmissions < this.assessment.numberOfAttemptsAllowed
        }
      } else {
        return false
      }
    },

  },
  data () {
    return {
      questions: [],
      answers: [],
      started: false,
    }
  },
  created () {
    this.checkIfAssessmentsEnabledForInstance()
  },
  mounted () {
    this.$store.dispatch('compete/fetchAssessments')
    if (this.user) {
      this.$store.dispatch('compete/fetchUserSubmissions', { userId: this.user._id })
    }
  },
  methods: {
    checkIfAssessmentsEnabledForInstance () {
      if (this.siteMetadata?.enabledFeatures?.assessments !== true) {
        this.$router.push({ name: 'compete-main' })
      }
    },
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    async start () {
      if (!this.user) {
        this.showLoginModal('Create an account to take assessments')
        return
      }
      const data = await CompeteService.startAssessment(this._id)
      this.questions = data.questions
      this.started = true
    },
    async submit () {
      const cleanedAnswers = this.answers.map(answer => answer && answer.trim().length > 0 ? answer.trim() : null)
      const result = await CompeteService.submitAssessment(this._id, cleanedAnswers)
      if (result.errors) {
        console.log(result.errors)
      } else {
        await this.$store.dispatch('compete/fetchUserSubmissions', { userId: this.user._id })
      }
      this.started = false
    },
    resetAnswers () {
      this.answers = new Array(this.questions.length).fill(null)
    },
  },
  watch: {
    questions () {
      this.resetAnswers()
    },
  },
}
</script>

<style>

</style>
