import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import Toast from './components/utilities/Toast.vue'
import CopyrightFooter from './components/utilities/CopyrightFooter.vue'
import BrandMark from './components/utilities/BrandMark.vue'

import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'

import { simpleTimestamp } from './utilities/timestampFilters'

import ImageWithAuth from '@/directives/image-with-auth'

// import full bundle for development
// import VueMaterial from 'vue-material'
// import individual ui elements for compact build
import { MdSwitch, MdRadio, MdButton, MdIcon, MdTooltip } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import '@/scss/main.scss'

var v

// AXIOS CONFIGURATION

// Get API URL from environment or default for development.
Axios.defaults.baseURL = API_URL || 'http://localhost/'

// Response interceptor to handle API errors.
async function axiosResponseFailure (error) {
  // If error is 401, log the user out.
  if (error.response && error.response.status === 401) {
    await store.commit('auth/clearStorage')
    router.push({ name: 'login' })
  }
  // If other error, maybe we should show a friendly error message.
  // For now just return.
  return Promise.reject(error)
}

// Response interceptor to handle API successes.
function axiosResponseSuccess (response) {
  if (response.data.user) {
    if (response.data.requestPath !== '/logout') {
      store.commit('auth/saveUser', response.data.user)
    }
  }
  return response
}

Axios.interceptors.response.use(axiosResponseSuccess, axiosResponseFailure)

// Use an interceptor to process authentication.
Axios.interceptors.request.use(config => {
  const token = USE_SSO ? v.$auth?.getAccessToken() : store.state.auth.token
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`
  }

  return config
})

Vue.config.productionTip = false
// import full bundle for development
// Vue.use(VueMaterial)
// import individual ui elements for compact build
Vue.use(MdSwitch)
Vue.use(MdRadio)
Vue.use(MdButton)
Vue.use(MdIcon)
Vue.use(MdTooltip)

Vue.use(VueCookies)
Vue.use(Vuelidate)

Vue.filter('simpleDate', simpleTimestamp)

Vue.directive('image-with-auth', ImageWithAuth)

Vue.component('Toast', Toast)
Vue.component('CopyrightFooter', CopyrightFooter)
Vue.component('BrandMark', BrandMark)

v = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
