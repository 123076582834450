import Axios from 'axios'
import store from '@/store'

export default {
  async resetChallengeCompletions (id) {
    try {
      const response = await Axios.put(`/users/${id}/reset-challenge-completions`)
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      // set stuff in the store
      store.commit('auth/saveUser', responseData.data)
      return true
    } catch (err) {
      return false
    }
  },
  async fetchProfile (id) {
    try {
      const response = await Axios.get(`/users/${id}`)
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      // set stuff in the store
      store.commit('auth/saveUser', responseData.data)
      return true
    } catch (err) {
      return false
    }
  },
  async updateProfile (id, updates) {
    const profileData = {
      firstName: updates.firstName,
      lastName: updates.lastName,
      customUserFields: updates.customUserFields,
    }

    try {
      const response = await Axios.put(`/users/${id}`, profileData)
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      // set stuff in the store
      store.commit('auth/saveUser', responseData.data)
      return true
    } catch (err) {
      return false
    }
  },
  async resetPassword (id, currentPassword, newPassword) {
    try {
      const response = await Axios.put(`/users/${id}/authenticated-password-reset`, { currentPassword, newPassword })
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      store.commit('auth/saveUser', responseData.data)
      return true
    } catch (err) {
      return false
    }
  },
  async logout () {
    try {
      const response = await Axios.delete('/users/logout')
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      return true
    } catch (err) {
      return false
    }
  },
}
