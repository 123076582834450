<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative text-th-text-secondary"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-col items-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-xl font-bold">{{item.title}}</div>
          <div class="text-md">Contest Score: {{contestScore}} out of {{possibleScore}}</div>
        </div>
        <div class="flex justify-between items-center">
          <ArrowRightCircle
            v-if="currentChallenge > 0"
            @click="switchCallenge('down')"
            class="cursor-pointer fill-current transform rotate-180 w-10 h-10"
          />
          <div v-else class="w-10 h-10"></div>
          <div class="flex flex-col">
            <div class="text-center">Challenge {{currentChallenge + 1}} of {{challenges.length}}</div>
            <div class="text-center">{{pointConversion(challenges[currentChallenge].level)}} Points</div>
          </div>
          <ArrowRightCircle
            v-if="currentChallenge + 1 < challenges.length"
            @click="switchCallenge('up')"
            class="cursor-pointer fill-current w-10 h-10"
          />
          <div v-else class="w-10 h-10"></div>
        </div>
        <div class="text-xl my-4">{{challenges[currentChallenge].question}}</div>

        <div v-if="challenges[currentChallenge].type === 'FREE_TEXT'">
          <div class="flex flex-row w-full items-center">
            <div class="text-lg font-bold text-opacity-50 uppercase pr-2">Answer:</div>
            <input
              type="text"
              v-model="answer[currentChallenge]"
              autocomplete="off"
              :disabled="submitted[currentChallenge]"
              class="flex-grow bg-th-secondary border-th-text-secondary border-2 rounded text-2xl text-th-text px-2 py-1 text-center focus:outline-none"
            />
          </div>
        </div>
        <div v-else-if="challenges[currentChallenge].type === 'CHOICE'">
          <div class="space-y-2">
            <div
              v-for="(option,index) in challenges[currentChallenge].options"
              :key="`option-${index}`"
              @click="!submitted[currentChallenge] ? selectedOption = option : null"
              class="py-2 px-3 rounded-lg transition-all duration-150 border-2 border-th-text-secondary"
              :class="{'hover:bg-th-text-secondary hover:text-th-secondary': !submitted[currentChallenge], 'bg-th-text-secondary text-th-secondary': selectedOption === option, 'bg-th-secondary text-th-text-secondary' : selectedOption !== option}"
            >{{option}}</div>
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="!submitted[currentChallenge]"
            v-on="answer[currentChallenge] ? { click: submitAnswer } : {}"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase"
            :class="{'opacity-60': !answer[currentChallenge], 'cursor-pointer': answer[currentChallenge]}"
          >Submit Answer</div>
          <div v-if="submitted[currentChallenge]">
            <div
              v-if="result[currentChallenge] === undefined"
              class="text-center py-2 px-2 rounded-lg text-th-text font-bold text-opacity-75 uppercase"
            >Processing...</div>
            <div
              v-if="result[currentChallenge] !== undefined && result[currentChallenge]"
              class="bg-green-600 text-white text-center py-2 px-2 rounded-lg"
            >Congrats! The submitted answer is correct</div>
            <div
              v-if="result[currentChallenge] !== undefined && !result[currentChallenge]"
              class="bg-red-700 text-white text-center py-2 px-2 rounded-lg"
            >Sorry! The submitted answer is incorrect</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'
import ArrowRightCircle from '@/assets/arrow-right-circle.svg'

export default {
  components: {
    CloseIcon,
    ArrowRightCircle,
  },
  props: {
    submit: {
      type: Function,
      required: false,
    },
  },
  data () {
    return {
      visible: false,
      item: undefined,
      challenges: [],
      answer: [],
      result: [],
      submitted: [],
      contestScore: 0,
      selectedOption: undefined,
      currentChallenge: 0,
    }
  },
  computed: {
    possibleScore () {
      var total = 0
      this.challenges.forEach(challenge => {
        total += this.pointConversion(challenge.level)
      })
      return total
    },
  },
  methods: {
    open (item, challenges, challengeCompletions) {
      this.currentChallenge = 0
      this.challenges = challenges
      this.item = item
      this.visible = true
      challenges.forEach(challenge => {
        if (challengeCompletions.find(completion => completion.challenge === challenge._id)) {
          this.answer.push(challenge.answer)
          this.result.push(true)
          this.submitted.push(true)
        } else {
          this.answer.push(undefined)
          this.result.push(undefined)
          this.submitted.push(false)
        }
      })
      this.calculateContestScore()
    },
    close () {
      this.visible = false
      this.item = undefined
      this.answer = []
      this.result = []
      this.submitted = []
      this.$emit('closed')
    },
    async submitAnswer () {
      const submitted = [...this.submitted]
      submitted[this.currentChallenge] = true
      this.submitted = submitted

      const result = [...this.result]
      result[this.currentChallenge] = await this.submit(this.challenges[this.currentChallenge]._id, this.answer[this.currentChallenge])
      this.result = result

      // I'm using this to refresh the view. Not sure why its not working without it.
      this.calculateContestScore()
      var temp = this.selectedOption
      this.selectedOption = ''
      this.selectedOption = temp
    },
    switchCallenge (direction) {
      this.selectedOption = undefined
      if (!this.submitted[this.currentChallenge]) {
        this.answer[this.currentChallenge] = undefined
      }
      switch (direction) {
        case 'up':
          this.currentChallenge += 1
          break
        case 'down':
          this.currentChallenge -= 1
          break
      }
    },
    pointConversion (level) {
      switch (level) {
        case 'GOLD':
          return 500
        case 'SILVER':
          return 300
        default:
          return 100
      }
    },
    calculateContestScore () {
      var total = 0
      for (var i = 0; i < this.challenges.length; i++) {
        if (this.result[i]) {
          total += this.pointConversion(this.challenges[i].level)
        }
      }
      this.contestScore = total
    },
  },
  watch: {
    selectedOption () {
      this.answer[this.currentChallenge] = this.selectedOption
    },
    currentChallenge () {
      if (this.submitted[this.currentChallenge] && this.result[this.currentChallenge]) {
        this.selectedOption = this.challenges[this.currentChallenge].answer
      }
    },
  },
}
</script>

<style>
</style>
