<template>
    <div :class="`flex flex-col h-full ${isBadgeView && 'md:flex-row'} bg-th-secondary border-th-text-secondary border-2 rounded-md shadow overflow-hidden shadow-lg w-full`">
      <div :class="`h-56 ${isBadgeView && 'md:w-96'} flex flex-col flex-none`">
        <div class="grid grid-rows-1 grid-cols-1 h-full w-full" v-if="linkEmbedded">
          <iframe :style="{ width: '100% !important', height: '100% !important' }" class="row-start-1 col-start-1" :src="embedUrl + (trackedPlay ? '?autoplay=1' : '')" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
          <div v-if="!trackedPlay" class="w-full h-full row-start-1 col-start-1 z-100 cursor-pointer" @click="trackVideoPlay" />
        </div>
        <img v-else-if="hasImage" ref="cardImage" class="h-56 object-cover" />
        <img v-else-if="item.thumbnailUrl" :src="item.thumbnailUrl" class="h-56 object-cover" />
        <div v-else
          class="h-56 bg-gray-900 flex items-center justify-center text-gray-200 text-2xl font-bold text-center px-2">
          {{ title }}
        </div>
      </div>
      <div class="flex flex-col px-3 mb-1 mt-2 flex-1">
        <div class="h-full flex flex-col">
          <div class="text-lg font-bold flex flex-row items-start">
            <div v-html="highlightTitle()" :class="`flex-grow py-1 mb-1 border-th-text-secondary text-center ${isBadgeView && 'md:text-left md:border-b-0'} text-th-text-secondary`" />
          </div>
          <div class="flex flex-col font-normal h-full mb-2 text-base text-th-text-secondary">

            <ExpandableText :html="highlightDescription()" />
            <!-- <div v-html="highlightDescription()"
              class="text-sm font-hairline text-th-text-secondary whitespace-pre-wrap" /> -->
          </div>
          <!-- ================ Ratings/Comments ================ -->
          <div class="flex flex-col align-bottom w-full text-th-text-secondary">
            <div :class="`border-b-0 ${isBadgeView && 'md:text-left md:border-b-0'} border-th-text-secondary flex flex-wrap items-center`">
              <div class="flex flex-row flex-nowrap" v-if="ratingsEnabled">
                <StarRating :star-size="25" :rating="item.averageStarRating" :read-only="true" :increment="0.01" v-if="theme.useStarRatings"></StarRating>
                <div class="flex flex-row flex-nowrap" v-else>
                  <div class="flex flex-row flex-nowrap mr-4 text-th-text-secondary">
                    <button @click.stop="upVote" class="mr-0.5 focus:outline-none"
                      v-bind:class="{ 'opacity-100': upVoted, 'opacity-60': !upVoted }">
                      <ThumbUpIcon />
                    </button>
                    <span class="text-sm">{{ upVoteCount }}</span>
                  </div>
                  <div class="flex flex-row flex-nowrap text-th-text-secondary">
                    <button @click.stop="downVote" class="mr-0.5 focus:outline-none"
                      v-bind:class="{ 'opacity-100': downVoted, 'opacity-60': !downVoted }">
                      <ThumbDownIcon />
                    </button>
                    <span class="text-sm">{{ downVoteCount }}</span>
                  </div>
                </div>
              </div>
              <md-button v-if="commentsEnabled" @click.stop="showCommentModal" class="md-primary md-dense focus:outline-none"><md-icon md-font-icon="material-icons">chat</md-icon> ({{ commentCount }})</md-button>
            </div>
            <div class="flex items-center space-x-2 w-full">
              <div class="flex items-end -ml-2 flex-1">
                <md-button
                  :href="itemType === 'practice' || item.contentType === 'link' ? link : undefined"
                  :target="itemType === 'practice' || item.contentType === 'link' ? '_blank' : undefined"
                  @click="handleExploreButtonClick"
                  :md-ripple="false"
                  class="md-primary md-raised focus:outline-none flex-none"
                >
                  <div class="flex items-center space-x-2">
                    <div>Explore</div>
                    <md-icon md-font-icon="material-icons" class="text-xs">arrow_outward</md-icon>
                  </div>
                </md-button>
                <div class="flex flex-col text-th-text-secondary pb-1">
                  <div v-if="licenseRequired" class="text-sm uppercase break-all line-clamp-1">License Required</div>
                  <div class="opacity-70 line-clamp-1 break-all">source: {{ source }}</div>
                </div>
              </div>
              <router-link v-if="associatedAssessment" :to="associatedAssessmentLink">
                <md-button
                  class="md-icon-button md-dense md-primary"
                  :md-ripple="false"
                >
                  <md-icon md-font-icon="material-icons">quiz</md-icon>
                </md-button>
              </router-link>
              <md-button
                v-if="shareButton || removeButton"
                @click.stop="addOrRemoveItemFromCollection"
                class="md-icon-button md-dense md-primary"
                :md-ripple="false"
              >
                <md-icon md-font-icon="material-icons">{{ shareButton ? 'collections_bookmark' : 'delete' }}</md-icon>
                <md-tooltip md-direction="top">Add to collection</md-tooltip>
              </md-button>
              <div v-if="theme.completionTrackingEnabled">
                <md-button
                  @click="showCompleteModal"
                  :class="`md-icon-button md-dense md-primary ${hasCompleted ? 'md-raised' : 'opacity-60'}`"
                >
                  <md-icon>verified</md-icon>
                  <md-tooltip md-direction="top">Mark as completed</md-tooltip>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ThumbUpIcon from '@/assets/thumb-up.svg'
import ThumbDownIcon from '@/assets/thumb-down.svg'
import EventService from 'event-service'
import ExpandableText from '@/components/utilities/ExpandableText.vue'
import Axios from 'axios'
import Buffer from 'buffer'
import { EMBED_REGEX } from '@/utilities/constants.js'
import StarRating from 'vue-star-rating'

export default {
  components: {
    ExpandableText,
    ThumbUpIcon,
    ThumbDownIcon,
    StarRating,
  },
  beforeMount () {
    if (!this.linkEmbedded) {
      this.fetchImage()
    }
  },
  data () {
    return {
      hasImage: true,
      loading: false,
      trackedPlay: false,
    }
  },
  props: {
    isBadgeView: {
      type: Boolean,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
    shareButton: {
      type: Boolean,
      required: false,
    },
    removeButton: {
      type: Boolean,
      required: false,
    },
    query: {
      type: String,
      required: false,
    },
  },
  computed: {
    itemIsLesson () {
      return this.itemType === 'lesson'
    },
    currentLesson () {
      return this.item
    },
    currentUser () {
      return this.$store.getters['auth/user']
    },
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    title () {
      return this.item.title
    },
    SST () {
      return this.item.sst
    },
    sstTracks () {
      return this.item.sstTracks
    },
    upVoteCount () {
      return this.item.upVoteCount || 0
    },
    downVoteCount () {
      return this.item.downVoteCount || 0
    },
    upVoted () {
      if (!this.currentUser) return false
      return this.item.upVote.includes(this.currentUser.id)
    },
    downVoted () {
      if (!this.currentUser) return false
      return this.item.downVote.includes(this.currentUser.id)
    },
    description () {
      return this.item.description
    },
    commentCount () {
      const theme = this.$store.getters['metadata/siteMetadata']
      if (theme && theme.requireCommentApproval) {
        return this.item.comments.filter((comment) => comment.adminApproved).length
      } else {
        return this.item.commentCount
      }
    },
    commentary () {
      return this.item.commentary || ''
    },
    commentsEnabled () {
      return this.item.commentsEnabled && this.theme.globalCommentsEnabled
    },
    ratingsEnabled () {
      return this.item.ratingsEnabled
    },
    link () {
      let webAddress = this.item.link
      if (webAddress.includes('learn/lecture')) {
        webAddress = webAddress.replace(
          webAddress.substring(
            webAddress.indexOf('learn/lecture'),
            webAddress.indexOf('?'),
          ),
          '',
        )
      }
      if (!webAddress.match('^[a-zA-Z-]+:')) {
        webAddress = 'http://' + webAddress
      }
      if ((!this.currentUser || !this.currentUser.email.includes('mitre.org')) && webAddress.includes('mitre.udemy')) {
        return webAddress.replace('mitre.udemy', 'digitalu.udemy')
      }
      if (this.currentUser?.email?.includes('mitre.org') && webAddress.includes('udemy')) {
        return webAddress.replace('udemy', 'mitre.udemy')
      }
      return webAddress
    },
    linkEmbedded () {
      return this.item.linkEmbedded
    },
    type () {
      return this.item.type
    },
    licenseRequired () {
      return this.item.licenseRequired
    },
    source () {
      const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(this.link)
      return !this.itemIsLesson || this.item.contentType === 'link' ? urlParts[1] : 'Self-Hosted Content'
    },
    embedUrl () {
      if (this.link) {
        const match = this.link.match(EMBED_REGEX)
        if (match) {
          return `https://www.youtube.com/embed/${match[1]}`
        }
      }
      return 'https://www.youtube.com/embed/404'
    },
    hasCompleted () {
      if (this.itemIsLesson) return this.$store.getters['learn/hasCompleted'](this.item._id)
      else return this.$store.getters['practice/hasCompleted'](this.item._id)
    },
    associatedAssessment () {
      return this.item.associatedAssessment
    },
    associatedAssessmentLink () {
      return `/compete/assessments/${this.associatedAssessment}`
    },
  },
  methods: {
    addOrRemoveItemFromCollection () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to curate a collection')
        return
      }
      if (this.shareButton) {
        this.$emit('share', this.item)
      } else {
        this.$emit('delete', this.item)
      }
    },
    addPracticeModule () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to curate a collection')
        return
      }
      this.$emit('share', this.item)
    },
    addLesson () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to curate a collection')
        return
      }
      this.$emit('share', this.item)
    },
    removeLesson () {
      this.$emit('delete', this.item)
    },
    getLessons () {
      this.$emit('getLessons')
    },
    showCommentModal () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to leave a comment')
        return
      }
      this.$emit('showCommentModal', this.item)
    },
    showCompleteModal () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to track your progress')
        return
      }
      this.$emit('showCompleteModal', { ...this.item, userRating: { upVoted: this.upVoted, downVoted: this.downVoted }, completed: this.hasCompleted })
    },
    highlightTitle () {
      if (!this.query || this.query === '') {
        return this.title
      }
      return this.title.replace(new RegExp(this.query, 'gi'), (match) => {
        return (
          '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' +
          match +
          '</span>'
        )
      })
    },
    highlightDescription () {
      if (!this.query || this.query === '') {
        return this.description
      }
      return this.description.replace(new RegExp(this.query, 'gi'), (match) => {
        return (
          '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' +
          match +
          '</span>'
        )
      })
    },
    highlightCommentary () {
      if (!this.query || this.query === '') {
        return this.commentary
      }
      return this.commentary.replace(new RegExp(this.query, 'gi'), (match) => {
        return (
          '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' +
          match +
          '</span>'
        )
      })
    },
    async upVote () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to leave a rating')
        return
      }
      if (this.itemIsLesson) {
        const result = await this.$store.dispatch('learn/upVoteLesson', {
          lessonId: this.item._id,
        })
        if (result.errors) {
          console.log(result.errors)
        } else {
          this.$emit('getLessons')
        }
      } else {
        const result = await this.$store.dispatch('practice/upVoteModule', { moduleId: this.item._id })
        if (result.errors) {
          console.log(result.errors)
        } else {
          this.$emit('getModules')
        }
      }
    },
    async downVote () {
      if (!this.currentUser) {
        this.$emit('showLoginModal', 'Create an account to leave a rating')
        return
      }
      if (this.itemIsLesson) {
        const result = await this.$store.dispatch('learn/downVoteLesson', {
          lessonId: this.item._id,
        })
        if (result.errors) {
          console.log(result.errors)
        } else {
          this.$emit('getLessons')
        }
      } else {
        const result = await this.$store.dispatch('practice/downVoteModule', { moduleId: this.item._id })
        if (result.errors) {
          console.log(result.errors)
        } else {
          this.$emit('getModules')
        }
      }
    },
    async addComment (commentText) {
      if (this.itemIsLesson) {
        await this.$store.dispatch('learn/addLessonComment', { lessonId: this.item._id, payload: { name: this.currentUser.firstName + ' ' + this.currentUser.lastName, userId: this.currentUser.id, comment: commentText } })
        this.$emit('getLessons')
      } else {
        await this.$store.dispatch('practice/addModuleComment', { moduleId: this.item._id, payload: { name: this.currentUser.firstName + ' ' + this.currentUser.lastName, userId: this.currentUser.id, comment: commentText } })
        this.$emit('getModules')
      }
    },
    async removeComment (comment) {
      if (this.itemIsLesson) {
        await this.$store.dispatch('learn/removeLessonComment', { lessonId: this.item._id, payload: comment })
        this.$emit('getLessons')
      } else {
        await this.$store.dispatch('practice/removeModuleComment', { moduleId: this.item._id, payload: comment })
        this.$emit('getModules')
      }
    },
    async addCompletion (data) {
      if (data.comment) {
        if (this.itemIsLesson) {
          await this.$store.dispatch('learn/addLessonComment', { lessonId: this.item._id, payload: { name: this.currentUser.firstName + ' ' + this.currentUser.lastName, userId: this.currentUser.id, comment: data.comment } })
        } else {
          await this.$store.dispatch('practice/addModuleComment', { moduleId: this.item._id, payload: { name: this.currentUser.firstName + ' ' + this.currentUser.lastName, userId: this.currentUser.id, comment: data.comment } })
        }
      }
      if (data.upVoteChanged) {
        await this.upVote()
      } else if (data.downVoteChanged) {
        await this.downVote()
      }
      console.log(data)
      if (data.starRating) {
        if (this.itemIsLesson) {
          await this.$store.dispatch('learn/addLessonStarRating', { lessonId: this.item._id, payload: { user: this.currentUser.id, rating: data.starRating } })
        } else {
          await this.$store.dispatch('practice/addModuleStarRating', { moduleId: this.item._id, payload: { user: this.currentUser.id, rating: data.starRating } })
        }
      }
      if (this.itemIsLesson) {
        await this.$store.dispatch('learn/addLessonCompletion', { lessonId: this.item._id })
        this.$emit('getLessons')
      } else {
        await this.$store.dispatch('practice/addModuleCompletion', { moduleId: this.item._id })
        this.$emit('getModules')
      }
    },
    async removeCompletion () {
      if (this.itemIsLesson) {
        await this.$store.dispatch('learn/removeLessonCompletion', { lessonId: this.item._id })
        await this.$store.dispatch('learn/removeLessonStarRating', { lessonId: this.item._id, userId: this.currentUser.id })
        this.$emit('getLessons')
      } else {
        await this.$store.dispatch('practice/removeModuleCompletion', { moduleId: this.item._id })
        await this.$store.dispatch('practice/removeModuleStarRating', { moduleId: this.item._id, userId: this.currentUser.id })
        this.$emit('getModules')
      }
    },
    async logLessonHit () {
      this.loading = true
      await EventService.createEvent({
        eventName: 'lessonClick',
        payload: { lessonId: this.item._id },
      })
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async handleExploreButtonClick () {
      if (this.loading) return
      if (this.itemType === 'practice' || this.item.contentType === 'link') {
        this.itemIsLesson ? this.logLessonHit() : this.logPracticeModuleHit()
      } else if (this.item.contentType === 'document') {
        this.viewContent()
      }
    },
    async logPracticeModuleHit () {
      await EventService.createEvent({ eventName: 'practiceModuleClick', payload: { practiceModuleId: this.item._id } })
    },
    async viewContent () {
      this.loading = true
      await EventService.createEvent({
        eventName: 'lessonClick',
        payload: { lessonId: this.item._id },
      })
      Axios.get(this.item.link, { responseType: 'blob' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response.data)
          const tab = window.open()
          tab.location.href = fileURL
          // const url = window.URL.createObjectURL(response.data)
          // const anchor = document.createElement('a')

          // anchor.href = url
          // anchor.setAttribute('target', '_blank')
          // // anchor.setAttribute('download', `${this.item.link.split('/').pop()}`)
          // document.body.appendChild(anchor)
          // anchor.click()
          // document.body.removeChild(anchor)
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 1000)
        })
    },
    tryLegacyImage () {
      // in case of legacy DCA content, use hard-coded images in assets folder
      const lessonOrPractice = this.itemIsLesson ? 'lessons' : 'practice'
      let pic
      try {
        pic = require(`@/assets/${lessonOrPractice}/${this.item._id}.jpg`)
      } catch (err) {
        try {
          pic = require(`@/assets/${lessonOrPractice}/${this.item._id}.png`)
        } catch (err) { }
      }
      if (pic) {
        this.$refs.cardImage && this.$refs.cardImage.setAttribute('src', `${pic}`)
        this.hasImage = true
      }
    },
    fetchImage () {
      Axios.get(`/object-store/download/images/${this.item._id}/${this.item._id}.png`, {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          if (response.status === 204) {
            this.hasImage = false
            this.tryLegacyImage()
          } else {
            this.hasImage = true
            // Do stuff here
            var mimeType = response.headers['content-type'].toLowerCase()
            var imgBase64 = Buffer.Buffer.from(response.data, 'binary').toString(
              'base64',
            )
            this.$refs.cardImage.setAttribute(
              'src',
              `data:${mimeType};base64,${imgBase64}`,
            )
          }
        })
        .catch((err) => {
          console.log('Error fetching image...')
          console.log(err)
          this.hasImage = false
          this.tryLegacyImage()
        })
    },
    trackVideoPlay () {
      this.logLessonHit()
      this.trackedPlay = true
    },
  },
}
</script>

<style lang="postcss" scoped>
.secondary-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 1);
}
</style>
