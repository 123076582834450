<template>
  <div>
    <div class="text-xs h-4 uppercase pointer-events-none font-semibold mb-1">{{inputLabel}}</div>
    <div class="flex items-center">
      <div class="relative w-full mr-2">
        <select
          class="block appearance-none w-full bg-th-secondary border-2 border-th-text-secondary py-2 px-4 pr-8 rounded-md leading-tight focus:outline-none text-sm text-th-text-secondary"
          id="control-position"
          :value="value"
          @input="updateValue"
        >
          <option
            v-for="option in optionsForInput"
            :key="option ? (option.key || option) : undefined"
            :value="option ? (option.key || option) : undefined"
          >{{option ? (option.name || option) : 'Select an Item'}}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <CaratIcon class="fill-current h-4 w-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaratIcon from '@/assets/carat.svg'

export default {
  props: {
    inputLabel: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    undefinedOption: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CaratIcon,
  },
  computed: {
    optionsForInput () {
      return this.undefinedOption ? [undefined, ...this.options] : this.options
    },
  },
  methods: {
    updateValue (event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style>
</style>
