<template>
  <div
    class="py-2 w-full text-center px-2 text-xs bg-th-navbar text-th-text-navbar uppercase"
  >
    <div class="w-full h-full relative flex flex-col justify-center items-center">
      <div>{{ copyrightText }}</div>
      <a
        v-if="contactEmail"
        :href="`mailto:${contactEmail}?subject=${contactEmailSubject}`"
        class="md:absolute md:right-0 md:my-auto mt-2 md:mt-0 underline cursor-pointer font-semibold"
        >Contact Us</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    copyrightText: {
      type: String,
      default: '',
    },
    contactEmail: {
      type: String,
      default: undefined,
    },
    contactEmailSubject: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>
