<template>
  <div v-if="siteMetadata" class="w-full h-full min-h-screen flex flex-col justify-center items-center">
    <Toast width="w-1/3" type="danger" v-model="showRegistrationError" :autoClose="5">
      <template v-slot:title>An Error Occurred</template>
      <template
        v-slot:msg
      >There was an issue with processing your registration. Please check your entries and try again.</template>
    </Toast>
    <BrandMark class="mb-8" :title="siteMetadata !== undefined ? siteMetadata.portalTitle : ''" :logoSize="siteMetadata !== undefined ? siteMetadata.logoSize : 'medium'" />
    <div class="flex flex-col w-full px-8 lg:px-0 lg:w-2/3" style="max-width:650px">
      <div class="text-th-text text-opacity-30 text-4xl font-extrabold uppercase mb-4">Registration</div>
      <div v-if="registrationSuccessful" class="space-y-6">
        <div>
          <div class="text-opacity-60 text-th-text">
            <strong>Registration successful!</strong>
            Please proceed to the login page to sign in.
          </div>
        </div>
        <div
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          @click="$router.replace({name: 'login'})"
        >Return to Login</div>
      </div>
      <div v-if="!registrationSuccessful && !validInvitation" class="space-y-6">
        <div>
          <div class="text-opacity-60 text-th-text">
            There is no valid invitation associated with this email.
            <strong>PLEASE CONTACT YOUR ADMINISTRATOR.</strong>
          </div>
        </div>
        <div
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          @click="$router.replace({name: 'login'})"
        >Return to Login</div>
      </div>
      <div v-if="!registrationSuccessful && validInvitation">
        <div
          class="text-opacity-60 text-th-text mb-4"
        >Fill out the information below to finish registration for the DevOps Coder Academy</div>
        <form autocomplete="off">
          <div class="space-y-4 pb-8 mt-4 mb-4 border-b border-th-text">
            <div class="text-sm">* indicates a required field</div>
            <div class="flex flex-col lg:flex-row lg:justify-between lg:space-x-4 lg:space-y-0">
              <ValidatedInputField
                class="w-full"
                label="First Name *"
                v-model="$v.registrationInfo.firstName.$model"
                :fieldError="errors && $v.registrationInfo.firstName.$invalid"
                :errorMessage="errorMessage('firstName')"
              />
              <ValidatedInputField
                class="w-full"
                label="Last Name *"
                v-model="$v.registrationInfo.lastName.$model"
                :fieldError="errors && $v.registrationInfo.lastName.$invalid"
                :errorMessage="errorMessage('lastName')"
              />
            </div>
            <ValidatedInputField
              v-for="field in siteMetadata.customUserFields"
              :key="field._id"
              class="w-full"
              :label="field.name + (field.required ? ' *' : '')"
              v-model.trim="$v.registrationInfo.customUserFields[field._id].$model"
              :fieldError="errors && $v.registrationInfo.customUserFields[field._id].$invalid"
              :errorMessage="errorMessage('requiredCustomField')"
            />
          </div>
          <div class="flex flex-col space-y-4 pb-8 mb-4 border-b border-th-text">
            <div>
              <div class="font-bold">Password Complexity Requirements</div>
              <ul class="list-disc list-inside text-sm">
                <li v-for="req in passwordRequirements" :key="req">{{ req }}</li>
              </ul>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
              <ValidatedInputField
                class="w-full"
                label="Password *"
                type="password"
                autocomplete="new-password"
                v-model="$v.registrationInfo.password.$model"
                :fieldError="errors && $v.registrationInfo.password.$invalid"
                :errorMessage="errorMessage('password')"
              />
              <ValidatedInputField
                class="w-full"
                label="Confirm Password *"
                type="password"
                autocomplete="new-password"
                v-model="$v.registrationInfo.confirmPassword.$model"
                :fieldError="errors && $v.registrationInfo.confirmPassword.$invalid"
                :errorMessage="errorMessage('confirmPassword')"
              />
            </div>
          </div>
          <div class="flex justify-between mb-10">
            <div
              class="border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
              @click="$router.replace({name: 'login'})"
            >Cancel</div>
            <div
              @click="validateRegistration()"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
            >Register</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators'
import { complexPassword, passwordRequirements } from '@/utilities/complexPassword'
import ValidatedInputField from '@/components/utilities/ValidatedInputField.vue'
import AuthService from 'auth-service'

const ERROR_MESSAGE_MAP = {
  firstName: 'Please provide a value for first name',
  lastName: 'Please provide a value for last name',
  requiredCustomField: 'Please provide a value for this field',
  password: 'Please provide a password that matches password requirements.',
  confirmPassword: 'Please make sure password entries match.',
}

export default {
  props: ['ident', 'token'],
  components: {
    ValidatedInputField,
  },
  computed: {
    errors () {
      return this.$v.$dirty
    },
    approvedDomains () {
      return this.$store.getters['metadata/approvedRegistrationDomains']
    },
    customFieldValidations () {
      const validations = {}
      if (this.siteMetadata) {
        for (const field of this.siteMetadata.customUserFields) {
          validations[field._id] = { required: requiredIf(() => field.required) } // change prop name to just 'required'?
        }
      }
      return validations
    },
  },
  async created () {
    this.validateInvitation()
    await this.fetchSiteMetadata()

    const customUserFields = {}
    for (const field of this.siteMetadata.customUserFields) {
      customUserFields[field._id] = ''
    }
    this.registrationInfo.customUserFields = customUserFields
  },
  data () {
    return {
      invalidEmailDomain: false,
      validInvitation: false,
      showRegistrationError: false,
      showExistingUserError: false,
      registrationSuccessful: false,
      registrationInfo: {
        firstName: '',
        lastName: '',
        customUserFields: undefined,
        password: '',
        confirmPassword: '',
      },
      siteMetadata: undefined,
      passwordRequirements,
    }
  },
  validations () {
    return {
      registrationInfo: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        customUserFields: {
          ...this.customFieldValidations,
        },
        password: {
          required,
          complexPassword,
        },
        confirmPassword: {
          sameAs: sameAs(function () { return this.registrationInfo.password }),
        },
      },
    }
  },
  methods: {
    async validateRegistration () {
      this.$v.$touch()
      // If there are not any errors, let's do the registration
      if (!this.$v.$invalid && !this.invalidEmailDomain) {
        try {
          console.log(this.$props.token)
          const result = await AuthService.inviteRegistration({
            ident: this.$props.ident,
            token: this.$props.token,
            firstName: this.registrationInfo.firstName,
            lastName: this.registrationInfo.lastName,
            customUserFields: this.registrationInfo.customUserFields,
            password: this.registrationInfo.password,
          })
          if (result.success) {
            this.registrationSuccessful = true
          } else {
            console.log(result)
            if (result.errors.find((error) => {
              return error.type === 'EXISTING_USER'
            })) {
              this.showExistingUserError = true
              return
            }
            this.showRegistrationError = true
          }
        } catch (err) {
          this.showRegistrationError = true
          console.log(err)
        }
      }
    },
    checkEmailDomain () {
      if (this.approvedDomains.length > 0 && this.registrationInfo.email !== '' && !this.approvedDomains.includes(this.registrationInfo.email.split('@')[1])) {
        this.invalidEmailDomain = true
      } else {
        this.invalidEmailDomain = false
      }
    },
    errorMessage (field) {
      return ERROR_MESSAGE_MAP[field]
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty(
        '--notification',
        this.siteMetadata.notificationColor,
      )
    },
    async validateInvitation () {
      try {
        const result = await AuthService.validateInvitation(this.$props.ident)
        this.validInvitation = result.data
      } catch (err) {
        this.validInvitation = false
        console.log(err)
      }
    },
  },
}
</script>

<style>
</style>
