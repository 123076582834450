import Axios from 'axios'

const itemName = 'badge'
const apiRoute = `${itemName}s`

export const getAll = async () => {
  const response = await Axios.get(apiRoute).catch(err => {
    console.log(`Error fetching ${itemName}`)
    console.log(err)
    return []
  })
  return response.data.data
}

export const getById = async (id) => {
  const response = await Axios.get(`${apiRoute}/${id}`).catch(err => {
    console.log(`Error getting ${itemName}`)
    console.log(err)
    return []
  })
  return response.data.data
}
