<template>
  <div v-if="visible" @click="dismiss" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center py-20">
    <div
      @click.stop
      class="max-h-full w-full lg:w-1/2 mx-6 px-3 py-3 mb-auto rounded-2xl bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary relative overflow-y-auto"
    >
      <div class="flex items-center rounded-2xl px-2 bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary w-full">
        <SearchIcon class="w-6 h-6 mr-2 fill-current" />
        <input ref="searchbar" v-model="searchInput" class="h-10 flex-1 bg-transparent focus:outline-none placeholder-current" type="text"
          placeholder="Search content" />
        <CloseIcon v-if="searchInput.length" class="w-6 h-6 ml-4 cursor-pointer fill-current" @click="searchInput = ''" />
      </div>

      <div v-if="this.siteMetadata.enabledSections.learn" class="mt-2">
        <span class="font-bold">Tracks</span>
        <div v-if="tracks && tracks.length > 0">
          <div @click="dismiss" v-for="track in filteredTracks" :key="track._id">
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: 'learn-main', hash: `#${track._id}` }"
            >{{track.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for tracks.
        </div>
      </div>
      <div v-if="this.siteMetadata.enabledSections.learn" class="mt-2">
        <span class="font-bold">Courses</span>
        <div v-if="courses && courses.length > 0">
          <div @click="dismiss" v-for="course in courses" :key="course._id">
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: 'learn-course', params: { _id: course._id }}"
            >{{course.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for courses.
        </div>
      </div>
      <div v-if="this.siteMetadata.enabledSections.learn" class="mt-2">
        <span class="font-bold">Lessons</span>
        <!-- Lessons without courses are hidden, there is no way to view these currently -->
        <div v-if="lessons && lessons.filter(lesson => lesson.courses.length > 0).length > 0">
          <div @click="dismiss" v-for="lesson in lessons.filter(lesson => lesson.courses.length > 0)" :key="lesson._id" >
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: 'learn-course', params: { _id: lesson.courses[0] }, query: { lesson: lesson._id }}"
            >{{lesson.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for lessons.
        </div>
      </div>
      <div v-if="this.siteMetadata.enabledSections.practice" class="mt-2">
        <span class="font-bold">Practice Modules</span>
        <div v-if="modules && modules.length > 0">
          <div @click="dismiss" v-for="practiceModule in modules" :key="practiceModule._id">
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: 'practice-main', query: { module: practiceModule._id } }"
            >{{practiceModule.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for practice modules.
        </div>
      </div>
      <div v-if="this.siteMetadata.enabledSections.compete" class="mt-2">
        <span class="font-bold">Assessments</span>
        <div v-if="assessments && assessments.length > 0">
          <div @click="dismiss" v-for="assessment in assessments" :key="assessment._id">
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: 'compete-assessments-exam', params: { _id: assessment._id } }"
            >{{assessment.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for assessments.
        </div>
      </div>
      <div v-if="this.siteMetadata.enabledSections.compete" class="mt-2">
        <span class="font-bold">Contests</span>
        <div v-if="contests && contests.length > 0">
          <div @click="dismiss" v-for="contest in contests" :key="contest._id">
            <router-link
              class="font-normal px-2 py-2 width-full hover:bg-gray-200 hover:bg-opacity-10 rounded-lg block"
              :to="{ name: `compete-${contest.type}`, params: { _id: contest._id } }"
            >{{contest.title}}</router-link>
          </div>
        </div>
        <div v-else class="font-normal px-2 py-2">
          No results for contests.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/search.svg'
import CloseIcon from '@/assets/close-x.svg'
import debounce from 'lodash.debounce'

export default {
  components: {
    SearchIcon,
    CloseIcon,
  },
  data () {
    return {
      visible: false,
      searchInput: '',
      searchLoading: false,
      debouncedQueryWatch: undefined,
    }
  },
  async created () {
    this.debouncedQueryWatch = debounce(async query => this.search(query), 500)
    this.searchInput = this.$route.params.searchTag || ''

    await this.$store.dispatch('metadata/fetchSiteMetadata')
  },
  beforeDestroy () {
    this.debouncedQueryWatch.cancel()
  },
  watch: {
    searchInput: function (newVal) {
      this.searchLoading = true
      this.debouncedQueryWatch(newVal)
    },
  },
  computed: {
    lessons () {
      return this.$store.getters['search/lessons']
    },
    courses () {
      return this.$store.getters['search/courses']
    },
    tracks () {
      return this.$store.getters['search/tracks']
    },
    filteredTracks () {
      return this.tracks.filter(track => track.isActive)
    },
    modules () {
      return this.$store.getters['search/modules']
    },
    assessments () {
      return this.$store.getters['search/assessments']
    },
    contests () {
      return this.$store.getters['search/contests']
    },
    siteMetadata () {
      return this.$store.getters['metadata/siteMetadata']
    },
  },
  methods: {
    open () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.searchbar.focus()
      })
    },
    dismiss () {
      console.log('dismissed')
      this.visible = false
      this.searchInput = ''
    },
    search (query) {
      const searchString = query.toLowerCase().trim()

      this.$store.dispatch('search/searchAll', searchString)

      this.searchLoading = false
    },
  },
}
</script>
