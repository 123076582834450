<template>
  <div class="flex flex-col items-center justify-center space-y-5 leading-none">
    <div class="font-black" style="font-size:200px;">404</div>
    <div class="font-semibold text-5xl">PAGE NOT FOUND</div>
    <div class="text-2xl w-1/2 text-center" style="line-height: 1.5">
      The page you are looking for could not be found. It might have been removed, has its name changed, is temporarily unavailable, or had some other error occur. Please try another page or go
      <a
        class="underline"
        href="/"
      >home</a>.
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
