import LearnService from 'learn-service'

export default {
  namespaced: true,
  state: {
    tracks: [],
    courses: [],
    lessons: [],
  },
  getters: {
    tracks: (state) => {
      return state.tracks
    },
    courses: (state) => {
      return state.courses
    },
    lessons: (state) => {
      return state.lessons
    },
    trackWithId: (state) => (id) => {
      return state.tracks.find(track => {
        return track._id === id
      })
    },
    courseWithId: (state) => (id) => {
      return state.courses.find(course => {
        return course._id === id
      })
    },
    hasCompleted: (state, getters, rootState, rootGetters) => (id) => {
      const completions = rootGetters['auth/user']?.completions.lessons || []
      return completions.find(completion => {
        return completion?.lesson?._id === id
      }) !== undefined
    },
  },
  mutations: {
    updateTracks (state, tracks) {
      state.tracks = tracks
    },
    updateCourses (state, courses) {
      state.courses = courses
    },
    updateLessons (state, lessons) {
      state.lessons = lessons
    },
  },
  actions: {
    async fetchTracks ({ commit }) {
      const tracks = await LearnService.getTracks()
      commit('updateTracks', tracks)
    },
    async fetchCourses ({ commit }) {
      const courses = await LearnService.getCourses()
      commit('updateCourses', courses)
    },
    async fetchLessons ({ commit }) {
      const lessons = await LearnService.getLessons()
      commit('updateLessons', lessons)
    },
    async fetchSSTJourneyMaps ({ commit }) {
      return await LearnService.getSSTJourneyMaps()
    },
    async fetchLearnData ({ dispatch }) {
      try {
        await Promise.all([
          dispatch('fetchTracks'),
          dispatch('fetchCourses'),
          dispatch('fetchLessons'),
        ])
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },
    async upVoteLesson ({ commit }, { lessonId }) {
      return await LearnService.upVoteLesson(lessonId)
    },
    async downVoteLesson ({ commit }, { lessonId }) {
      return await LearnService.downVoteLesson(lessonId)
    },
    async addLessonComment ({ commit }, { lessonId, payload }) {
      return await LearnService.addLessonComment(lessonId, payload)
    },
    async removeLessonComment ({ commit }, { lessonId, payload }) {
      return await LearnService.removeLessonComment(lessonId, payload)
    },
    async addLessonCompletion ({ commit }, { lessonId }) {
      return await LearnService.addLessonCompletion(lessonId)
    },
    async removeLessonCompletion ({ commit }, { lessonId }) {
      return await LearnService.removeLessonCompletion(lessonId)
    },
    async addLessonStarRating ({ commit }, { lessonId, payload }) {
      return await LearnService.addLessonStarRating(lessonId, payload)
    },
    async removeLessonStarRating ({ commit }, { lessonId, userId }) {
      return await LearnService.removeLessonStarRating(lessonId, userId)
    },
  },
}
