import LearnService from 'learn-service'
import PracticeService from 'practice-service'
import CompeteService from 'compete-service'

export default {
  namespaced: true,
  state: {
    tracks: [],
    courses: [],
    lessons: [],
    modules: [],
    contests: [],
    assessments: [],
  },
  getters: {
    tracks: (state) => {
      return state.tracks
    },
    courses: (state) => {
      return state.courses
    },
    lessons: (state) => {
      return state.lessons
    },
    modules: (state) => {
      return state.modules
    },
    contests: (state) => {
      return state.contests
    },
    assessments: (state) => {
      return state.assessments
    },
  },
  mutations: {
    updateTracks (state, tracks) {
      if (Array.isArray(tracks)) { state.tracks = tracks } else { state.tracks = [] }
    },
    updateCourses (state, courses) {
      if (Array.isArray(courses)) { state.courses = courses } else { state.courses = [] }
    },
    updateLessons (state, lessons) {
      if (Array.isArray(lessons)) { state.lessons = lessons } else { state.lessons = [] }
    },
    updateModules (state, modules) {
      if (Array.isArray(modules)) { state.modules = modules } else { state.modules = [] }
    },
    updateContests (state, contests) {
      if (Array.isArray(contests)) { state.contests = contests } else { state.contests = [] }
    },
    updateAssessments (state, assessments) {
      if (Array.isArray(assessments)) { state.assessments = assessments } else { state.assessments = [] }
    },
  },
  actions: {
    async searchTracks ({ commit }, query, limit = 10) {
      const tracks = await LearnService.searchTracks(query, limit)
      commit('updateTracks', tracks)
    },
    async searchCourses ({ commit }, query, limit = 10) {
      const courses = await LearnService.searchCourses(query, limit)
      commit('updateCourses', courses)
    },
    async searchLessons ({ commit }, query, limit = 10) {
      const lessons = await LearnService.searchLessons(query, limit)
      commit('updateLessons', lessons)
    },
    async searchModules ({ commit }, query, limit = 10) {
      const modules = await PracticeService.searchModules(query, limit)
      commit('updateModules', modules)
    },
    async searchContests ({ commit }, query, limit = 10) {
      const contests = await CompeteService.searchContests(query, limit)
      commit('updateContests', contests)
    },
    async searchAssessments ({ commit }, query, limit = 10) {
      const assessments = await CompeteService.searchAssessments(query, limit)
      commit('updateAssessments', assessments)
    },
    async searchAll ({ dispatch }, query, limit = 10) {
      dispatch('searchTracks', query, limit)
      dispatch('searchCourses', query, limit)
      dispatch('searchLessons', query, limit)
      dispatch('searchModules', query, limit)
      dispatch('searchContests', query, limit)
      dispatch('searchAssessments', query, limit)
    },
  },
}
