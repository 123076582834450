<template>
  <div class="w-full h-full min-h-screen flex flex-col bg-th-primary justify-center items-center">
    <BrandMark class="mt-8" :title="siteMetadata !== undefined ? siteMetadata.portalTitle : ''" />
    <div class="w-full px-10 sm:px-0 sm:w-2/3 md:w-3/5 lg:w-1/4 flex flex-col flex-grow" style="max-width:375px;">
      <div
        class="text-center text-sm font-thin"
      >{{ oauthData.clientName}} would like you to access {{siteMetadata.portalTitle}} on your behalf</div>
      <div class="flex w-full space-x-2 text-xs uppercase mt-4">
        <div
          class="bg-white bg-opacity-10 text-th-text text-opacity-75 px-4 py-2 w-full font-bold text-center rounded-md cursor-pointer"
          @click="authorizeApp"
        >Authorize</div>
        <div
          class="bg-white bg-opacity-10 text-th-text text-opacity-75 px-4 py-2 w-full font-bold text-center rounded-md cursor-pointer"
          @click="denyApp"
        >Deny</div>
      </div>
    </div>
    <CopyrightFooter class="mt-8" :copyrightText="siteMetadata.copyrightBy" />
  </div>
</template>

<script>

export default {
  created () {
    this.fetchSiteMetadata()
  },
  props: {
    oauthData: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      siteMetadata: undefined,
    }
  },
  methods: {
    async authorizeApp () {
      const userId = this.$store.getters['auth/user'].id
      window.location.replace(`/api/oauth/authorize/${this.oauthData.id}/grant/${userId}`)
    },
    async denyApp () {
      window.location.replace(`/api/oauth/authorize/${this.oauthData.id}/deny`)
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty(
        '--notification',
        this.siteMetadata.notificationColor,
      )
    },
  },
}
</script>

<style lang="postcss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  /* -webkit-text-fill-color: #f7eace !important;
  -webkit-box-shadow: 0 0 0px 1000px #a4895c inset; */
  transition: background-color 5000s ease-in-out 0s;
}
</style>
