<template>
  <div>
    <div class="flex flex-col items-start w-full">
      <div class="flex justify-between w-full">
        <div class="flex flex-1 flex-col">
          <div class="flex flex-row items-center">
            <AchieveIcon class="w-10 h-10 fill-current mr-4" />
            <div class="text-4xl font-bold">{{ contest ? contest.title : '' }}</div>
          </div>
          <div
            class="text-lg"
          >{{ contest ? contest.description : '' }}</div>
        </div>

        <!-- this scorecard appears on large screens -->
        <div class="hidden lg:flex">
          <div class="flex flex-col space-y-4 mr-4">
            <ScoreboardCard
              v-if="userCoinCounts"
              :item="{
                name: `${user.firstName} ${user.lastName}`,
                team: '---',
                rank: user.rank,
                overallScore: userScore,
                score: userCoinCounts,
              }"
            />
          </div>
          <div class="flex flex-col justify-center items-center">
            <div
              class="flex flex-col items-center cursor-pointer"
              @click="$router.push({ name: 'scoreboard-main' })"
            >
              <ArrowRightCircle class="fill-current w-10 h-10 mb-4" />
              <div class="text-center">View Scoreboard</div>
            </div>
          </div>
        </div>
      </div>

      <!-- this scorecard appears on small screens -->
      <div class="lg:hidden">
        <div class="mt-6 flex space-x-4 justify-center w-full">
          <ScoreboardCard
            v-if="userCoinCounts"
            :item="{
              name: `${user.firstName} ${user.lastName}`,
              team: '---',
              rank: user.rank,
              overallScore: userScore,
              score: userCoinCounts
            }"
          />
          <div class="hidden sm:flex flex-col justify-center items-center">
            <div
              class="flex flex-col items-center cursor-pointer"
              @click="$router.push({ name: 'scoreboard-main' })"
            >
              <ArrowRightCircle class="fill-current w-10 h-10 mb-2" />
              <div class="text-center">View Scoreboard</div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mt-8 md:mt-10">
        <jeopardy-board v-if="contest" :contest="contest" @updateScoreCard="fetchContestScoreForUser"/>
      </div>
    </div>
  </div>
</template>

<script>
import AchieveIcon from '@/assets/achieve.svg'
import ScoreboardCard from '@/components/scoreboard/ScoreboardCard.vue'
import ArrowRightCircle from '@/assets/arrow-right-circle.svg'
import JeopardyBoard from '@/components/compete/JeopardyBoard.vue'

import CompeteService from 'compete-service'

export default {
  components: {
    AchieveIcon,
    ScoreboardCard,
    ArrowRightCircle,
    JeopardyBoard,
  },
  async mounted () {
    await this.$store.dispatch('compete/fetchScores')
    await this.$store.dispatch('compete/fetchCompeteData')
    this.fetchContestScoreForUser()
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
  },
  computed: {
    leaderBoard () {
      return this.$store.getters['compete/highScores'](3)
    },
    user () {
      return this.$store.getters['auth/user']
    },
    contest () {
      return this.$store.getters['compete/contestForId'](this._id)
    },
  },
  data () {
    return {
      userScore: undefined,
      userCoinCounts: undefined,
    }
  },
  methods: {
    async fetchContestScoreForUser () {
      const { score, coinCounts } = await this.$store.dispatch('compete/fetchUserScoreForContest', { contestId: this._id, userId: this.user._id })
      this.userScore = score
      this.userCoinCounts = coinCounts
    },
    async fetchHighScores () {
      this.leaderBoard = await CompeteService.fetchHighScores()
    },
  },
}
</script>

<style>
</style>
