<template>
  <div class="flex flex-col items-start">
    <LoginModal ref="loginModal" />
    <addToCollectionModal
      v-if="user"
      ref="addToCollectionModal"
      itemType="Lesson"
    />
    <updateCompletionModal
      ref="completionModal"
      type="lesson"
      :item="selectedLesson"
      @addCompletion="addCompletion"
      @removeCompletion="removeCompletion"
    />
    <lessonCommentModal
      ref="commentModal"
      :item="selectedLesson"
      @addComment="addComment"
      @removeComment="removeComment"
    />
    <div class="flex flex-col md:flex-row w-full justify-between gap-5 pb-5">
      <div class="flex flex-col flex-1">
        <div class="flex flex-row items-center">
          <CheckBadge class="w-10 h-10 mr-4" />
          <div class="text-2xl sm:text-4xl font-bold pb-1">{{ badge?.title }}</div>
        </div>
        <div class="text-lg mb-8 font-normal">{{ badge?.description }}</div>
      </div>
      <ProgressCircle v-if="this.requirements" :color="theme.textColor" :progress="progressPercentage">
        <div
          v-if="!isBadgeComplete"
          class="text-center text-lg font-normal"
        >
          <div>Progress</div>
          <div class="">{{ `${numberOfRequirementsCompleted} / ${requirements.length}` }}</div>
        </div>
        <div v-else class="font-normal text-lg">Complete!</div>
      </ProgressCircle>
    </div>
    <div v-if="!isLoading" class="flex w-full justify-center">
        <div class="h-full w-full lg:w-3/4">
          <div v-if="badge?.testOutAssessment" class="flex flex-wrap items-center gap-x-6 gap-y-3 mb-6">
            <div class="text-xl font-normal">Confident in your skills? You can test out to earn your badge immediately</div>
                <md-button
                  :href="`/compete/assessments/${badge.testOutAssessment}`"
                  @click="() => {}"
                  :md-ripple="false"
                  class="md-primary md-raised focus:outline-none flex-none"
                >
                  <div class="flex items-center space-x-2">
                    <div>{{ isTestOutAssessmentComplete ? 'test Complete' : 'take test' }}</div>
                    <md-icon md-font-icon="material-icons">{{ isTestOutAssessmentComplete ? 'celebration' : 'quiz' }}</md-icon>
                  </div>
                </md-button>
          </div>
          <div class="text-xl font-normal pb-3">Complete all the items to earn your badge</div>
          <!-- <div class="grid grid-cols-1 h-1/4 md:grid-cols-2 xl:grid-cols-3 p-4 gap-8"> -->
          <div class="flex flex-col gap-6">
            <!-- <div v-for="lesson in this.course.lessons" :key="lesson._id"> -->
            <div class="flex justify-center" v-for="req in requirements" :key="req._id">
              <LessonCard
                v-if="req.requirementType === 'Lesson'"
                :ref="req._id"
                :item="req"
                itemType="lesson"
                :shareButton="true"
                :isBadgeView="true"
                @share="showAddToCollectionModal"
                @showLoginModal="showLoginModal"
                @showCommentModal="showCommentModal"
                @showCompleteModal="showCompleteModal"
                @getLessons="getLessons"
              />
              <AssessmentCard
                v-if="req.requirementType === 'Assessment'"
                :key="req._id"
                :item="req"
                :isBadgeView="true"
                @click="openAssessment(req._id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CheckBadge from '@/assets/check-badge.svg'
import LessonCard from '@/components/learn/LessonCard.vue'
import UpdateCompletionModal from '@/components/learn/UpdateCompletionModal'
import AddToCollectionModal from '@/components/profile/AddToCollectionModal.vue'
import LessonCommentModal from '@/components/learn/LessonCommentModal'
import LoginModal from '@/components/utilities/LoginModal'
import AssessmentCard from '@/components/compete/assessments/AssessmentCard.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default {
  components: {
    AssessmentCard,
    LoginModal,
    LessonCommentModal,
    AddToCollectionModal,
    UpdateCompletionModal,
    LessonCard,
    CheckBadge,
    ProgressCircle,
  },
  data: () => ({
    fill: { gradient: ['red', 'green', 'blue'] },
    selectedLesson: null,
    isLoading: false,
  }),
  props: {
    _id: {
      type: String,
      required: false,
    },
  },
  async mounted () {
    if (this.$store.getters['learn/tracks'].length === 0) {
      this.$store.dispatch('learn/fetchLearnData')
    }
    if (this.assessments.length === 0) this.isLoading = true
    await this.$store.dispatch('compete/fetchAssessments')
    if (this.user) {
      await this.$store.dispatch('compete/fetchUserSubmissions', { userId: this.user._id })
    }
    if (!this.badge) {
      await this.$store.dispatch('badges/getAll')
    }
    // setTimeout(() => this.isLoading = false, 1000)
    this.isLoading = false
  },
  methods: {
    openAssessment (assessmentId) {
      if (!this.user) {
        this.showLoginModal('Create an account to take assessments')
        return
      }
      this.$router.push({ name: 'compete-assessments-exam', params: { _id: assessmentId } })
    },
    getLessons () {
      this.$store.dispatch('learn/fetchLearnData')
    },
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    showAddToCollectionModal (item) {
      this.$refs.addToCollectionModal.open(item)
    },
    closeAddToCollectionModal () {
      this.$refs.addToCollectionModal.close()
    },
    showCommentModal (item) {
      this.selectedLesson = item
      this.$refs.commentModal.open()
    },
    closeCommentModal () {
      this.$refs.commentModal.close()
    },
    showCompleteModal (item) {
      this.selectedLesson = item
      this.$refs.completionModal.open()
    },
    closeCompleteModal () {
      this.$refs.completionModal.close()
    },
    addComment (commentText) {
      this.$refs[this.selectedLesson._id][0].addComment(commentText)
    },
    removeComment (comment) {
      this.$refs[this.selectedLesson._id][0].removeComment(comment)
    },
    addCompletion (completion) {
      this.$refs[this.selectedLesson._id][0].addCompletion(completion)
    },
    removeCompletion () {
      this.$refs[this.selectedLesson._id][0].removeCompletion()
    },
  },
  computed: {
    isTestOutAssessmentComplete () {
      return this.user?.completions.assessments.map(item => item.assessment._id).includes(this.badge.testOutAssessment)
    },
    isBadgeComplete () {
      return this.numberOfRequirementsCompleted === this.requirements.length || this.isTestOutAssessmentComplete
    },
    progressPercentage () {
      if (this.isBadgeComplete) return 100
      if (!this.requirements) return 0
      return (this.numberOfRequirementsCompleted / this.requirements.length * 100)
    },
    user () {
      return this.$store.getters['auth/user']
    },
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    completedLessonIds () {
      return this.user?.completions.lessons.map(lesson => lesson.lesson?._id) || []
    },
    completedAssessmentIds () {
      return this.user?.completions.assessments.map(assessment => assessment.assessment?._id) || []
    },
    numberOfRequirementsCompleted () {
      if (!this.requirements) return 0
      let numCompletions = 0
      for (const req of this.requirements) {
        if (req.requirementType === 'Lesson' && this.completedLessonIds.includes(req.resourceId)) {
          numCompletions++
        }
        if (req.requirementType === 'Assessment' && this.completedAssessmentIds.includes(req.resourceId)) {
          numCompletions++
        }
      }
      return numCompletions
    },
    badge () {
      return this.$store.getters['badges/badges']?.find(badge => badge._id === this._id)
    },
    requirements () {
      return this.badge?.requirements.map(req => {
        if (req.requirementType === 'Lesson') {
          return { ...req, ...this.lessons.find(lesson => lesson._id === req.resourceId) }
        } else {
          return { ...req, ...this.assessments.find(assessment => assessment._id === req.resourceId) }
        }
      })
    },
    lessons () {
      return this.$store.getters['learn/lessons']
    },
    assessments () {
      return this.$store.getters['compete/assessments']
    },
  },
  watch: {
    lessons: function (newVal) {
      if (this.selectedLesson) {
        this.selectedLesson = newVal.find(lesson => lesson._id === this.selectedLesson._id)
      }
    },
  },
}
</script>
