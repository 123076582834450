<template>
  <div class="flex flex-col items-start">
    <!-- TODO: page header component -->
    <div class="flex flex-row items-center">
      <CheckBadge class="w-10 h-10 mr-4" />
      <div class="text-4xl font-bold pb-1">Badge Programs</div>
    </div>
    <div class="text-lg mb-8">Complete all the items in a program to earn Badges</div>

    <div v-if="isLoading" class="mt-8 text-xl text-center w-full">Loading Content...</div>
    <div v-else class="flex justify-center w-full">
      <div v-if="badges.filter(badges => badges.isActive).length<1" class="text-xl text-center w-full">
        <div class="items-center h-full flex flex-col w-80 px-4 pb-4 py-2 mx-auto bg-th-secondary border-th-text-secondary border-2 rounded-md">
          <CheckMark class="w-32 h-32" />
          <div class="text-xl text-th-text-secondary py-4 font-bold">No badges active</div>
          <div class="grow font-normal text-lg text-th-text-secondary line-clamp-none mx-5">There are currently no active badges. Badges will show up here when created.  </div>
        </div>
      </div>
      <div v-else class="flex flex-col w-full lg:w-3/4 items-stetch">
        <div v-for="badge of badges" :key="badge._id" class="flex flex-col lg:flex-row p-3 md:p-5 lg:items-center shadow-lg bg-th-secondary text-th-text-secondary border-2 rounded mb-3 lg:pl-0 border-current items-start">
          <div class="hidden lg:block flex flex-col items-center mx-10">
            <CheckBadge class="w-32 h-32 flex-none" />
            <div class="flex w-full justify-center">
              <md-button @click="() => navigateToBadgeDetails(badge._id)" :md-ripple="false" class="md-primary md-raised md-dense">Explore</md-button>
            </div>
          </div>
          <div class="flex flex-col flex-1 gap-5 h-full lg:pr-6 pb-6 lg:pb-0">
            <!-- <md-button class="md-primary">Primary</md-button> -->
            <div class="text-xl">{{ badge.title }}</div>
            <div class="-ml-2 lg:hidden">
              <md-button @click="() => navigateToBadgeDetails(badge._id)" :md-ripple="false" class="md-primary md-raised md-dense">Explore</md-button>
            </div>
            <div class="font-normal text-base pb-6 lg:pb-0">
              <ExpandableText :text="badge.description" />
            </div>
            <div class="flex gap-6 font-medium text-base items-center">
              <div class="capitalize">{{ badge.requirements.length }} items</div>
              <div v-if="badge.testOutAssessment" class="flex gap-2">
                <md-icon md-font-icon="material-icons" class="md-primary">quiz</md-icon>
                <div>Test Out Assessment</div>
              </div>
            </div>
          </div>
          <ProgressCircle :color="theme.secondaryTextColor" :progress="progressPercentage(badge.requirements, badge.testOutAssessment)">
            <div
              v-if="numberOfRequirementsCompleted(badge.requirements) < badge.requirements.length && !user?.completions.assessments.map(item => item?.assessment?._id).includes(badge.testOutAssessment)"
              class="text-center text-lg font-normal"
            >
              <div>Progress</div>
              <div class="">{{ `${numberOfRequirementsCompleted(badge.requirements)} / ${badge.requirements.length}` }}</div>
            </div>
            <div v-else class="font-normal text-lg">Complete!</div>
          </ProgressCircle>
          <!-- <div class="flex lg:flex-col lg:space-y-3 items-center space-x-3 pt-4 lg:pt-0 lg:space-x-0 lg:ml-5">
            <div class="w-24 h-24 border-4 border-gray-400 rounded-full flex-none" />
            <div>Not Started</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExpandableText from '@/components/utilities/ExpandableText.vue'
import CheckBadge from '@/assets/check-badge.svg'
import CheckMark from '@/assets/check-mark.svg'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default {
  components: {
    ExpandableText,
    CheckBadge,
    CheckMark,
    ProgressCircle,
  },
  data: () => {
    return {
      isLoading: false,
    }
  },
  computed: {
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    badges () {
      return this.$store.getters['badges/badges'].filter(badge => badge.requirements.length && badge.isActive)
    },
    user () {
      return this.$store.getters['auth/user']
    },
    completedLessonIds () {
      return this.user?.completions.lessons.map(lesson => lesson.lesson?._id) || []
    },
    completedAssessmentIds () {
      return this.user?.completions.assessments.map(assessment => assessment.assessment?._id) || []
    },
  },
  async mounted () {
    if (this.badges?.length === 0) this.isLoading = true
    await this.getAllBadges()
    // setTimeout(() => this.isLoading = false, 1000)
    this.isLoading = false
  },
  methods: {
    numberOfRequirementsCompleted (requirements) {
      let numCompletions = 0
      for (const req of requirements || []) {
        if (req.requirementType === 'Lesson' && this.completedLessonIds.includes(req.resourceId)) {
          numCompletions++
        }
        if (req.requirementType === 'Assessment' && this.completedAssessmentIds.includes(req.resourceId)) {
          numCompletions++
        }
      }
      return numCompletions
    },
    progressPercentage (requirements, testOutAssessmentId) {
      if (!this.user) return 0
      if (this.user.completions.assessments.map(item => item?.assessment?._id).includes(testOutAssessmentId)) return 100
      return (this.numberOfRequirementsCompleted(requirements) / requirements.length * 100)
    },
    async getAllBadges () {
      await this.$store.dispatch('badges/getAll')
    },
    navigateToBadgeDetails (id) {
      console.log(id)
      this.$router.push({ name: 'badge-details', params: { _id: id } })
    },
  },
}
</script>
