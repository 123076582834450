<template>
  <div>
    <LoginModal ref="loginModal" />
    <div class="flex flex-col items-start">
      <div class="flex flex-row items-center">
        <LearnIcon class="w-10 h-10 fill-current mr-4" />
        <div class="text-4xl font-bold">Assessments</div>
      </div>
      <div
        class="text-lg mb-3"
      >Complete assigned assessments.</div>
      <div v-if="isLoading" class="mt-8 text-xl text-center w-full">Loading Content...</div>
      <div v-else class="flex flex-col items-start w-full">
        <div class="w-full pt-8">
          <div class="border-b-2 border-th-text pb-2 mb-4">
            <div class="text-2xl font-bold">Active Assessments</div>
            <div class="font-normal">Upcoming or already active assessments.</div>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-items-center">
            <AssessmentCard
              v-for="assessment in activeAssessments"
              :key="assessment._id"
              :item="assessment"
              @click="openAssessment(assessment._id)"
            />
          </div>
        </div>

        <div class="w-full pt-8">
          <div class="border-b-2 border-th-text pb-2 mb-4">
            <div class="text-2xl font-bold">Archived Assessments</div>
            <div class="font-normal">Past or archived assessments.</div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 justify-items-center px-4">
            <AssessmentCard
              v-for="assessment in archivedAssessments"
              :key="assessment._id"
              :item="assessment"
              @click="$router.push({ name: 'compete-assessments-exam', params: {_id: assessment._id} })"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LearnIcon from '@/assets/learn.svg'
import AssessmentCard from '@/components/compete/assessments/AssessmentCard.vue'
import LoginModal from '@/components/utilities/LoginModal'

export default {
  components: {
    LearnIcon,
    AssessmentCard,
    LoginModal,
  },
  created () {
    this.checkIfAssessmentsEnabledForInstance()
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    assessments () {
      return this.$store.getters['compete/assessments']
    },
    activeAssessments () {
      return this.assessments.filter(assessment => !assessment.archived && (!assessment.closeAt || new Date(assessment.closeAt) > new Date()))
    },
    archivedAssessments () {
      return this.assessments.filter(assessment => assessment.archived || (assessment.closeAt && new Date(assessment.closeAt) < new Date()))
    },
    siteMetadata () {
      return this.$store.getters['metadata/siteMetadata']
    },
  },
  async mounted () {
    if (this.assessments.length === 0) this.isLoading = true
    await this.$store.dispatch('compete/fetchAssessments')
    if (this.user) {
      await this.$store.dispatch('compete/fetchUserSubmissions', { userId: this.user._id })
    }
    this.isLoading = false
  },
  methods: {
    checkIfAssessmentsEnabledForInstance () {
      if (this.siteMetadata?.enabledFeatures?.assessments !== true) {
        this.$router.push({ name: 'compete-main' })
      }
    },
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    openAssessment (assessmentId) {
      if (!this.user) {
        this.showLoginModal('Create an account to take assessments')
        return
      }
      this.$router.push({ name: 'compete-assessments-exam', params: { _id: assessmentId } })
    },
  },
}
</script>

<style lang="postcss" scoped>
.vs-carousel__slide {
  flex: 0 0 auto;
}
</style>
