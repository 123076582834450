import CompeteService from 'compete-service'

const state = {
  scores: [],
  categories: [],
  challenges: [],
  contests: [],
  scoreboards: [],
  assessments: [],
  submissions: [],
}

const getters = {
  categories: (state) => {
    return state.categories
  },
  challenges: (state) => {
    return state.challenges
  },
  contests: (state) => {
    return state.contests
  },
  contestForId: (state) => (id) => {
    return state.contests.find(contest => {
      return contest._id === id
    })
  },
  scoreboards: (state) => {
    return state.scoreboards
  },
  allScores: (state) => {
    return state.scores
  },
  highScores: (state) => (count) => {
    return state.scores.slice(0, count)
  },
  hasCompleted: (state, getters, rootState, rootGetters) => (challengeId) => {
    const challengeCompletions = rootGetters['auth/user']?.completions.challenges || []
    return challengeCompletions.find(completion => {
      return completion.challenge === challengeId
    })
  },
  assessments: (state) => {
    return state.assessments
  },
  assessmentById: (state) => (id) => {
    return state.assessments.find(assessment => {
      return assessment._id === id
    })
  },
  submissions: (state) => {
    return state.submissions
  },
  submissionForId: (state) => (assessmentId) => {
    if (!Array.isArray(state.submissions)) return []
    return state.submissions.find(submission => {
      return submission.assessment._id === assessmentId
    })
  },
  // get all submissions based on ID
  submissionsForId: (state) => (assessmentId) => {
    return state.submissions.filter(submission => {
      return submission.assessment._id === assessmentId
    })
  },
  // return the submission with highest score based on assessment ID
  highestSubmission: (state) => (assessmentId) => {
    var submissions = []
    var highestSubmission
    submissions = state.submissions.filter(submission => {
      return submission.assessment._id === assessmentId
    })

    submissions.sort(function (a, b) { return b.score - a.score })

    highestSubmission = submissions[0]

    return highestSubmission
  },
  // return the latest submission based on assessment ID
  latestSubmission: (state) => (assessmentId) => {
    var submissions = []
    var latestSubmission
    submissions = state.submissions.filter(submission => {
      return submission.assessment._id === assessmentId
    })

    submissions.sort(function (a, b) { return b.numberOfSubmissions - a.numberOfSubmissions })

    latestSubmission = submissions[0]

    return latestSubmission
  },
}

const mutations = {
  updateScores: (state, scores) => {
    state.scores = scores
  },
  updateCategories (state, categories) {
    state.categories = categories
  },
  updateChallenges (state, challenges) {
    state.challenges = challenges
  },
  updateContests (state, contests) {
    state.contests = contests
  },
  updateScoreboards (state, scoreboards) {
    state.scoreboards = scoreboards
  },
  updateAssessments (state, assessments) {
    state.assessments = assessments
  },
  updateSubmissions (state, submissions) {
    state.submissions = submissions
  },
}

const actions = {
  async fetchScores ({ commit }) {
    const scores = await CompeteService.fetchScoreboard()
    commit('updateScores', scores)
  },
  async fetchScoreboards ({ commit }) {
    const scoreboards = await CompeteService.getAllScoreboards()
    commit('updateScoreboards', scoreboards)
  },
  async fetchUserScoreForContest ({ commit }, { contestId, userId }) {
    return await CompeteService.fetchUserScoreForContest(contestId, userId)
  },
  async generateNewGameBoard ({ commit }) {
    return await CompeteService.generateNewGameBoard()
  },
  async fetchCompeteData ({ dispatch }) {
    Promise.all([
      await dispatch('fetchContests'),
      await dispatch('fetchCategories'),
      await dispatch('fetchChallenges'),
    ])
  },
  async fetchCategories ({ commit }) {
    const categories = await CompeteService.getAllCategories()
    commit('updateCategories', categories)
  },
  async fetchChallenges ({ commit }) {
    const challenges = await CompeteService.getAllChallenges()
    commit('updateChallenges', challenges)
  },
  async fetchContests ({ commit }) {
    const contests = await CompeteService.getAllContests()
    commit('updateContests', contests)
  },
  async fetchAssessments ({ commit }) {
    const assessments = await CompeteService.getAllAssessments()
    commit('updateAssessments', assessments)
  },
  async fetchUserSubmissions ({ commit }, { userId }) {
    const submissions = await CompeteService.getUserSubmissions(userId)
    commit('updateSubmissions', submissions)
  },
}

export default { namespaced: true, state, getters, mutations, actions }
