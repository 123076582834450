<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative text-th-text-secondary"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center justify-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-xl font-bold ml-4">
            <div v-if="item.level==='BRONZE'">100 Points</div>
            <div v-else-if="item.level==='SILVER'">300 Points</div>
            <div v-else>500 Points</div>
          </div>
        </div>
        <div class="text-xl my-4">{{item.question}}</div>
        <div v-if="item.type === 'FREE_TEXT'">
          <div>
            <div class="flex flex-col w-full">
              <span class="text-th-secondary-text text-opacity-50 uppercase pb-2 text-sm">Type your answer here</span>
              <input
                type="text"
                v-model="answer"
                autocomplete="off"
                :disabled="submitted"
                class="bg-th-secondary border-2 border-th-text-secondary text-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div v-else-if="item.type === 'CHOICE'">
          <div class="space-y-2">
            <div
              v-for="(option,index) in shuffle(item.options, this.shuffleFlag)"
              :key="`option-${index}`"
              @click="!submitted ? selectedOption = option : null"
              class="py-2 px-3 rounded border-2 border-th-text-secondary font-normal transition-all duration-150 cursor-pointer"
              :class="{'bg-th-secondary text-th-text-secondary hover:bg-th-text-secondary hover:text-th-secondary': selectedOption !== option, 'bg-th-text-secondary text-th-secondary': selectedOption === option }"
            >{{option}}</div>
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="!submitted"
            v-on="answer ? { click: submitAnswer } : {}"
            class="bg-th-text-secondary text-th-secondary border-2 border-th-text-secondary px-4 py-2 w-full font-bold text-center rounded uppercase"
            :class="{'opacity-50': !answer, 'cursor-pointer': answer}"
          >Submit Answer</div>
          <div v-if="submitted">
            <div
              v-if="result === undefined"
              class="text-center py-2 px-2 rounded-lg text-th-text font-bold text-opacity-75 uppercase"
            >Processing...</div>
            <div
              v-if="result !== undefined && result"
              class="bg-green-600 text-white text-center py-2 px-2 rounded-lg"
            >Congrats! The submitted answer is correct</div>
            <div
              v-if="result !== undefined && !result"
              class="bg-red-700 text-white text-center py-2 px-2 rounded-lg"
            >Sorry! The submitted answer is incorrect</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'

export default {
  components: {
    CloseIcon,
  },
  props: {
    submit: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      visible: false,
      item: undefined,
      categoryId: undefined,
      answer: undefined,
      result: undefined,
      submitted: false,
      selectedOption: undefined,
      shuffleFlag: false,
    }
  },
  methods: {
    shuffle (array, flag) {
      if (flag === false) {
        var currentIndex = array.length; var temporaryValue; var randomIndex

        while (currentIndex !== 0) {
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex -= 1

          temporaryValue = array[currentIndex]
          array[currentIndex] = array[randomIndex]
          array[randomIndex] = temporaryValue
        }
        this.shuffleFlag = true
        return array
      } else {
        return array
      }
    },
    open (item) {
      this.item = item
      this.visible = true
    },
    close () {
      this.visible = false
      this.item = undefined
      this.categoryId = undefined
      this.answer = undefined
      this.result = undefined
      this.submitted = false
      this.selectedOption = undefined
      this.$emit('closed')
    },
    async submitAnswer () {
      this.submitted = true
      this.result = await this.submit(this.item._id, this.answer)
      this.$emit('updateScore')
    },
  },
  watch: {
    selectedOption () {
      this.answer = this.selectedOption
    },
  },
}
</script>

<style>
</style>
