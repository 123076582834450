<template>
  <div id="app" class="bg-th-primary w-full min-h-screen text-th-text font-semibold">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>
