<template>
  <div>
    <!-- ******************** TEAMS ******************** -->
    <div class="flex flex-col">
      <div class="flex flex-row items-center">
        <TeamIcon class="w-10 h-10 fill-current mr-4" />
        <div class="text-4xl font-bold">Team Management</div>
      </div>
      <div class="text-lg mb-8">Create a Team to share custom learning paths</div>
      <div class="mb-5">
        <div class="border-b-2 border-th-text mb-2">
          <div class="flex flex-row">
            <div class="text-center text-2xl font-bold pr-4 mb-3 capitalize">Invites</div>
          </div>
        </div>
        <div v-if="teamInvites.length > 0">
          <div v-for="invite in teamInvites" :key="invite._id">
            <div
              class="group flex flex-row items-center text-base font-normal py-1 rounded-sm justify-between hover:bg-white hover:bg-opacity-25 px-2"
            >
              <div>{{ invite.name }}</div>
              <div class="flex flex-row">
                <AcceptIcon
                  class="h-8 w-8 px-2 cursor-pointer rounded-sm hover:bg-white hover:bg-opacity-25"
                  @click.stop="acceptInvite(invite._id)"
                />
                <DeclineIcon
                  class="h-8 w-8 px-2 fill-current cursor-pointer rounded-sm hover:bg-white hover:bg-opacity-25"
                  @click.stop="declineInvite(invite._id)"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="italic pl-2 py-1">No incoming invites.</div>
      </div>
      <div>
        <div class="border-b-2 border-th-text mb-2">
          <div class="flex flex-row items-end mb-2">
            <div class="text-center text-2xl font-bold pr-4 capitalize">Teams</div>
            <div
            v-if="this.usersCanCreateTeams"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary pr-5 pl-4 py-2 text-center rounded cursor-pointer uppercase"
              @click="user ? showCreateTeamModal() : $refs.loginModal.open()"
            >+ New Team</div>
          </div>
        </div>
        <createTeamModal ref="createTeamModal" @submit="addTeam"></createTeamModal>
        <LoginModal ref="loginModal" :modalText="'Create an account to Team Up'"/>
        <div v-if="!teamsFetched" class="flex flex-col items-center">
          <TeamIcon class="w-32 fill-current py-5" />
          <div class="font-bold text-xl normal-case text-center">Fetching Teams...</div>
        </div>
        <div v-else-if="teams.length > 0">
          <div v-for="team in teams" :key="team._id">
            <TeamCard :team="team" :openId="openId" :userId="user._id" @delete="deleteTeam" @refresh="refreshTeams" :userTeamManagementSettings="siteMetadata.userTeamManagementSettings"/>
          </div>
        </div>
        <div v-else class="flex flex-col items-center">
          <TeamIcon class="w-40 fill-current py-5" />
          <div v-if="this.contactButtonEnabled"
            class="font-normal normal-case text-center"
          >{{this.usersCanCreateTeams ? 'You don\'t have any teams. Create one to share curated collections with your teammates.' :
          'You don\'t have any teams. Contact an administrator to be assigned to a team.'}}</div>
          <div v-else-if="!this.contactButtonEnabled">
          {{this.usersCanCreateTeams ? 'You don\'t have any teams. Create one to share curated collections with your teammates.' :
          'You don\'t have any teams. Please be patient to be assigned to a team by our admins.'}}</div>
          <a
          v-if="this.contactButtonEnabled && !this.usersCanCreateTeams" :href="`mailto:${contactEmail}`"
          class="bg-th-secondary text-th-text-secondary hover:bg-opacity-75 ext-opacity-75 pl-2 pr-3 my-1 mb-3 py-1 text-sm font-bold text-center rounded-md cursor-pointer uppercase">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamCard from '@/components/profile/TeamCard.vue'
import CreateTeamModal from '@/components/profile/CreateTeamModal.vue'
import AcceptIcon from '@/assets/check-mark.svg'
import DeclineIcon from '@/assets/close-x.svg'
import TeamIcon from '@/assets/team-icon.svg'
import LoginModal from '@/components/utilities/LoginModal.vue'

export default {
  components: {
    CreateTeamModal,
    AcceptIcon,
    DeclineIcon,
    TeamCard,
    TeamIcon,
    LoginModal,
  },
  mounted () {
    this.fetchSiteMetadata()
    if (!this.user) {
      this.teamsFetched = true
      this.$refs.loginModal.open()
      return
    }
    if (this.teams.length > 0) { this.teamsFetched = true }
    this.fetchTeams()
    this.$store.dispatch('team/fetchUsers')
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    teams () {
      return this.$store.getters['team/teams']
    },
    teamInvites () {
      return this.user ? this.$store.getters['auth/user'].teamInvites : []
    },
  },
  data () {
    return {
      openId: undefined,
      teamsFetched: false,
      siteMetadata: false,
      usersCanCreateTeams: false,
      contactButtonEnabled: false,
      contactEmail: '',
    }
  },
  methods: {
    async fetchTeams () {
      await this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      this.teamsFetched = true
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      this.usersCanCreateTeams = this.siteMetadata.userTeamManagementSettings.usersCanCreateTeams
      this.contactButtonEnabled = this.siteMetadata.contactUsEnabled
      this.contactEmail = this.siteMetadata.contactEmail
    },
    showCreateTeamModal () {
      this.$refs.createTeamModal.open()
    },
    closeCreateTeamModal () {
      this.$refs.createTeamModal.close()
    },
    showExistingTeamError () {
      this.$refs.createTeamModal.showExistingTeamError()
    },
    async addTeam (item) {
      const response = await this.$store.dispatch('team/addTeam', item)
      if (response.result) {
        this.closeCreateTeamModal()
        this.fetchTeams()
      } else {
        if (response.errors.find(error => error.type === 'EXISTING_TEAM')) {
          this.showExistingTeamError()
        }
      }
    },
    async deleteTeam (id) {
      const result = await this.$store.dispatch('team/deleteTeam', id)
      if (result.result) {
        this.fetchTeams()
      } else {
        console.log(result.errors)
      }
    },
    async refreshTeams (id) {
      this.openId = id
      this.fetchTeams()
    },
    async acceptInvite (teamID) {
      const result = await this.$store.dispatch('team/acceptInvite', { userID: this.user._id, teamID })
      console.log(result)
      if (result.result) {
        this.fetchTeams()
      } else {
        console.log(result.errors)
      }
    },
    async declineInvite (teamID) {
      const result = await this.$store.dispatch('team/declineInvite', { userID: this.user._id, teamID })
      if (result.result) {
        this.fetchTeams()
      } else {
        console.log(result.errors)
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
ul.dashed > li:before {
  content: "-";
}
</style>
