<template>
  <div>
    <Toast class="w-1/3 mt-8 fixed" type="success" v-model="showInviteSuccess" :autoClose="3">
      <template v-slot:title>Invite successful</template>
      <template v-slot:msg></template>
    </Toast>
    <Toast width="w-1/3 mt-8 fixed" type="danger" v-model="showInviteFailure" :autoClose="3">
      <template v-slot:title>Invite failed</template>
      <template v-slot:msg>The invite failed to send. This may be because no one has registered with this email.</template>
    </Toast>
    <div
      @click="show=!show"
      class="group flex flex-row border-2 border-th-text-secondary py-2 my-2 rounded-md items-center bg-th-secondary cursor-pointer"
    >
      <div class="flex flex-row text-th-text-secondary items-center flex-grow w-full">
        <div v-if="!show">
          <CaratIcon class="fill-current transform -rotate-90 h-10 w-10" />
        </div>
        <div v-else>
          <CaratIcon class="fill-current h-10 w-10" />
        </div>
        <div class="flex-grow w-full ml-2 text-lg uppercase font-bold text-center pr-16">{{team.name}}</div>
      </div>
    </div>

    <div v-if="show" class="my-4">
      <div class="flex flex-col xl:flex-row">
        <!-- ************************************ TEAM *******☺️**********************-->
        <div class="w-full text-center xl:text-left xl:mr-10">
          <div class="border-b-2 border-th-text mb-2 h-12 flex flex-row items-center justify-center space-x-6">
            <div
              class="text-center text-xl font-bold cursor-pointer h-full px-4 pt-2 rounded-t-lg border-th-text border-2 border-b-0"
              :class="{'bg-th-secondary text-th-text-secondary opacity-100': viewMembersTab, 'bg-th-primary opacity-80': !viewMembersTab}"
              @click="viewMembersTab=true"
            >Members</div>
            <div
              class="text-center text-xl font-bold cursor-pointer h-full px-4 pt-2 rounded-t-lg border-th-text border-2 border-b-0"
              :class="{'bg-th-secondary text-th-text-secondary opacity-100 ': !viewMembersTab, 'opacity-80': viewMembersTab}"
              @click="viewMembersTab=false"
            >Invites</div>
          </div>
          <div v-if="viewMembersTab">
            <div v-if="team.lead" class="flex text-base">
              <div class="pl-2 py-1 font-normal capitalize">{{ team.lead?.firstName }} {{ team.lead?.lastName }},</div>
              <span class="ml-2 py-1 font-bold">Lead</span>
            </div>
            <div class="flex flex-col justify-between">
              <div>
                <div v-for="member in team.members" :key="member._id">
                  <div
                    class="group flex flex-row items-center text-base font-normal py-1 rounded-sm justify-between"
                    :class="{'hover:bg-th-secondary hover:bg-opacity-25': userIsTeamLead() && userTeamManagementSettings.usersCanCreateTeams }"
                  >
                    <div class="pl-2 capitalize">{{ member.firstName }} {{ member.lastName }}</div>
                    <div v-if="userIsTeamLead() && userTeamManagementSettings.usersCanCreateTeams">
                      <DeleteIcon
                        class="h-8 w-8 px-2 py-1 fill-current opacity-0 group-hover:opacity-100 cursor-pointer"
                        @click.stop="deleteUser(member._id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Invites tab -->
          <div v-else class="relative">
            <div class="flex flex-col justify-between relative">
              <div v-if="team.invites.length > 0">
                <div v-for="invitee in team.invites" :key="invitee._id">
                  <div
                    class="group flex flex-row items-center text-base py-1 rounded-sm justify-between"
                    :class="{'hover:bg-white hover:bg-opacity-25': userIsTeamLead() && userTeamManagementSettings.usersCanCreateTeams }"
                  >
                    <div class="pl-2">{{ invitee.email }}</div>
                    <div v-if="userIsTeamLead() && userTeamManagementSettings.usersCanCreateTeams">
                      <DeleteIcon
                        class="h-8 w-8 px-2 py-1 fill-current opacity-0 group-hover:opacity-100 cursor-pointer"
                        @click.stop="deleteInvite(invitee._id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="italic pl-2 py-1">No outgoing invites.</div>
              <div class="flex flex-col sm:flex-row items-start sm:items-center pr-1 relative" v-if="userIsTeamLead() && userTeamManagementSettings.usersCanCreateTeams">
                <multiselect
                  v-if="userListAvailable"
                  class="w-full h-10 border-th-text-secondary bg-th-secondary text-th-text-secondary rounded flex-grow mr-2 my-0 xl:my-1 placeholder-current leading-tight focus:outline-none focus:shadow-outline"
                  v-model="user"
                  :options="userOptions"
                  :multiple="false"
                  :custom-label="customLabel"
                  :close-on-select="true"
                  :clear-on-select="false"
                  >
                </multiselect>
                <input
                  v-else
                  class="w-full h-10 border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary rounded flex-grow mr-2 px-3 my-0 xl:my-1 placeholder-current leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  v-model="userEmail"
                  placeholder="example@email.com"
                />
                <button
                  class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary text-sm rounded capitalize flex h-10 w-32 my-1 justify-center items-center"
                  :class="{ 'opacity-60 cursor-default': inviteDisabled, 'cursor-pointer': !inviteDisabled }"
                  @click.stop="inviteUser"
                  :disabled="inviteDisabled"
                >
                  <span>
                    <TeamIcon class="fill-current mr-1 w-5 h-5" />
                  </span>
                  <span>Invite</span>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div class="invisible xl:visible flex flex-row">
              <button v-if="userIsTeamLead()"
                class="bg-th-secondary text-th-text-secondary border-th-text-secondary border-2 pl-2 py-2 my-2 text-sm text-center rounded-md cursor-pointer capitalize flex flex-row mr-2"
                @click.stop="showUpdateTeamModal"
              >
                <span>
                  <EditIcon class="justify-end fill-current w-5 h-5 mr-2" />
                </span>
                <span class="mr-3 font-bold">Edit Team</span>
              </button>
              <button v-if="userIsTeamLead() && userTeamManagementSettings.usersCanDeleteTeams"
                class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary pl-2 py-2 my-2 text-sm text-center rounded-md cursor-pointer capitalize flex flex-row mr-2"
                @click.stop="showDeleteTeamModal"
              >
                <span>
                  <DeleteIcon class="justify-end fill-current w-5 h-5 mr-2" />
                </span>
                <span class="mr-3 font-bold">Delete Team</span>
              </button>
              <button v-if="userCanLeaveTeam()"
                class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary pl-2 py-2 my-2 text-sm text-center rounded-md cursor-pointer capitalize flex flex-row mr-2"
                @click="showLeaveTeamModal"
              >
                <span>
                  <DeleteIcon class="justify-end fill-current w-5 h-5 mr-2" />
                </span>
                <span class="mr-3">Leave Team</span>
              </button>
            </div>
          </div>
        </div>
        <!-- **************************** Collections *****************************-->
        <div class="w-full xl:ml-10">
          <div class="flex flex-col">
            <div class="border-b-2 border-th-text flex h-12 justify-center items-center mb-2">
              <div class="pr-4 text-xl font-bold">Collections</div>
              <button
                v-if="userIsTeamLead()"
                class="bg-th-secondary border-th-text-secondary border-2 text-th-text-secondary pl-2 pr-3 h-10 mb-2 text-sm font-bold text-center rounded-md cursor-pointer uppercase"
                @click="showCreateCollectionModal()"
              >+ New Collection</button>
              <createCollectionModal ref="createCollectionModal" @submit="createCollection"></createCollectionModal>
            </div>
            <!-- <div v-for="group in sstTracks" :key="group">
            <CollectionTableEntry :collectionName="group" :collection="journeyMaps[group.toString().toLowerCase()]" />-->
            <div v-for="collection in this.team.lists" :key="collection._id">
              <CollectionTableEntry
                :collection="collection"
                :teamId="team._id"
                :admin="userIsTeamLead()"
                @delete="deleteCollection"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <deleteTeamModal ref="deleteTeamModal" @delete="deleteTeam" @leave="deleteUser(userId)" :leave="leave"></deleteTeamModal>
    <updateTeamModal
      :item="this.team"
      ref="updateTeamModal"
      @submit="updateTeam"
    ></updateTeamModal>
  </div>
</template>

<script>
import DeleteTeamModal from '@/components/profile/DeleteTeamModal.vue'
import DeleteIcon from '@/assets/delete.svg'
import EditIcon from '@/assets/edit.svg'
import TeamIcon from '@/assets/team-icon.svg'
import CaratIcon from '@/assets/carat.svg'
import CollectionTableEntry from '@/components/profile/CollectionTableEntry.vue'
import CreateCollectionModal from '@/components/profile/CreateCollectionModal.vue'
import UpdateTeamModal from '@/components/profile/UpdateTeamModal.vue'
import Multiselect from 'vue-multiselect'
import Toast from '@/components/utilities/Toast'

export default {
  components: {
    DeleteIcon,
    EditIcon,
    CaratIcon,
    DeleteTeamModal,
    CreateCollectionModal,
    TeamIcon,
    CollectionTableEntry,
    UpdateTeamModal,
    Multiselect,
    Toast,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      required: false,
    },
    openId: {
      type: String,
      required: false,
    },
    userTeamManagementSettings: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      visible: false,
      item: { email: undefined },
      categoryId: undefined,
      collectionDescription: undefined,
      result: undefined,
      submitted: false,
      menuOpen: false,
      show: false,
      userEmail: undefined,
      leave: undefined,
      viewMembersTab: true,
      user: null,
      showInviteSuccess: false,
      showInviteFailure: false,
    }
  },
  created () {
    if (this.openId === this.team._id) {
      this.show = true
    }
  },
  computed: {
    users () {
      return this.$store.getters['team/users']
    },
    userListAvailable () { // if the user list is empty, the user directory is disabled and multiselect cannot be used
      return this.users.length > 0
    },
    uninvitedUsers () {
      return this.users.filter(user => !(this.team.members.some(mem => mem.email === user.email) || this.team.invites.some(invite => invite.email === user.email) || user.email === this.team.lead.email))
    },
    userOptions () {
      return this.uninvitedUsers.map(obj => ({ email: obj.email, firstName: obj.firstName, lastName: obj.lastName }))
    },
    inviteDisabled () {
      return !this.user && !this.userEmail
    },
  },
  methods: {
    userIsTeamLead () {
      return this.userId === this.team.lead?._id
    },
    userCanLeaveTeam () {
      return this.userTeamManagementSettings.usersCanLeaveTeams && !this.userIsTeamLead()
    },
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    },
    deleteTeam () {
      this.$emit('delete', this.team._id)
    },
    showDeleteTeamModal () {
      this.leave = false
      this.$refs.deleteTeamModal.open(this.team.name)
      this.menuOpen = false
    },
    showLeaveTeamModal () {
      this.leave = true
      this.$refs.deleteTeamModal.open(this.team.name)
      this.menuOpen = false
    },
    showCreateCollectionModal () {
      this.$refs.createCollectionModal.open()
    },
    showUpdateTeamModal () {
      this.$refs.updateTeamModal.open()
    },
    closeUpdateTeamModal () {
      this.$refs.updateTeamModal.close()
    },
    closeCreateCollectionModal () {
      this.$refs.createCollectionModal.close()
    },
    showExistingTeamError () {
      this.$refs.updateTeamModal.showExistingTeamError()
    },
    async createCollection (item) {
      const result = await this.$store.dispatch('team/createCollection', { collection: item, id: this.team._id })
      this.closeCreateCollectionModal()
      this.$emit('refresh', this.team._id)
      if (result.result) {
        this.$store.dispatch('team/fetchTeams')
      } else {
        console.log(result.errors)
      }
    },
    async deleteCollection (id) {
      const result = await this.$store.dispatch('team/deleteCollection', { collectionID: id, teamID: this.team._id })
      this.$emit('refresh', this.team._id)
      if (result.result) {
        this.$store.dispatch('team/fetchTeams')
      } else {
        console.log(result.errors)
      }
    },
    async inviteUser () {
      if (this.userListAvailable) {
        this.item = { email: this.user.email.toLowerCase() }
      } else {
        this.item = { email: this.userEmail.toLowerCase() }
      }
      const res = await this.$store.dispatch('team/inviteUser', { userEmail: this.item, teamID: this.team._id })
      console.log('Response object: ' + res.result)
      if (res.result === false) { this.showInviteFailure = true } else { this.showInviteSuccess = true }
      this.$emit('refresh', this.team._id)
      this.userEmail = ''
    },
    async deleteInvite (id) {
      const result = await this.$store.dispatch('team/deleteInvite', { userID: id, teamID: this.team._id })
      this.$emit('refresh', this.team._id)
      this.userEmail = ''
      if (result.result) {
        this.$store.dispatch('team/fetchTeams')
      } else {
        console.log(result.errors)
      }
    },
    async deleteUser (id) {
      const result = await this.$store.dispatch('team/deleteUser', { userID: id, teamID: this.team._id })
      this.$emit('refresh', this.team._id)
      this.userEmail = ''
      if (result.result) {
        this.$store.dispatch('team/fetchTeams')
      } else {
        console.log(result.errors)
      }
    },
    async updateTeam (item) {
      const response = await this.$store.dispatch('team/updateTeam', { team: item, teamID: this.team._id })
      if (response.result) {
        this.closeUpdateTeamModal()
        this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams)
      } else {
        if (response.errors.find(error => error.type === 'EXISTING_TEAM')) {
          this.showExistingTeamError()
        }
      }
    },
    customLabel (user) {
      return `${user.firstName} ${user.lastName} (${user.email})`
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- class="w-full h-10 border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary rounded flex-grow mr-2 px-3 my-0 xl:my-1 placeholder-current leading-tight focus:outline-none focus:shadow-outline" -->

<style>
  .multiselect__tags {
    @apply border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary rounded;
  }
  .multiselect__input, .multiselect__input:focus, .multiselect__single, .multiselect__placeholder {
    @apply bg-th-secondary text-th-text-secondary
  }
  .multiselect__content-wrapper {
    @apply bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary
  }
  .multiselect__select:before {
    /* @apply text-th-text-secondary border-t-th-text-secondary */
    border-top-color: var(--text-secondary)
  }
</style>
