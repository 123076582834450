<template>
  <div>
    <div v-if="showPopupReminder" class="absolute z-50 inset-0 bg-black bg-opacity-60 flex justify-center items-center">
      <div
        class="w-full mx-4 md:w-1/2 md:m-0 bg-white rounded-2xl flex flex-col justify-center items-center p-8 space-y-3">
        <div class="text-gray-900 font-bold text-center text-3xl">ATTENTION:</div>
        <div class="text-gray-900 text-xl text-center">Some content provided may be blocked by popup blockers. Please
          disable popup blockers for this site to ensure access to content provided. Thank you!</div>
        <div class="w-full rounded-lg bg-gray-700 text-white text-center font-semibold py-2 cursor-pointer"
          @click="popupMsgAck">OK</div>
      </div>
    </div>
    <LoginModal ref="loginModal" />
    <addToCollectionModal v-if="$store.getters['auth/user']" ref="addToCollectionModal" itemType="Lesson">
    </addToCollectionModal>
    <updateCompletionModal ref="completionModal" type="lesson" :item="selectedLesson" @addCompletion="addCompletion"
      @removeCompletion="removeCompletion"></updateCompletionModal>
    <lessonCommentModal ref="commentModal" :item="selectedLesson" @addComment="addComment" @removeComment="removeComment">
    </lessonCommentModal>
    <div class="flex flex-col items-start">
      <div class="flex flex-row items-center">
        <LearnIcon class="w-10 h-10 fill-current mr-4" />
        <div class="text-4xl font-bold">Learn</div>
      </div>
      <div class="text-lg mb-3">{{ this.$parent.siteMetadata !== undefined ? this.$parent.siteMetadata.learnDescription :
        '' }}</div>

      <div
        class="flex items-center rounded-full px-2 bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary w-full md:w-3/4 lg:w-1/2 xl:w-1/4">
        <SearchIcon class="w-6 h-6 mr-2 fill-current" />
        <input v-model="searchInput" class="h-10 flex-1 bg-transparent focus:outline-none placeholder-current" type="text"
          placeholder="Search Learn content" />
        <CloseIcon v-if="searchInput.length" class="w-6 h-6 ml-4 cursor-pointer fill-current" @click="searchInput = ''" />
      </div>

      <div class="flex items-center">
        <md-switch v-model="isSyllabusView">Syllabus View</md-switch>
        <div v-if="isSyllabusView" @click="exportAsCsv" class="flex items-center bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded cursor-pointer p-1 pr-2">
          <DownloadFileIcon  class="mr-1 h-5 w-5" />
          Download
        </div>
      </div>

      <div v-if="isLoading" class="mt-8 text-xl text-center w-full">Loading Content...</div>
      <div v-else-if="isSyllabusView" class="w-full">
        <div v-if="searchLoading">Loading...</div>
        <div v-else-if="searchInput.length > 0 && filteredCourses.length === 0 && filteredLessons.length === 0">No results
        </div>
        <div v-else v-for="track in tracks" :key="track._id" class="w-full">
          <div v-if="(searchInput.length === 0 || trackHasFilteredCoursesOrLessons(track)) && track.isActive" class="pb-8 font-normal">
            <div class="border-b border-th-text pb-2 mb-4">
              <div class="flex items-end gap-4 mt-4">
                <div class="flex items-start gap-2">
                  <MountainFlagIcon class="w-8 h-8 fill-current" />
                  <div class="text-3xl font-medium">{{ track.title }}</div>
                </div>
                <div class="text-lg">{{ track.courses.length }} Courses</div>
              </div>
              <!-- <div class="font-normal text-base">{{ track.description }}</div> -->
            </div>
            <div v-for="course in track.courses" :key="course._id" class="bg-th-secondary border-2 border-th-text-secondary rounded shadow-lg m-4 p-4">
              <div
                v-if="searchInput.length === 0 || (searchInput.length > 0 && courseHasFilteredLessonsOrIsFiltered(course))"
                class="">
                <div class="flex items-end gap-3 pb-3">
                  <div class="flex items-start gap-2">
                  <md-icon class="md-primary" md-font-icon="material-icons">outlined_flag</md-icon>
                    <h4
                      @click="$router.push({ name: 'learn-course', params: { _id: course._id }, query: { parent: track._id } })"
                      class="cursor-pointer text-xl underline font-medium">{{ course.title }}</h4>
                    </div>
                  <div class="text-base">{{ course.lessons.length }} Lessons</div>
                </div>
                <div v-for="lessonId in course.lessons.filter(lessonId => {
                  const isLessonExist = !!allLessons.find(lesson => lesson._id === lessonId)
                  if (!isLessonExist) return false
                  if (searchInput.length > 0) return filteredLessonIds.includes(lessonId)
                  else return isLessonExist
                })  " :key="lessonId"
                class="flex items-center"
                >
                  <div class="w-1 h-1 rounded-full bg-th-text-secondary ml-1" />
                  <div class="flex space-x-2 items-center">
                    <div v-if="lessonForId(lessonId)?.contentType === 'link'">
                      <a :href="getProcessedLink(lessonForId(lessonId)?.link)" @click="logLessonHit()" target="_blank">
                        <h5 class="text-base py-1 sm:ml-3 cursor-pointer hover:underline flex-shrink">{{
                          lessonForId(lessonId)?.title }}</h5>
                      </a>
                    </div>
                    <div v-else-if="lessonForId(lessonId)?.contentType === 'document'">
                      <h5 @click="viewContent(lessonId)"
                        class="text-base py-1 sm:ml-3 cursor-pointer hover:underline flex-shrink">{{
                          lessonForId(lessonId)?.title }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="searchInput === '' && !searchLoading" class="w-full">
        <div class="flex flex-col space-y-10 mt-4">
          <div v-for="track in tracks.filter(track => track.isActive)" :key="track._id" :id="track._id">
            <div v-if="track.isActive">
              <div class="border-b border-th-text pb-2 mb-4">
                <div class="flex items-start gap-2 pb-2">
                  <MountainFlagIcon class="w-8 h-8 fill-current" />
                  <div class="text-3xl font-medium">{{ track.title }}</div>
                </div>
                <div class="font-normal text-base">{{ track.description }}</div>
              </div>
              <CarouselWrapper v-if="track.flattened">
                <div v-if="!lessonsFromFlatTrack(track).length">
                  <div class="text-lg font-bold items-center">
                    No lessons from this track, yet!
                  </div>
                </div>
                <slide class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4" v-for="lesson in lessonsFromFlatTrack(track)"
                  :key="lesson._id">
                  <LessonCard :ref="lesson._id" :query="searchInput" :item="lesson" itemType="lesson" :shareButton="true"
                    @share="showAddToCollectionModal" @showLoginModal="showLoginModal" @showCommentModal="showCommentModal"
                    @showCompleteModal="showCompleteModal" @getLessons="getLessons" />
                </slide>
              </CarouselWrapper>
              <CarouselWrapper v-else>
                <div v-if="!track.courses.length">
                  <div class="text-lg font-bold items-center">
                    No courses from this track, yet!
                  </div>
                </div>
                <slide class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4" v-for="course in track.courses" :key="course._id">
                  <LearnModuleCard :item="course" @tagClick="(tag) => searchInput = tag"
                    @click="$router.push({ name: 'learn-course', params: { _id: $event }, query: { parent: track._id } })" />
                </slide>
              </CarouselWrapper>
            </div>
          </div>
        </div>

      </div>
      <div v-else class="w-full pt-8">
        <div class="border-b border-th-text py-2 mb-4">
          <div class="text-2xl font-bold">Search All Courses</div>
          <div class="font-normal">{{ searchLoading ? 'Loading...' : filteredCourses.length + ' Matches' }}</div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 justify-items-center px-4">
          <LearnModuleCard v-for="course in filteredCourses" :key="course._id" :item="course" :query="searchInput"
            @tagClick="(tag) => searchInput = tag"
            @click="$router.push({ name: 'learn-course', params: { _id: $event }, query: { parent: course.tracks[0] } })" />
        </div>
        <div class="border-b border-th-text pb-2 mb-4">
          <div class="text-2xl font-bold">Search All Lessons</div>
          <div class="font-normal">{{ searchLoading ? 'Loading...' : filteredLessons.length + ' Matches' }}</div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 justify-items-center px-12">
          <div class="w-full" v-for="lesson in filteredLessons" :key="lesson._id">
            <div>
              <LessonCard :ref="lesson._id" :query="searchInput" :item="lesson" itemType="lesson" :shareButton="true"
                @share="showAddToCollectionModal" @showLoginModal="showLoginModal" @showCommentModal="showCommentModal"
                @showCompleteModal="showCompleteModal" @getLessons="getLessons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import Axios from 'axios'
import debounce from 'lodash.debounce'
import CloseIcon from '@/assets/close-x.svg'
import DownloadFileIcon from '@/assets/download-file.svg'
import LearnIcon from '@/assets/learn.svg'
import SearchIcon from '@/assets/search.svg'
import LearnModuleCard from '@/components/learn/ModuleCard.vue'
import LessonCard from '@/components/learn/LessonCard.vue'
import AddToCollectionModal from '@/components/profile/AddToCollectionModal.vue'
import LessonCommentModal from '@/components/learn/LessonCommentModal'
import LoginModal from '@/components/utilities/LoginModal'
import UpdateCompletionModal from '@/components/learn/UpdateCompletionModal'
import EventService from 'event-service'
import MountainFlagIcon from '@/assets/mountain-flag-icon.svg'

import CarouselWrapper from '@/components/utilities/CarouselWrapper.vue'
import { Slide } from 'vue-carousel'

export default {
  components: {
    MountainFlagIcon,
    LearnIcon,
    LearnModuleCard,
    CarouselWrapper,
    Slide,
    LessonCard,
    AddToCollectionModal,
    LessonCommentModal,
    LoginModal,
    UpdateCompletionModal,
    CloseIcon,
    SearchIcon,
    DownloadFileIcon,
  },
  computed: {
    tracks () {
      return this.$store.getters['learn/tracks']
    },
    allCourses () {
      return this.$store.getters['learn/courses']
    },
    allLessons () {
      return this.$store.getters['learn/lessons']
    },
    currentUser () {
      return this.$store.getters['auth/user']
    },
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    showPopupReminder () {
      return this.$store.getters['metadata/showPopupReminder']
    },
    filteredLessonIds () {
      return this.filteredLessons.map(lesson => lesson._id)
    },
    filteredCourseIds () {
      return this.filteredCourses.map(course => course._id)
    },
  },
  async mounted () {
    if (this.tracks.length === 0) this.isLoading = true
    await this.$store.dispatch('learn/fetchLearnData')
    this.isLoading = false

    if (this.$route.hash) {
      await nextTick()
      window.scrollTo({
        top: document.getElementById(this.$route.hash.substring(1)).offsetTop - 48,
        behavior: 'smooth',
      })
    }
  },
  data () {
    return {
      isLoading: false,
      searchInput: '',
      searchLoading: false,
      filteredCourses: [],
      filteredLessons: [],
      debouncedQueryWatch: undefined,
      selectedLesson: undefined,
      isSyllabusView: false,
    }
  },
  created () {
    this.debouncedQueryWatch = debounce(async query => this.search(query), 500)
    this.searchInput = this.$route.params.searchTag || ''
  },
  beforeDestroy () {
    this.debouncedQueryWatch.cancel()
  },
  watch: {
    searchInput: function (newVal) {
      this.searchLoading = true
      this.filteredCourses = []
      this.filteredLessons = []
      this.debouncedQueryWatch(newVal)
    },
    allLessons: function (newVal) {
      if (this.selectedLesson) {
        this.selectedLesson = newVal.find(lesson => lesson._id === this.selectedLesson._id)
      }
    },
  },
  methods: {
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    lessonForId (id) {
      return this.allLessons.find(lesson => lesson._id === id)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    showAddToCollectionModal (item) {
      this.$refs.addToCollectionModal.open(item)
    },
    closeAddToCollectionModal () {
      this.$refs.addToCollectionModal.close()
    },
    showCommentModal (item) {
      this.selectedLesson = item
      this.$refs.commentModal.open()
    },
    closeCommentModal () {
      this.$refs.commentModal.close()
    },
    showCompleteModal (item) {
      this.selectedLesson = item
      this.$refs.completionModal.open()
    },
    closeCompleteModal () {
      this.$refs.completionModal.close()
    },
    addComment (commentText) {
      this.$refs[this.selectedLesson._id][0].addComment(commentText)
    },
    removeComment (comment) {
      this.$refs[this.selectedLesson._id][0].removeComment(comment)
    },
    addCompletion (completion) {
      this.$refs[this.selectedLesson._id][0].addCompletion(completion)
    },
    removeCompletion () {
      this.$refs[this.selectedLesson._id][0].removeCompletion()
    },
    lessonsFromFlatTrack (track) {
      const displayLessons = []
      const courses = track.courses
      for (let i = 0; i < courses.length; i++) {
        for (let j = 0; j < courses[i].lessons.length; j++) {
          const lessonById = this.allLessons.find(allLesson => allLesson._id === courses[i].lessons[j])
          if (lessonById) displayLessons.push(lessonById)
        }
      }
      return displayLessons
    },
    search (query) {
      const searchString = query.toLowerCase().trim()

      this.filteredCourses = this.allCourses.filter((course) => {
        return (
          course.tracks.length > 0 && (
            course.title.toLowerCase().includes(searchString) ||
            course.description.toLowerCase().includes(searchString) ||
            course.tags.find(item => item.toLowerCase().includes(searchString))
          )
        )
      })
      this.filteredLessons = this.allLessons.filter((lesson) => {
        return (
          lesson.title.toLowerCase().includes(searchString) ||
          lesson.description.toLowerCase().includes(searchString) ||
          lesson.commentary.toLowerCase().includes(searchString)
        )
      })

      this.searchLoading = false
    },
    popupMsgAck () {
      this.$store.dispatch('metadata/updatePopupReminder', true)
    },
    getLessons () {
      this.$store.dispatch('learn/fetchLearnData')
    },
    async logLessonHit (lessonId) {
      await EventService.createEvent({
        eventName: 'lessonClick',
        payload: { lessonId },
      })
    },
    courseHasFilteredLessonsOrIsFiltered (course) {
      return this.filteredCourseIds.includes(course._id) || course.lessons.some(lessonId => this.filteredLessonIds.includes(lessonId))
    },
    trackHasFilteredCoursesOrLessons (track) {
      return track.courses.some(course => this.courseHasFilteredLessonsOrIsFiltered(course))
    },
    getProcessedLink (link) {
      let webAddress = link
      if (webAddress.includes('learn/lecture')) {
        webAddress = webAddress.replace(
          webAddress.substring(
            webAddress.indexOf('learn/lecture'),
            webAddress.indexOf('?'),
          ),
          '',
        )
      }
      if (!webAddress.match('^[a-zA-Z-]+:')) {
        webAddress = 'http://' + webAddress
      }
      if ((!this.currentUser || !this.currentUser.email.includes('mitre.org')) && webAddress.includes('mitre.udemy')) {
        return webAddress.replace('mitre.udemy', 'digitalu.udemy')
      }
      if (this.currentUser?.email?.includes('mitre.org') && webAddress.includes('udemy')) {
        return webAddress.replace('udemy', 'mitre.udemy')
      }
      return webAddress
    },
    async viewContent (lessonId) {
      await EventService.createEvent({
        eventName: 'lessonClick',
        payload: { lessonId },
      })
      const response = await Axios.get(this.lessonForId(lessonId)?.link, { responseType: 'blob' })
      var fileURL = window.URL.createObjectURL(response.data)
      const tab = window.open()
      tab.location.href = fileURL
    },
    exportAsCsv () {
      let data = ''
      for (const track of this.tracks) {
        if (this.searchInput.length === 0 || this.trackHasFilteredCoursesOrLessons(track)) {
          data += `"${track.title.replace(/"/g, '""')}"\n`
          for (const course of track.courses) {
            if (this.searchInput.length === 0 || this.courseHasFilteredLessonsOrIsFiltered(course)) {
              data += `,"${course.title.replace(/"/g, '""')}"\n`
              for (const lessonId of course.lessons.filter(lessonId => {
                const isLessonExist = !!this.allLessons.find(lesson => lesson._id === lessonId)
                if (!isLessonExist) return false
                if (this.searchInput.length > 0) return this.filteredLessonIds.includes(lessonId)
                else return isLessonExist
              })) {
                const lesson = this.lessonForId(lessonId)
                data += `,,"${lesson.title.replace(/"/g, '""')}"\n`
              }
            }
          }
        }
      }
      const blob = new Blob([data], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'text'
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
}
</script>

<style lang="postcss" scoped>
.vs-carousel__slide {
  flex: 0 0 auto;
}
</style>
