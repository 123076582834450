import Axios from 'axios'
import store from '@/store'

export default {
  async login (email, password) {
    const loginData = {
      email, password,
    }

    try {
      const response = await Axios.post('/users/login', loginData)
      const responseData = response.data
      // Check for errors
      if (responseData.errors.length > 0) {
        return { success: false, errors: responseData.errors }
      }
      // set stuff in the store
      store.commit('auth/saveUser', responseData.data.user)
      store.commit('auth/saveToken', responseData.data.token)
      return { success: true, errors: [] }
    } catch (err) {
      if (err.response.data) {
        return { success: false, errors: err.response.data.errors }
      }
      return { success: false, errors: [] }
    }
  },
  async register (userData) {
    try {
      const response = await Axios.post('/users/register', userData)
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return { success: false, errors: responseData.errors }
      }

      return { success: true, errors: [] }
    } catch (err) {
      if (err.response.data) {
        return { success: false, errors: err.response.data.errors }
      }
      return { success: false, errors: [] }
    }
  },
  async resendVerification (email) {
    try {
      const response = await Axios.post('/users/resend-verification', { email })
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }

      return true
    } catch (err) {
      return false
    }
  },
  async forgotPassword (email) {
    try {
      const response = await Axios.post('/users/forgot-password', { email })
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }

      return true
    } catch (err) {
      return false
    }
  },
  async resetPassword (ident, today, token, email, password) {
    try {
      const response = await Axios.put(`/users/${ident}/reset-password/${today}-${token}`, { email, password })
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      return true
    } catch (err) {
      return false
    }
  },
  async verifyAccount (ident, today, token, email, password) {
    try {
      const response = await Axios.put(`/users/${ident}/verify-email/${today}-${token}`, { email, password })
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return false
      }
      return true
    } catch (err) {
      return false
    }
  },
  async validateInvitation (ident) {
    try {
      const response = await Axios.get(`/users/verify-invitation/${ident}`)
      const responseData = response.data

      if (responseData.errors.length > 0) {
        return false
      }

      return responseData
    } catch (err) {
      return false
    }
  },
  async inviteRegistration (userData) {
    try {
      const response = await Axios.post(`/users/${userData.ident}/invite-registration/${userData.token}`, userData)
      const responseData = response.data

      // Check for errors
      if (responseData.errors.length > 0) {
        return { success: false, errors: responseData.errors }
      }

      if (responseData.data.user == null) {
        return { success: false, errors: ['Error adding user via invitation'] }
      }

      if (!responseData.data.deletionSuccess) {
        return { success: false, errors: ['Error deleting invitee'] }
      }

      return { success: true, errors: [] }
    } catch (err) {
      if (err.response.data) {
        return { success: false, errors: err.response.data.errors }
      }
      return { success: false, errors: [] }
    }
  },
}
