<template>
  <div :class="`border-th-text-secondary bg-th-secondary text-th-text-secondary border-2 rounded px-4 py-2 flex items-center ${scoreboardView && 'h-16'} justify-between space-x-4`">
    <div v-if="scoreboardView">
      <div class="text-xl">{{ item.name }}</div>
    </div>
    <div>
      <div v-if="!scoreboardView" class="pb-2">Total Score</div>
      <div><span class="text-3xl pr-1">{{ item.overallScore }}</span>Points</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    scoreboardView: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Boolean,
      default: false,
    },
  },
  components: {

  },
}
</script>

<style>
</style>
