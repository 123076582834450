<template>
  <div>
    <div
      @click="$router.push({name:'teams-collection', params:{teamId: teamId , collectionId: collection._id}})"
      class="group flex flex-row py-2 my-2 rounded-md hover:bg-gray-100 hover:bg-opacity-25 cursor-pointer"
    >
      <img
        class="h-20 w-32 self-center sm:self-start sm:h-28 sm:w-40 inline-block sm:ml-2 mr-2 rounded-md"
        :src="picture"
      />
      <div class="text-lg text-left flex flex-col flex-grow truncate">
        <span class="uppercase font-bold truncate">{{ collection.name }}</span>
        <div class="text-sm font-normal capitalize flex flex-col">
          <div>{{ lessonTotal }} Lessons</div>
          <div>{{ practiceTotal }} Practice modules</div>
          <button
            v-if="admin"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary py-2 my-1 text-sm font-bold rounded-md cursor-pointer capitalize flex self-start sm:self-end"
            @click.stop="showDeleteCollectionModal"
          >
            <span>
              <DeleteIcon class="hidden sm:block justify-end fill-current w-5 h-5 pl-2" />
            </span>
            <span class="px-2">Delete Collection</span>
          </button>
        </div>
      </div>
    </div>
    <deleteCollectionModal ref="deleteCollectionModal" @delete="deleteCollection"></deleteCollectionModal>
  </div>
</template>

<script>
import DeleteCollectionModal from '@/components/profile/DeleteCollectionModal.vue'
import DeleteIcon from '@/assets/delete.svg'

export default {
  components: {
    DeleteCollectionModal,
    DeleteIcon,
  },
  props: {
    collection: {
      type: Object,
      required: false,
    },
    teamId: {
      type: String,
      required: false,
    },
    admin: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    picture () {
      let pic = require('@/assets/practice/practice_placeholder.jpg')
      try {
        pic = require(`@/assets/lessons/${this.collection.items[0].item._id}.jpg`)
      } catch (err) {
        try {
          pic = require(`@/assets/practice/${this.collection.items[0].item._id}.jpg`)
        } catch (err) { }
      }
      return pic
    },
  },
  data () {
    return {
      visible: false,
      item: undefined,
      categoryId: undefined,
      collectionDescription: undefined,
      result: undefined,
      submitted: false,
      menuOpen: false,
      lessonTotal: 0,
      practiceTotal: 0,
    }
  },
  created () {
    this.collection.items.forEach(item => {
      if (item.itemType === 'Lesson') {
        this.lessonTotal++
      } else if (item.itemType === 'PracticeModule') {
        this.practiceTotal++
      }
    })
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    },
    showDeleteCollectionModal () {
      this.$refs.deleteCollectionModal.open(this.collection.name)
    },
    deleteCollection () {
      this.$emit('delete', this.collection._id)
    },
  },
}
</script>

<style>
</style>
