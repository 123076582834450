// Vue Stuff
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Utility Components/Views
import Wrapper from '@/views/Wrapper.vue'
import Main from '@/views/Main.vue'
import NotFound from '@/views/utilities/NotFound.vue'

// Learn Components/Views
import Learn from '@/views/learn/Learn.vue'
import Course from '@/views/learn/Course.vue'

// Practice Components/Views
import Practice from '@/views/practice/Practice.vue'
import PracticeTarget from '@/views/practice/Target.vue'

// Compete Components/Views
import Compete from '@/views/compete/Compete.vue'
import ChallengeBoard from '@/views/compete/ChallengeBoard.vue'
import Tournament from '@/views/compete/Tournament.vue'

// Scoreboard Components/Views
import Scoreboard from '@/views/scoreboard/Scoreboard.vue'

// Profile Components/Views
import Profile from '@/views/profile/Profile.vue'
import Teams from '@/views/profile/Teams.vue'
import Collection from '@/views/profile/Collection.vue'

import Badges from '@/views/badges/Badges.vue'
import BadgeDetails from '@/views/badges/BadgeDetails.vue'

// Assessment Components/Views
import Assessments from '@/views/compete/assessments/Assessments.vue'
import AssessmentDetails from '@/views/compete/assessments/AssessmentDetails.vue'

// Login Components/Views
import Login from '@/views/login/Login.vue'
import ForgotPassword from '@/views/login/ForgotPassword.vue'
import Register from '@/views/login/Register.vue'
import InviteRegistration from '@/views/login/InviteRegistration.vue'
import ResetPassword from '@/views/login/ResetPassword.vue'
import VerifyEmail from '@/views/login/VerifyEmail.vue'

// Oauth Login
import OAuthAuthorize from '@/views/oauth/Authorize.vue'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import LoginCallback from '@/views/LoginCallback.vue'

// Placeholder Components/Views
// import Placeholder from '@/components/Placeholder.vue'

const isAuthenticated = async () => {
  if (USE_SSO) {
    return await oktaAuth.isAuthenticated() && store.getters['auth/user']
  }
  return store.getters['auth/isAuthenticated']
}

// Simple component so it doesn't need its own file.
const Logout = Vue.component('Logout', {
  beforeRouteEnter: async (to, from, next) => {
    await store.dispatch('auth/logout')
    next({ name: 'main' })
  },
})

Vue.use(VueRouter)

var oktaAuth
if (USE_SSO) {
  oktaAuth = new OktaAuth({
    issuer: OKTA_ISSUER,
    clientId: OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    tokenManager: {
      storageKey: 'okta-dca-token',
    },
  })
  Vue.use(OktaVue, { oktaAuth })
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (USE_SSO && !(await isAuthenticated())) {
        oktaAuth.signInWithRedirect()
        return
      }

      if (!store.getters['metadata/siteMetadata']) {
        await store.dispatch('metadata/fetchSiteMetadata')
      }
      const { paywallEnabled } = store.getters['metadata/siteMetadata']
      // if from.name === 'profile-main', user must have logged out from profile page
      if (!paywallEnabled && from.name === 'profile-main') {
        next({ name: 'main' })
        return
      }

      if ((await isAuthenticated()) && from.name !== 'logout') {
        next({ name: 'main' })
        return
      }
      next()
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
  ...(USE_SSO
    ? [
      {
        path: '/login/callback',
        name: 'login-callback',
        component: LoginCallback,
      },
    ]
    : [
      {
        path: '/register',
        name: 'register',
        component: Register,
      },
      {
        path: '/oauth-login',
        name: 'oauth-login',
        component: Login,
        props: route => ({
          redirect: 'oauth-authorize',
          oauthData: JSON.parse(atob(route.query.oauthData)),
        }),
        beforeEnter: (to, from, next) => {
          const authenticated = store.getters['auth/isAuthenticated']
          if (authenticated) {
            next({
              name: 'oauth-authorize',
              params: { oauthData: JSON.parse(atob(to.query.oauthData)) },
            })
            return
          }
          next()
        },
      },
      {
        path: '/oauth-authorize',
        name: 'oauth-authorize',
        component: OAuthAuthorize,
        props: true,
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
      },
      {
        path: '/:ident/reset-password/:today-:token',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
      },
      {
        path: '/:ident/verify-email/:today-:token',
        name: 'verify-email',
        component: VerifyEmail,
        props: true,
      },
      {
        path: '/:ident/invite-registration/:token',
        name: 'invite-registration',
        component: InviteRegistration,
        props: true,
      },
    ]
  ),
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'main',
        component: Main,
      },
      {
        path: 'learn',
        name: 'learn-main',
        component: Learn,
        beforeEnter: async (to, from, next) => {
          if (!store.getters['metadata/siteMetadata']) {
            await store.dispatch('metadata/fetchSiteMetadata')
          }
          const { enabledSections } = store.getters['metadata/siteMetadata']
          if (!enabledSections.learn) {
            next({ name: 'main' })
            return
          }
          next()
        },
      },
      {
        path: 'learn/course/:_id',
        name: 'learn-course',
        component: Course,
        props: route => ({ _id: route.params._id, parent: route.query.parent, focusedLesson: route.query.lesson }),
      },
      {
        path: 'practice',
        name: 'practice-main',
        component: Practice,
        beforeEnter: async (to, from, next) => {
          if (!store.getters['metadata/siteMetadata']) {
            await store.dispatch('metadata/fetchSiteMetadata')
          }
          const { enabledSections } = store.getters['metadata/siteMetadata']
          if (!enabledSections.practice) {
            next({ name: 'main' })
            return
          }
          next()
        },
        props: route => ({ linkedModule: route.query.module }),
      },
      {
        path: 'practice/target/:id',
        name: 'practice-target',
        component: PracticeTarget,
        props: true,
      },
      {
        path: 'compete',
        name: 'compete-main',
        component: Compete,
        beforeEnter: async (to, from, next) => {
          if (!store.getters['metadata/siteMetadata']) {
            await store.dispatch('metadata/fetchSiteMetadata')
          }
          const { enabledSections } = store.getters['metadata/siteMetadata']
          if (!enabledSections.compete) {
            next({ name: 'main' })
            return
          }
          next()
        },
      },
      {
        path: 'compete/challenege-board/:_id',
        name: 'compete-challenge-board',
        component: ChallengeBoard,
        props: route => ({ _id: route.params._id }),
      },
      {
        path: 'compete/tournament/:_id',
        name: 'compete-tournament',
        component: Tournament,
        props: route => ({ _id: route.params._id }),
      },
      {
        path: 'scoreboard',
        name: 'scoreboard-main',
        component: Scoreboard,
        props: (route) => ({ scoreboardId: route.query.scoreboard, filter: route.query.filter }),
      },
      {
        path: 'profile',
        name: 'profile-main',
        component: Profile,
        beforeEnter: async (to, from, next) => {
          if (!(await isAuthenticated())) {
            await store.dispatch('auth/logout')
            next({ name: 'login' })
          } else {
            next()
          }
        },
      },
      {
        path: 'teams',
        name: 'teams',
        component: Teams,
      },
      {
        path: 'badges',
        name: 'badges',
        component: Badges,
        beforeEnter: async (to, from, next) => {
          if (!store.getters['metadata/siteMetadata']) {
            await store.dispatch('metadata/fetchSiteMetadata')
          }
          const { isBadgesEnabled } = store.getters['metadata/siteMetadata']
          if (!isBadgesEnabled) {
            next({ name: 'main' })
            return
          }
          next()
        },
      },
      {
        path: 'badges/:_id',
        name: 'badge-details',
        component: BadgeDetails,
        props: route => ({ _id: route.params._id }),
      },
      {
        path: 'collection/:teamId-:collectionId',
        name: 'teams-collection',
        component: Collection,
        props: route => ({
          teamId: route.params.teamId,
          collectionId: route.params.collectionId,
        }),
      },
      {
        path: 'compete/assessments',
        name: 'compete-assessments',
        component: Assessments,
      },
      {
        path: 'compete/assessments/:_id',
        name: 'compete-assessments-exam',
        component: AssessmentDetails,
        props: route => ({ _id: route.params._id }),
      },
      {
        // will match anything starting with `/user-`
        path: '*',
        component: NotFound,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition && (to.name === 'learn-main' || to.name === 'learn-course' || to.name === 'practice-main')) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  // Check each route except
  const exceptions = [
    'oauth-login',
    'login',
    'login-callback',
    'reset-password',
    'forgot-password',
    'register',
    'verify-email',
    'logout',
    'invite-registration',
  ]
  if (exceptions.includes(to.name)) {
    next()
    return
  }

  if (!store.getters['metadata/siteMetadata']) {
    try {
      await store.dispatch('metadata/fetchSiteMetadata')
    } catch (e) {
      console.log(`Caught: ${e}`)
      return next()
    }
  }
  const paywallEnabled = store.getters['metadata/siteMetadata']?.paywallEnabled
  if (paywallEnabled === undefined) return next()

  if (paywallEnabled && !(await isAuthenticated())) {
    await store.dispatch('auth/logout')
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
