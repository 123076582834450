<template>
  <div class="flex flex-row h-full min-h-screen">
    <div class="w-full bg-th-primary items-center relative z-40 flex flex-col">
      <div class="w-full bg-th-navbar h-12 flex flex-row items-center pl-4 text-th-text-navbar z-40 fixed top-0">
        <div class = "flex flex-row flex-grow items-center">
          <div
            class="font-bold cursor-pointer flex items-center"
            @click="$router.replace({ name: 'main' })"
          >
            <div v-if="imageData" class="object-contain h-8 mr-4">
              <img
                ref="image"
                :src="imageData"
              />
            </div>
            <DCALogo
              v-else-if="imageData === null"
              class="w-6 h-6 fill-current stroke-current mr-4"
            />
            <div id="titleID" ref="titleID" class="text-xl">
              {{ siteMetadata ? siteMetadata.portalTitle : "" }}
            </div>
          </div>
          <div
            class="flex items-center rounded-full pl-4 pr-6 bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary mx-4 py-1 cursor-pointer"
            @click="$refs.searchModal.open()"
          >
            <SearchIcon class="w-6 h-6 mr-2 fill-current" />
            Search
          </div>
          <SearchModal ref="searchModal"></SearchModal>
        </div>
        <div
          v-if="siteMetadata"
          class="hidden lg:flex flex-row h-full space-x-3"
        >
          <div
            v-for="item of navbarItems" :key="item.text"
            class="w-32 h-full"
          >
            <router-link
              v-if="!item.children"
              class="flex w-full h-full items-center justify-center cursor-pointer hover:opacity-50"
              :class="{
                'border-b-4 border-white border-opacity-20 font-bold pt-1 rounded-t-sm':
                  $route.name && $route.name.startsWith(item.text.toLowerCase())
              }"
              :to="{ name: item.routeName }"
            >
              {{ item.text }}
              <div v-if="item.notifications && item.notifications > 0" class="bg-th-notification rounded-full w-6 h-6 flex items-center justify-center text-sm ml-2">
                {{ item.notifications }}
              </div>
            </router-link>
            <div
              v-else
              class="relative w-full h-full"
              @mouseenter="activeDropdown = item.text"
              @mouseleave="activeDropdown = undefined"
            >
              <div
                class="flex w-full h-full flex-row cursor-pointer px-4 items-center justify-center"
                :class="{
                  'border-b-4 border-white border-opacity-20 font-bold pt-1 rounded-t-sm hover:opacity-50':
                    $route.name && $route.name.startsWith(item.text.toLowerCase())}"
              >
               <!--:class="{
                  'border-b-4 border-white border-opacity-20 font-bold pt-1':
                    $route.name && $route.name.startsWith(item.text.toLowerCase())
                }"-->

                <div
                  class="w-full"
                >
                  {{ item.text }}
                </div>
                <CaratIcon class="fill-current h-10 w-10" />
                <div v-if="item.children?.some(child => { return child.notifications && child.notifications > 0 })" class="bg-th-notification rounded-full w-2 h-2 flex items-center justify-center text-sm absolute right-2 bottom-2"></div>
              </div>
              <!-- <div
                v-if="activeDropdown === item.text"
                class="absolute flex flex-col w-full top-12 left-0 divide-y divide-th-text-secondary border-2 bg-th-secondary border-th-text-secondary border-2 round-md font-bold rounded-b-md"
              > -->
              <div
                v-if="activeDropdown === item.text"
                class="absolute flex flex-col w-full top-12 left-0 bg-th-navbar round-md font-bold rounded-b-md"
              >
                <router-link
                  v-for="child of item.children" :key="child.text"
                  class="flex w-full h-full items-center justify-center cursor-pointer th-text-navbar py-2 hover:opacity-90"
                  @click.native="activeDropdown = undefined"
                  :to="{ name: child.routeName }"
                  >{{ child.text }}
                  <div v-if="child.notifications && child.notifications > 0" class="bg-th-notification rounded-full w-5 h-5 flex items-center justify-center text-sm ml-2">
                    {{ child.notifications }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <a
            v-show="siteMetadata.forumLinkEnabled"
            class="w-32 h-full flex items-center justify-center cursor-pointer"
            :href="siteMetadata.forumLink" target="_blank"
            >Forum
            <ForumArrow class="ml-2 w-3 h-3 fill-current" />
          </a>
          <div
            style="min-width: 125px"
            class="bg-th-secondary text-th-text-secondary h-full flex justify-center items-center px-4 ml-4 border-th-text-secondary border-b-2"
          >
            <router-link
              v-if="currentUser"
              class="flex flex-row"
              :to="{ name: 'profile-main' }"
            >
              <ProfileIcon class="w-6 h-6 mr-2 fill-current" />
              <div>{{ currentUserFullName }}</div>
            </router-link>
            <router-link v-else :to="{ name: 'login' }">
              <div class="whitespace-nowrap">Register / Login</div>
            </router-link>
          </div>
        </div>
        <div ref="burgermenu" class="lg:hidden pr-6 pl-2">
          <BurgerMenu
            class="cursor-pointer"
            @click="showMobileMenu ? resetMobileMenu() : showMobileMenu = true"
          />
          <div v-if="anyNotifications" class="bg-th-notification text-white rounded-full w-2 h-2 flex items-center justify-center text-sm absolute right-4 bottom-2"></div>
        </div>
      </div>
      <div class="mt-12 h-0 w-full flex flex-col flex-grow">
        <div
          v-if="showMobileMenu"
          class="z-50 flex flex-col w-full lg:hidden bg-th-secondary text-th-text-secondary py-4 fixed"
        >
          <div
            v-for="item of navbarItems" :key="item.text"
            class="w-full py-2"
          >
            <router-link
              v-if="!item.children"
              class="flex text-center justify-center items-center cursor-pointer"
              @click.native="resetMobileMenu"
              :to="{ name: item.routeName }"
              >{{ item.text }}
              <div v-if="item.notifications && item.notifications > 0" class="bg-th-notification rounded-full w-6 h-6 flex items-center justify-center text-sm ml-2">
                {{ item.notifications }}
              </div>
            </router-link>
            <div v-else>
              <div
                class="flex w-full text-center justify-center items-center cursor-pointer relative"
                @click="activeDropdown = activeDropdown === item.text ? undefined : item.text"
              >
                {{ item.text }}
                <CaratIcon class="fill-current h-6 w-6" />
                <div v-if="item.children?.some(child => { return child.notifications && child.notifications > 0 })" class="bg-th-notification rounded-full w-2 h-2 flex text-sm ml-2"></div>
              </div>
              <div v-if="activeDropdown === item.text">
                <router-link
                  v-for="child of item.children" :key="child.text"
                  class="flex w-full text-center justify-center items-center cursor-pointer font-thin pt-2"
                  @click.native="resetMobileMenu"
                  :to="{ name: child.routeName }"
                  >{{ child.text }}
                  <div v-if="child.notifications && child.notifications > 0" class="bg-th-notification rounded-full w-5 h-5 flex items-center justify-center text-sm ml-2">
                    {{ child.notifications }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <a
            v-show="siteMetadata.forumLinkEnabled"
            class="w-full py-2 text-center flex justify-center items-center cursor-pointer"
            :href="siteMetadata.forumLink" target="_blank"
            >Forum
            <ForumArrow class="ml-2 w-3 h-3 fill-current" />
          </a>
          <div
            class="mt-3 pt-3 border-t border-th-text-secondary flex justify-center items-center px-4 ml-4 cursor-pointer"
          >
            <div
              class="flex flex-row"
              @click="
                showMobileMenu = false;
                $router.push({ name: 'profile-main'});
              "
            >
              <ProfileIcon class="w-6 h-6 mr-2 fill-current" />
              <div>
                {{ currentUser ? currentUserFullName : "Register / Login" }}
              </div>
            </div>
          </div>
        </div>
        <router-view
          class="py-2 px-2 sm:py-4 text-th-text w-full sm:px-6 flex-grow bg-th-primary"
        />
        <CopyrightFooter
          :copyrightText="siteMetadata ? siteMetadata.copyrightBy : ''"
          :contactEmail="contactUsEnabled ? contactEmail : undefined"
          :contactEmailSubject="contactEmailSubject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CaratIcon from '@/assets/carat.svg'
import ProfileIcon from '@/assets/profile.svg'
import ForumArrow from '@/assets/forum-arrow.svg'
import DCALogo from '@/assets/dca-logo.svg'
import BurgerMenu from '@/assets/burger-menu.svg'
import ThemeService from 'metadata-service'
import CopyrightFooter from '@/components/utilities/CopyrightFooter.vue'
import { hexToPercentOpacity } from '@/services/utilities.js'
import SearchIcon from '@/assets/search.svg'
import SearchModal from '../components/utilities/SearchModal.vue'

export default {
  components: {
    DCALogo,
    BurgerMenu,
    CaratIcon,
    ProfileIcon,
    ForumArrow,
    CopyrightFooter,
    SearchIcon,
    SearchModal,
  },
  async beforeMount () {
    await this.fetchSiteMetadata()
    this.contactUsEnabled = this.siteMetadata.contactUsEnabled
    this.contactEmail = this.siteMetadata.contactEmail
    this.contactEmailSubject =
      'A Message from ' + this.siteMetadata.portalTitle + ' User'
    document.title = this.siteMetadata.portalTitle
    this.setThemeColors()
    this.getLogo()
    if (this.$store.getters['auth/user']) { await this.$store.dispatch('team/fetchTeams', this.$store.getters['auth/user'].teams) }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/user']
    },
    currentUserFullName () {
      if (this.currentUser) {
        return `${this.currentUser.firstName} ${this.currentUser.lastName}`
      }
      return '---'
    },
    teamInvites () {
      return this.currentUser?.teamInvites
    },
    anyNotifications () {
      return this.navbarItems.some(item => { return (item.notifications && item.notifications > 0) || (item.children && item.children.some(child => { return child.notifications && child.notifications > 0 })) })
    },
    navbarItems () {
      const items = []
      if (this.siteMetadata.enabledSections.learn) items.push({ text: 'Learn', routeName: 'learn-main' })
      if (this.siteMetadata.enabledSections.practice) items.push({ text: 'Practice', routeName: 'practice-main' })
      if (this.siteMetadata.enabledSections.compete) {
        // If assessments are allowed for this instance, and assessment page is enabled in admin portal, push both contests and assessments to the nav bar item as children content
        // Else, No drop down option/children content and default compete to contests
        if (this.siteMetadata.enabledFeatures?.assessments && this.siteMetadata.assessmentEnabled) {
          const childrenContent = []
          childrenContent.push({ text: 'Contests', routeName: 'compete-main' })
          childrenContent.push({ text: 'Assessments', routeName: 'compete-assessments' })
          items.push({
            text: 'Compete',
            children: childrenContent,
          })
        } else {
          items.push({ text: 'Compete', routeName: 'compete-main' })
        }
      }
      if (this.siteMetadata.isBadgesEnabled) items.push({ text: 'Badges', routeName: 'badges' })
      items.push({ text: 'Teams', routeName: 'teams', notifications: this.teamInvites?.length || 0 })
      return items
    },
  },
  mounted () {
    window.addEventListener('resize', this.checkForTitleHeight)
  },
  updated () {
    this.checkForTitleHeight()
  },
  unmounted () {
    window.removeEventListener('resize', this.checkForTitleHeight)
  },
  data () {
    return {
      showMobileMenu: false,
      showProfileMenu: false,
      userUpdateTask: undefined,
      siteMetadata: undefined,
      contactEmail: undefined,
      contactUsEnabled: undefined,
      imageData: undefined,
      contactEmailSubject: undefined,
      activeDropdown: undefined,
    }
  },
  methods: {
    hexToPercentOpacity,
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
    },
    hexToRgba (hex, opacity) {
      // Remove the hash sign (#) if it's included
      hex = hex.replace('#', '')

      // Convert the hex string to an integer value
      var hexValue = parseInt(hex, 16)

      // Extract the red, green, and blue values from the integer
      var red = (hexValue >> 16) & 255
      var green = (hexValue >> 8) & 255
      var blue = hexValue & 255

      // Return the rgba() value as a string
      return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + opacity + ')'
    },
    setThemeColors () {
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--text-navbar', this.siteMetadata.navbarTextColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty('--notification', this.siteMetadata.notificationColor)

      // override vue material styles to make them compatible with custom styles configured in admin portal
      document.documentElement.style.setProperty('--md-switch-thumb-checked', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--md-switch-thumb-unchecked', hexToPercentOpacity(this.siteMetadata.textColor, 0.3))
      document.documentElement.style.setProperty('--md-switch-bar-unchecked', hexToPercentOpacity(this.siteMetadata.textColor, 0.2))
      document.documentElement.style.setProperty('--md-switch-bar-checked', hexToPercentOpacity(this.siteMetadata.textColor, 0.6))

      // overide styling for vue material radio buttons
      // checked
      document.documentElement.style.setProperty('--md-theme-default-accent', hexToPercentOpacity(this.siteMetadata.secondaryTextColor, 0.6))
      // unchecked
      document.documentElement.style.setProperty('--unchecked-radio-border', hexToPercentOpacity(this.siteMetadata.secondaryTextColor, 0.3))

      document.documentElement.style.setProperty('--md-theme-default-primary-on-background', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty('--md-theme-default-primary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty('--md-theme-default-text-primary-on-primary', this.siteMetadata.secondaryColor)

      document.documentElement.style.setProperty('--md-theme-default-accent-on-background', this.siteMetadata.textColor)
    },
    async getLogo () {
      const res = await ThemeService.getLogo()
      this.imageData = res.imageData
    },
    checkForTitleHeight () {
      if (this.$refs.titleID.offsetHeight > 28) {
        this.$refs.titleID.classList.add('invisible')
      } else {
        this.$refs.titleID.classList.remove('invisible')
      }
    },
    resetMobileMenu () {
      this.showMobileMenu = false
      this.activeDropdown = undefined
    },
  },
}
</script>
