<template>
  <div>
    <div class="flex flex-col items-start">
      <div class="flex justify-between w-full">
        <div>
          <div class="flex flex-row items-center">
            <AchieveIcon class="w-10 h-10 fill-current mr-4" />
            <div class="text-4xl font-bold">Compete</div>
          </div>
          <div class="text-lg mb-8">
            {{
              this.$parent.siteMetadata !== undefined
                ? this.$parent.siteMetadata.competeDescription
                : ""
            }}
          </div>
        </div>
        <!-- this scorecard appears on large screens -->
        <div
          v-if="showScoreboard"
          class="hidden xl:flex flex-row mt-6 lg:space-y-0 lg:space-x-4 pb-4 justify-center space-y-4"
        >
          <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
            <ScoreboardCard
              :item="{
                name: `${user.firstName} ${user.lastName}`,
                team: '---',
                rank: user.rank,
                overallScore: user.score,
                score: user.coinCounts,
              }"
              @click="$router.push({ name: 'scoreboard-main' })"
            />
          </div>
          <div class="flex flex-col justify-center items-center">
            <div
              class="flex flex-col items-center cursor-pointer"
              @click="$router.push({ name: 'scoreboard-main' })"
            >
              <ArrowRightCircle class="fill-current w-10 h-10 mb-4" />
              <div class="text-center">View Scoreboard</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="mt-8 text-xl text-center w-full">Loading Content...</div>
      <div v-else class="w-full">
        <!-- this scorecard appears on small screens -->
        <div v-if="showScoreboard" class="mb-4 xl:hidden">
          <div class="mt-6 flex flex-row space-x-4 pb-4 justify-center space-y-4">
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
              <ScoreboardCard
                :item="{
                  name: `${user.firstName} ${user.lastName}`,
                  team: '---',
                  rank: user.rank,
                  overallScore: user.score,
                  score: user.coinCounts,
                }"
              />
            </div>
            <div class="flex flex-col justify-center items-center">
              <div
                class="flex flex-col items-center cursor-pointer"
                @click="$router.push({ name: 'scoreboard-main' })"
              >
                <ArrowRightCircle class="fill-current w-10 h-10 mb-2" />
                <div class="text-center">View Scoreboard</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div v-show="activeContests.length > 0">
            <div class="border-b-2 border-th-text pb-2 mb-4">
              <div class="text-2xl font-bold">Active Contests</div>
              <div class="font-normal">Compete in these live challenges</div>
            </div>
            <CarouselWrapper>
              <slide
                class="cursor-pointer w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                v-for="contest in activeContests"
                :key="contest._id"
              >
                <ContestCard :item="contest" @click.native="openContest(contest)" />
              </slide>
            </CarouselWrapper>
          </div>

          <div v-show="upcomingContests.length > 0">
            <div class="border-b border-th-text pb-2 mb-4">
              <div class="text-2xl font-bold">Upcoming Contests</div>
              <div class="font-thin">Prepare for these upcoming challenges</div>
            </div>
            <CarouselWrapper>
              <slide
                :class="{ 'cursor-pointer': contest.type === 'tournament' }"
                class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                v-for="contest in upcomingContests"
                :key="contest.title"
              >
                <ContestCard
                  :item="contest"
                  @click.native="
                    contest.type === 'tournament' ? openContest(contest) : {}
                  "
                />
              </slide>
            </CarouselWrapper>
          </div>

          <div v-show="pastContests.length > 0">
            <div class="border-b border-th-text pb-2 mb-4">
              <div class="text-2xl font-bold">Past Contests</div>
              <div class="font-thin">Prepare for these upcoming challenges</div>
            </div>
            <CarouselWrapper>
              <slide
                :class="{ 'cursor-pointer': contest.type === 'tournament' }"
                class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                v-for="contest in pastContests"
                :key="contest.title"
              >
                <ContestCard
                  :item="contest"
                  @click.native="
                    contest.type === 'tournament' ? openContest(contest) : {}
                  "
                />
              </slide>
            </CarouselWrapper>
          </div>
        </div>
      </div>
    </div>
    <ContestModal
      ref="contestModal"
      :submit="checkAnswer"
      @closed="$store.dispatch('compete/fetchScores')"
    />
    <LoginModal ref="loginModal" :modalText="'Create an account to Compete'"/>
  </div>
</template>

<script>
import AchieveIcon from '@/assets/achieve.svg'
import ScoreboardCard from '@/components/scoreboard/ScoreboardCard.vue'
import ArrowRightCircle from '@/assets/arrow-right-circle.svg'
import ContestCard from '@/components/compete/ContestCard.vue'
import ContestModal from '@/components/compete/ContestModal.vue'
import LoginModal from '@/components/utilities/LoginModal'

import CarouselWrapper from '@/components/utilities/CarouselWrapper.vue'
import { Slide } from 'vue-carousel'

import CompeteService from 'compete-service'
import EventService from 'event-service'

export default {
  components: {
    AchieveIcon,
    ScoreboardCard,
    ArrowRightCircle,
    CarouselWrapper,
    Slide,
    ContestCard,
    ContestModal,
    LoginModal,
  },
  async mounted () {
    if (this.allContests.length === 0) this.isLoading = true
    await this.$store.dispatch('compete/fetchCompeteData')
    this.isLoading = false
  },
  computed: {
    showScoreboard () {
      if (!this.user) return false
      // only show scoreboard if there is assessment or challenge board contest
      // since tournaments contain their own scoreboard
      for (const contest of this.allContests) {
        if (contest.type === 'assessment' || contest.type === 'challenge-board') {
          return true
        }
      }
      return false
    },
    allContests () {
      return this.$store.getters['compete/contests']
    },
    activeContests () {
      return this.allContests.filter((contest) => {
        return (
          (contest.startDate === null || Date.parse(contest.startDate) < Date.now()) &&
          (contest.endDate === null || Date.parse(contest.endDate) > Date.now())
        )
      })
    },
    upcomingContests () {
      return this.allContests.filter((contest) => {
        return contest.startDate !== null && Date.parse(contest.startDate) > Date.now()
      })
    },
    pastContests () {
      return this.allContests.filter((contest) => {
        return contest.endDate !== null && Date.parse(contest.endDate) < Date.now()
      })
    },
    allChallenges () {
      return this.$store.getters['compete/challenges']
    },
    leaderBoard () {
      return this.$store.getters['compete/highScores'](3)
    },
    user () {
      return this.$store.getters['auth/user']
    },
  },
  data () {
    return {
      isLoading: false,
      showChallengeModal: false,
      selectedCategory: 3,
      dispalyedContests: undefined,
    }
  },
  methods: {
    async fetchHighScores () {
      this.leaderBoard = await CompeteService.fetchHighScores()
    },
    async checkAnswer (challengeId, answer) {
      return await CompeteService.checkAnswer(challengeId, answer)
    },
    showContestModal (contest) {
      var challenges = this.allChallenges.filter(
        (challenges) =>
          challenges.contests.find((contestId) => contestId === contest._id) !== undefined,
      )

      this.$refs.contestModal.open(contest, challenges, this.user.completions.challenges)
    },
    async logContestHit (contestId) {
      await EventService.createEvent({
        eventName: 'contestClick',
        payload: { contestId },
      })
    },
    openContest (contest) {
      if (!this.user) {
        this.$refs.loginModal.open()
        return
      }
      this.logContestHit(contest._id)
      if (contest.type === 'challenge-board') {
        this.$router.push({
          name: 'compete-challenge-board',
          params: { _id: contest._id },
        })
      } else if (contest.type === 'assessment') {
        this.showContestModal(contest)
      } else if (contest.type === 'tournament') {
        this.$router.push({
          name: 'compete-tournament',
          params: { _id: contest._id },
        })
      }
    },
  },
}
</script>

<style></style>
