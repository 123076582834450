<template>
  <div class="max-w-full flex flex-row relative items-center">
    <!-- <div
      v-show="!hideLeft"
      @click.stop="$refs.carousel.advancePage('backward')"
      class="ml-6 w-12 h-12 absolute left-0 z-20 cursor-pointer bg-dca-sand rounded-full flex items-center justify-center border-2 border-th-accent shadow"
    >
      <ChevronBack class="w-2/5 text-th-accent fill-current" />
    </div> -->
    <div v-if="siteMetadata" class="w-full">
      <carousel ref="carousel" :perPage="1" :perPageCustom="[
        [768, 2],
        [1200, 3],
        // [1400, 4]
      ]" :paginationColor="hexToPercentOpacity(siteMetadata.textColor, 0.5)"
        :paginationActiveColor="siteMetadata.textColor" :scrollPerPage="true" :paginationEnabled="true" :mouseDrag="true"
        @page-change="pageChanged">
        <slot></slot>
      </carousel>
    </div>
    <!-- <div
      v-show="!hideRight"
      @click.stop="$refs.carousel.advancePage('forward')"
      class="mr-6 w-12 h-12 absolute right-0 z-20 cursor-pointer bg-dca-sand rounded-full flex items-center justify-center border-2 border-th-accent"
    >
      <ChevronNext class="w-2/5 text-th-accent fill-current" />
    </div> -->
  </div>
</template>

<script>
import { Carousel } from 'vue-carousel'
import Vue from 'vue'

export default {
  components: {
    Carousel,
  },
  data () {
    return {
      numPages: 0,
      hideLeft: true,
      hideRight: false,
      siteMetadata: undefined,
    }
  },
  mounted () {
    Vue.nextTick(() => {
      // Necessary to fix bad initial sizing of carousel in Safari. works fine without on other browsers.
      this.triggerResizeEvent()
    })
    this.fetchSiteMetadata()
  },
  methods: {
    triggerResizeEvent () {
      const event = new Event('resize')
      window.dispatchEvent(event)
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
    },
    hexToPercentOpacity (hex, opacity) {
      const alphaOpacityPercent = Math.round(opacity * 255)
      const alphaHex = alphaOpacityPercent.toString(16).padStart(2, '0')
      return '#' + hex.slice(1) + alphaHex
    },
    pageChanged (evt) {
      if (evt === 0) {
        this.hideLeft = true
      } else {
        this.hideLeft = false
      }

      if (evt === this.numPages - 1) {
        this.hideRight = true
      } else {
        this.hideRight = false
      }
    },
  },
}
</script>

<style lang="postcss">
.VueCarousel-slide {
  display: flex;
  justify-content: center;
}

button.VueCarousel-dot.VueCarousel-dot--active:focus {
  outline: none !important;
}

button.VueCarousel-dot:focus {
  outline: none !important;
}
</style>
