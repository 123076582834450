<template>
  <div class="z-0">
    <vue-circle
      ref="progressCircle"
      :progress="progress"
      :size="120"
      :reverse="false"
      line-cap="round"
      :fill="{ gradient: [color, hexToPercentOpacity(color, .6)] }"
      :empty-fill="hexToPercentOpacity(color, .2)"
      :animation-start-value="0.0"
      :start-angle="0"
      insert-mode="append"
      :show-percent="false"
      :thickness="5"
    >
        <slot></slot>
    </vue-circle>
  </div>
</template>
<script>
import { hexToPercentOpacity } from '@/services/utilities.js'
import VueCircle from 'vue2-circle-progress'

export default {
  components: {
    VueCircle,
  },
  methods: {
    hexToPercentOpacity,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
  },
  watch: {
    progress (newVal, oldVal) {
      this.$refs.progressCircle.updateProgress(newVal)
    },
  },
}
</script>
