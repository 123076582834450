<template>
  <div class="w-full shadow-lg">
    <!-- secondary and border -->
    <div
      class="h-full cursor-pointer flex flex-col w-full content-end px-4 pb-7 py-2 bg-th-secondary border-th-text-secondary border-2 rounded-md"
      @click="logCourseHit(); $emit('click', item._id)"
    >
      <div class="flex font-medium text-base mt-1 mb-3 text-th-text-secondary">
        <div class="flex flex-1 items-end gap-1">
          <div class="flex-none">
            <md-icon class="md-primary" md-font-icon="material-icons">outlined_flag</md-icon>
          </div>
          <div class="uppercase">Course</div>
        </div>
        <div v-if="item.lessons" class="flex items-center font-normal text-lg gap-2">
          <div class="pt-1">{{ item.lessons.length }}</div>
          <div class="flex-none">
            <md-icon md-font-icon="material-icons" class="md-primary">school</md-icon>
          </div>
        </div>
      </div>
      <div class="text-xl font-semibold mb-2 border-th-text-secondary flex flex-row items-center">
        <div v-html="highlightTitle()" class="flex-grow text-th-text-secondary" />
      </div>
      <div class="h-full flex flex-col text-th-text-secondary text-base gap-6">
          <div v-html="highlightDescription()" class="font-normal text-th-text-secondary line-clamp-3" />
          <!-- <ExpandableText :html="highlightDescription()" /> -->
          <div v-if="item?.objectives?.length > 0" class="flex flex-col gap-3 justify-end">
            <div v-for="objective of item.objectives.slice(0,2)" :key="objective" class="flex gap-3 font-medium">
              <!-- <div class="w-1 h-1 rounded-full bg-th-text-secondary flex-none mt-2" /> -->
              <div class="flex-none">
                <md-icon class="md-primary" md-font-icon="material-icons">check</md-icon>
              </div>
              <div class="font-medium">{{ objective }}</div>
            </div>
          </div>
      </div>
      <!-- <div class="self-start">
        <tags @tagClick="(tag) => $emit('tagClick', tag)" :tags="item.tags" :query="query" />
      </div> -->
    </div>
  </div>
</template>

<script>
import EventService from 'event-service'
// import ExpandableText from '@/components/utilities/ExpandableText.vue'
// import Tags from '@/components/learn/Tags.vue'

export default {
  // components: { Tags, ExpandableText },
  components: {
    // Tags,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    query: {
      type: String,
      required: false,
    },
  },
  computed: {
    title () {
      return this.item.title
    },
    description () {
      return this.item.description
    },
    type () {
      return this.item.type
    },
    moduleIcon () {
      switch (this.type) {
        case 'code':
          return 'ExternalLinkIcon'
        case 'trivia':
          return 'ExternalLinkIcon'
      }
      return undefined
    },
  },
  methods: {
    highlightTitle () {
      if (!this.query || this.query === '') {
        return this.title
      }
      return this.title.replace(new RegExp(this.query, 'gi'), match => {
        return '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' + match + '</span>'
      })
    },
    highlightDescription () {
      if (!this.query || this.query === '') {
        return this.description
      }
      return this.description.replace(new RegExp(this.query, 'gi'), match => {
        return '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' + match + '</span>'
      })
    },
    highlightTag (tag) {
      if (!this.query || this.query === '') {
        return tag
      }
      return tag.replace(new RegExp(this.query, 'gi'), match => {
        return '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' + match + '</span>'
      })
    },
    async logCourseHit () {
      await EventService.createEvent({ eventName: 'courseClick', payload: { courseId: this.item._id } })
    },
  },
}
</script>

<style lang="postcss">
.card-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 1);
}
</style>
