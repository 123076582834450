<template>
  <div class="w-full h-full min-h-screen flex flex-col justify-center items-center">
    <Toast width="w-1/3" type="danger" v-model="showSubmissionError" :autoClose="5">
      <template v-slot:title>An Error Occurred</template>
      <template
        v-slot:msg
      >There was an issue with requesting a password reset. Please try again or contact a system administrator.</template>
    </Toast>
    <BrandMark class="mb-8" :title="siteMetadata !== undefined ? siteMetadata.portalTitle : ''" :logoSize="siteMetadata !== undefined ? siteMetadata.logoSize : 'medium'" />
    <div style="max-width:650px" class="flex flex-col space-y-6 px-8">
      <div class="text-th-text text-opacity-30 text-4xl font-extrabold uppercase">Reset Password</div>
      <div v-if="submissionSuccess" class="space-y-6">
        <div>
          <div
            class="text-opacity-60 text-th-text"
          >The password reset was successfully processed. Please return to the login screen to login.</div>
        </div>
        <div
          class="bg-th-secondary text-th-text-secondary border-th-text-secondary border-2 px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          @click="$router.replace({name: 'login'})"
        >Return to Login</div>
      </div>
      <div v-if="!submissionSuccess" class="space-y-6">
        <div
          class="text-opacity-60 text-th-text"
        >Provide the email address associated with your account and a new password.</div>
        <div>
          <ValidatedInputField
            class="w-full"
            label="Email Address"
            type="text"
            autocomplete="current-password"
            v-model="$v.email.$model"
            :fieldError="errors && $v.email.$invalid"
            errorMessage="Please provide a valid email address"
          />
        </div>
        <div class="flex flex-col space-y-4 mb-4">
          <div>
            <div class="font-bold">Password Complexity Requirements</div>
            <ul class="list-disc list-inside text-sm">
              <li v-for="req in passwordRequirements" :key="req">{{ req }}</li>
            </ul>
          </div>
          <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
            <ValidatedInputField
              class="w-full"
              label="Password"
              type="password"
              autocomplete="new-password"
              v-model="$v.password.$model"
              :fieldError="errors && $v.password.$invalid"
              errorMessage="Please provide a password that matches password requirements."
            />
            <ValidatedInputField
              class="w-full"
              label="Confirm Password"
              type="password"
              autocomplete="new-password"
              v-model="$v.confirmPassword.$model"
              :fieldError="errors && $v.confirmPassword.$invalid"
              errorMessage="Please make sure password entries match."
            />
          </div>
        </div>
        <div class="flex justify-between pt-4 border-t border-th-text">
          <div
            class="bg-th-secondary border-2 border-th-text-secondary text-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
            @click="$router.replace({name: 'login'})"
          >Cancel</div>
          <div
            @click="processPasswordReset"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          >Reset Password</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { complexPassword, passwordRequirements } from '@/utilities/complexPassword'
import AuthService from 'auth-service'
import ValidatedInputField from '@/components/utilities/ValidatedInputField.vue'

export default {
  components: {
    ValidatedInputField,
  },
  props: ['ident', 'today', 'token'],
  computed: {
    errors () {
      return this.$v.$dirty
    },
  },
  created () {
    this.fetchSiteMetadata()
  },
  data () {
    return {
      submissionSuccess: false,
      submissionError: false,
      showSubmissionError: false,
      email: '',
      password: '',
      confirmPassword: '',
      siteMetadata: undefined,
      passwordRequirements,
    }
  },
  validations: {
    email: {
      required, email,
    },
    password: {
      required,
      complexPassword,
    },
    confirmPassword: {
      sameAs: sameAs(function () { return this.password }),
    },
  },
  methods: {
    async processPasswordReset () {
      this.submissionError = false
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Error....')
        this.submissionError = true
        return
      }

      const success = await AuthService.resetPassword(this.ident, this.today, this.token, this.$v.email.$model, this.$v.password.$model)
      if (success) {
        this.submissionSuccess = true
      } else {
        // Show an error toast
        this.showSubmissionError = true
      }
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty('--notification', this.siteMetadata.notificationColor)
    },
  },
}
</script>

<style>
</style>
