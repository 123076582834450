<template>
  <div>
    <LoginModal ref="loginModal" />
    <addToCollectionModal v-if="$store.getters['auth/user']" ref="addToCollectionModal" itemType="Lesson">
    </addToCollectionModal>
    <updateCompletionModal ref="completionModal" type="lesson" :item="selectedLesson" @addCompletion="addCompletion"
      @removeCompletion="removeCompletion"></updateCompletionModal>
    <lessonCommentModal ref="commentModal" :item="selectedLesson" @addComment="addComment" @removeComment="removeComment">
    </lessonCommentModal>
    <div v-if="course">
      <div v-if="track" class="flex flex-row h-full space-x-3 uppercase mb-4 text-xl items-center">
        <router-link class="opacity-50 cursor-pointer" :to="{ name: 'learn-main' }">Learn</router-link>
        <ChevronNext class="fill-current h-8 w-8" />
        <router-link class="opacity-50 cursor-pointer" :to="{ name: 'learn-main', hash: `#${track._id}` }">{{ track.title }}</router-link>
        <ChevronNext class="fill-current h-8 w-8" />
        <div class="font-bold">{{ course.title }}</div>
      </div>
      <div class="flex gap-3 items-center">
        <div class="flex-none">
          <md-icon md-font-icon="material-icons" class="md-accent md-size-2x">outlined_flag</md-icon>
        </div>
        <div class="text-4xl font-semibold">{{ course.title }}</div>
      </div>
      <div v-if="course.tags.length" class="">
        <tags :tags="course.tags" @tagClick="(tag) => $router.push({ name: 'learn-main', params: { searchTag: tag } })" />
      </div>
      <div class="font-normal text-lg pt-3 opacity-90">{{ course.description }}</div>
      <div v-if="course.objectives.length > 0" class="grid grid-cols-1 gap-4 mt-8">
        <div class="flex items-center gap-3 border-b border-th-text pb-1">
          <div class="flex-none">
            <md-icon md-font-icon="material-icons" class="md-accent">check</md-icon>
          </div>
          <div class="text-2xl font-medium">Objectives</div>
        </div>
        <div v-for="objective in course.objectives" :key="objective" class="text-lg opacity-90 flex justify-start">
          <li class="ml-3 font-normal">{{ objective }}</li>
        </div>
      </div>
      <div class="flex items-center mb-4 mt-10 gap-4 border-b border-th-text pb-1">
        <div class="flex-none">
          <md-icon md-font-icon="material-icons" class="md-accent">school</md-icon>
        </div>
        <div class="text-2xl font-medium border-th-text">Lessons</div>
      </div>
      <div class="flex h-full justify-center">
        <div class="h-full w-full">
          <div class="grid grid-cols-1 h-1/4 md:grid-cols-2 xl:grid-cols-3 gap-5">
            <div v-for="lesson in this.course.lessons" :key="lesson._id" :ref="`lesson-${lesson._id}`">
            <!-- <div v-for="lesson in this.course.lessons" :key="lesson._id" :id="'lesson'+lesson._id" :ref="'lesson'+lesson._id"> -->
              <LessonCard :ref="lesson._id" :item="lesson" itemType="lesson" :shareButton="true"
                @share="showAddToCollectionModal" @showLoginModal="showLoginModal" @showCommentModal="showCommentModal"
                @showCompleteModal="showCompleteModal" @getLessons="getLessons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronNext from '@/assets/chevron-next.svg'
import LessonCard from '@/components/learn/LessonCard.vue'
import Tags from '@/components/learn/Tags.vue'
import AddToCollectionModal from '@/components/profile/AddToCollectionModal.vue'
import LessonCommentModal from '@/components/learn/LessonCommentModal'
import LoginModal from '@/components/utilities/LoginModal'
import UpdateCompletionModal from '@/components/learn/UpdateCompletionModal'

export default {
  components: {
    ChevronNext,
    LessonCard,
    AddToCollectionModal,
    LessonCommentModal,
    LoginModal,
    UpdateCompletionModal,
    Tags,
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
    parent: {
      type: String,
      required: false,
    },
    focusedLesson: {
      type: String,
      required: false,
    },
  },
  computed: {
    track () {
      return this.$store.getters['learn/trackWithId'](this.parent)
    },
    course () {
      return this.$store.getters['learn/courseWithId'](this._id)
    },
  },
  data () {
    return {
      selectedLesson: undefined,
    }
  },
  async mounted () {
    if (this.$store.getters['learn/tracks'].length === 0) {
      await this.$store.dispatch('learn/fetchLearnData')
    }
    if (this.focusedLesson) {
      this.$nextTick(() => {
        this.scrollToLesson(this.focusedLesson)
      })
    }
  },
  methods: {
    showLoginModal (msg) {
      this.$refs.loginModal.open(msg)
    },
    closeLoginModal () {
      this.$refs.loginModal.close()
    },
    showAddToCollectionModal (item) {
      this.$refs.addToCollectionModal.open(item)
    },
    closeAddToCollectionModal () {
      this.$refs.addToCollectionModal.close()
    },
    showCommentModal (item) {
      this.selectedLesson = item
      this.$refs.commentModal.open()
    },
    closeCommentModal () {
      this.$refs.commentModal.close()
    },
    showCompleteModal (item) {
      this.selectedLesson = item
      this.$refs.completionModal.open()
    },
    closeCompleteModal () {
      this.$refs.completionModal.close()
    },
    addComment (commentText) {
      this.$refs[this.selectedLesson._id][0].addComment(commentText)
    },
    removeComment (comment) {
      this.$refs[this.selectedLesson._id][0].removeComment(comment)
    },
    addCompletion (completion) {
      this.$refs[this.selectedLesson._id][0].addCompletion(completion)
    },
    removeCompletion () {
      this.$refs[this.selectedLesson._id][0].removeCompletion()
    },
    getLessons () {
      this.$store.dispatch('learn/fetchLearnData')
    },
    scrollToLesson (id) {
      const lessonDiv = this.$refs[`lesson-${id}`][0]
      console.log(lessonDiv)
      if (lessonDiv) {
        window.scrollTo({ top: lessonDiv.offsetTop - 48 - 5, behavior: 'smooth' })
      }

      var scrollTimeout
      const scrollHandler = function (e) {
        clearTimeout(scrollTimeout)
        scrollTimeout = setTimeout(function () {
          console.log('Scroll ended')
          lessonDiv.classList.add('shake')
          setTimeout(() => {
            lessonDiv.classList.remove('shake')
            window.removeEventListener('scroll', scrollHandler)
          }, 500)
        }, 100)
      }
      window.addEventListener('scroll', scrollHandler)
    },
  },
  watch: {
    course: function (newVal) {
      if (this.selectedLesson) {
        this.selectedLesson = newVal.lessons.find(lesson => lesson._id === this.selectedLesson._id)
      }
    },
    focusedLesson: function (newLesson) {
      if (this.focusedLesson) {
        this.$nextTick(() => {
          this.scrollToLesson(this.focusedLesson)
        })
      }
    },
  },
}
</script>

<style></style>
