<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-th-text-secondary text-xl font-bold">Update Team</div>
        </div>
        <div>
          <div class="flex flex-col w-full">
            <span class="text-th-text-secondary uppercase pb-2 text-sm">Team Name</span>
            <input
              type="text"
              v-model="teamName"
              autocomplete="off"
              :disabled="submitted"
              @input="existingTeamError = false"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
              :class="{'border-2 border-red-700': existingTeamError}"
            />
            <span v-if="existingTeamError" class="text-th-text-secondary uppercase text-sm font-bold">A team with this name already exists</span>
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="!submitted"
            v-on="validated ? {click: ()=>{submit()}} : {}"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase"
            :class="{'opacity-60' : !validated, 'opacity-100 cursor-pointer': validated}"
          >Update</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'

export default {
  components: {
    CloseIcon,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data () {
    return {
      visible: false,
      result: undefined,
      submitted: false,
      teamName: this.item ? this.item.name : '',
      existingTeamError: false,
    }
  },
  computed: {
    validated () {
      return this.teamName !== ''
    },
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
      this.existingTeamError = false
    },
    showExistingTeamError () {
      this.existingTeamError = true
    },
    submit () {
      this.$emit('submit', { name: this.teamName, lead: this.item.lead.email, members: this.item.members.map(member => member.email) })
    },
  },
}
</script>

<style>
</style>
