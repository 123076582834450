<template>
  <div class="flex flex-wrap justify-start mt-4 text-white">
    <div
      v-for="(tag,index) in tags"
      v-html="highlightQuery(tag)"
      :key="index"
      class="text-sm capitalize font-normal border-th-text-secondary border bg-th-secondary text-th-text-secondary hover:bg-th-text-secondary hover:text-th-secondary transition-all duration-150 rounded-md cursor-pointer px-2 py-1 mr-2 mb-2"
      @click.stop="$emit('tagClick', tag)"
    />
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    query: {
      type: String,
      required: false,
    },
  },
  methods: {
    highlightQuery (tag) {
      if (!this.query || this.query === '') {
        return tag
      }
      return tag.replace(new RegExp(this.query, 'gi'), match => {
        return '<span class="bg-yellow-300 text-gray-700 rounded-sm bg-opacity-75">' + match + '</span>'
      })
    },
  },
}
</script>
