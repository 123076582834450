<template>
  <div v-if="this.$parent.siteMetadata" class="flex flex-col items-center space-y-10 flex-grow pt-8">
    <BrandMark
      :title="this.$parent.siteMetadata.portalTitle"
      :logoSize="this.$parent.siteMetadata.logoSize"
    />
    <div class="flex flex-col font-normal justify-center mx-1 md:mx-10">
      <div class="text-justify my-4 text-lg">
        <span
          v-html="this.$parent.siteMetadata.homepageText"
        ></span>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row space-y-8 lg:space-y-0 justify-evenly lg:w-11/12">
      <div v-for="section in learnPracticeCompete.filter((section) => $parent.siteMetadata.enabledSections[section])" :key="section">
        <div
          class="flex flex-col h-full items-center justify-between w-64 mx-12"
        >
          <div>
            <div class="flex flex-row items-center justify-center">
              <component :is="getIcon(section)" class="w-10 h-10 fill-current mr-6" />
              <div class="text-4xl font-bold capitalize">{{ section }}</div>
            </div>
            <div class="text-center pt-3">
              {{$parent.siteMetadata[section + 'Description']}}
            </div>
          </div>
          <div class="flex justify-center py-4">
            <div
              @click="$router.push({ name: section + '-main' })"
              class="border-2 border-th-text-secondary bg-th-secondary text-th-text-secondary px-4 py-2 w-32 font-bold text-center rounded-md cursor-pointer"
            >
              Explore
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnIcon from '@/assets/learn.svg'
import PracticeIcon from '@/assets/practice.svg'
import AchieveIcon from '@/assets/achieve.svg'

export default {
  components: {
    LearnIcon,
    PracticeIcon,
    AchieveIcon,
  },
  data () {
    return {
      learnPracticeCompete: ['learn', 'practice', 'compete'],
    }
  },
  methods: {
    getIcon (sectionName) {
      switch (sectionName) {
        case 'learn':
          return 'LearnIcon'
        case 'practice':
          return 'PracticeIcon'
        case 'compete':
          return 'AchieveIcon'
      }
    },
  },
}
</script>

<style></style>
