var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"flex w-full justify-between pb-4"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row items-center"},[_c('AchieveIcon',{staticClass:"w-10 h-10 fill-current mr-4"}),_c('div',{staticClass:"text-4xl font-bold"},[_vm._v(_vm._s(_vm.contest ? _vm.contest.title : ''))])],1),_c('div',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.contest ? _vm.contest.description : ''))])])]),(_vm.userTeamsInTournament.length > 0)?_c('div',{staticClass:"font-semibold pb-2"},[_vm._v(" "+_vm._s(`Team: ${_vm.allTeams.find(team => team._id === _vm.userTeamsInTournament[0]).name}`))]):_vm._e(),(_vm.contest && _vm.selectedStage && _vm.contest.stages.length > 0)?_c('div',{staticClass:"border-b-2 border-th-text w-full items-center justify-center sm:space-x-4 flex flex-col sm:flex-row"},[_c('SelectInput',{staticClass:"mb-4 my-2 w-full sm:w-96",attrs:{"undefinedOption":false,"inputLabel":"Tournament Stage","value":_vm.selectedStage ? _vm.selectedStage._id : undefined,"options":_vm.contestStages.map(stage => {
          return {
            key: stage._id,
            name: stage.title,
          }
        })},on:{"input":_vm.stageSelected}})],1):_vm._e(),(_vm.selectedStage)?_c('div',{staticClass:"flex flex-wrap mt-4 justify-center w-full"},_vm._l((_vm.selectedStage.matches),function(match){return _c('div',{key:match._id,staticClass:"py-4 sm:px-4 w-full lg:w-1/2 xl:w-1/3"},[_c('div',{staticClass:"w-full flex flex-col p-2 rounded-lg border-4 border-th-text-secondary bg-th-secondary text-th-text-secondary"},[_c('div',{staticClass:"text-xl font-bold text-center"},[_vm._v(_vm._s(match.location))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(match.date),expression:"match.date"}],staticClass:"font-semibold text-center text-sm"},[_vm._v(_vm._s(_vm.matchDateString(match.date))+" "+_vm._s(_vm.matchTimeString(match.date)))]),_vm._m(0,true),_vm._l((_vm.sortTeamsByScore(match.teams)),function(team,index){return _c('div',{key:team.team,staticClass:"flex justify-between p-2",class:{
                'border-b border-th-text-secondary': index !== match.teams.length - 1,
                'bg-white bg-opacity-20': _vm.userTeamsInTournament.includes(team.team) && team.team !== match.victor,
                'bg-green-600 text-white': team.team === match.victor
              }},[_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.allTeams.find(allTeam => allTeam._id === team.team).name))]),_c('div',{staticClass:"ml-4"},[_vm._v(_vm._s(team.points))])])})],2)])}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between space-x-4 font-semibold border-b-2 border-th-text-secondary"},[_c('div',{staticClass:"px-4"},[_vm._v("Team")]),_c('div',{staticClass:"px-4"},[_vm._v("Score")])])
}]

export { render, staticRenderFns }