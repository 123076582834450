import Axios from 'axios'
import Buffer from 'buffer'

export default {
  async getSiteMetadata () {
    try {
      const response = await Axios.get('/admin/theme')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getLogo () {
    const res = await Axios.get(
      '/object-store/download/images/theme/logo.png',
      { responseType: 'arraybuffer' },
    )
      .then(response => {
        if (response.status === 200) {
          var mimeType = response.headers['content-type'].toLowerCase()
          var imgBase64 = Buffer.Buffer.from(
            response.data,
            'binary',
          ).toString('base64')
          return {
            imageData: `data:${mimeType};base64,${imgBase64}`,
            imageFile: new Blob([response.data], { type: mimeType }),
          }
        }
        return {
          imageData: null,
          imageFile: null,
        }
      })
      .catch(err =>
        // swallow
        console.log(err),
      )

    return {
      imageData: res.imageData,
      imageFile: res.imageFile,
    }
  },
}
