import Axios from 'axios'

export default {
  async createEvent (event) {
    const result = await Axios.post('/analytics/events', event).catch(err => {
      console.log('Error logging event')
      console.log(err)
      return { result: false, errors: err.data.errors }
    })

    if (result.data.errors.length > 0) {
      return { result: false, errors: result.data.errors }
    }

    return { result: true }
  },
}
