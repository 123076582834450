<template>
  <div class="w-full">
    <!-- secondary and border -->
    <div :class="`h-full flex px-4 py-3 bg-th-secondary border-th-text-secondary border-2 rounded-md`">
      <div :class="`hidden ${isBadgeView && 'md:flex md:flex-col'} w-96 items-center`">
        <md-icon :class="`${!isPassed && 'opacity-40'} md-size-5x md-primary mx-16`">verified</md-icon>
        <div v-if="isPassed" class="text-th-text-secondary">Complete!</div>
        <div class="-ml-2">
          <md-button
            :md-ripple="false"
            class="md-primary md-raised focus:outline-none flex-none"
            @click="$emit('click',item._id)"
          >
            Take Assessment
          </md-button>
        </div>
      </div>
      <div
        :class="`flex flex-col content-end flex-1 cursor-pointer ${isBadgeView && 'md:pointer-events-none'}`"
        @click="$emit('click',item._id)"
      >
        <div class="text-xl font-bold mb-2 flex flex-row items-center">
          <div class="flex-grow text-th-text-secondary pt-1">{{ item.title }}</div>
        </div>
        <div class="mb-2 text-base font-normal text-th-text-secondary">{{ item.description }}</div>
        <div :class="`mt-3 flex flex-row justify-between text-th-text-secondary text-base font-normal`">
          <div class="flex-1">
            <div class="font-semibold uppercase tracking-wide opacity-60 pb-3">Details</div>
            <div class="text-sm uppercase font-semibold">Questions</div>
            <div class="pb-3">{{ item.questions }}</div>
            <div class="text-sm uppercase font-semibold">Passing Score</div>
            <div class="pb-3">{{ item.passScore }}</div>
            <div class="text-sm uppercase font-semibold">Allowed Attempts</div>
            <div>{{ numberOfAllowedAttempts }}</div>
          </div>
          <div class="text-right">
            <div class="font-semibold uppercase tracking-wide opacity-60 pb-3">personal stats</div>
            <div v-if="isSubmitted">
              <div class="text-sm uppercase font-semibold">Best Score</div>
              <div class="pb-3">{{ bestScore.toString().slice(0,5) }}</div>
              <div class="text-sm uppercase font-semibold">Attempts</div>
              <div>{{ submissions.length }}</div>
            </div>
            <div class="" v-else>Not Attempted</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  props: {
    isBadgeView: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubmitted () {
      return this.submissions?.length > 0
    },
    isPassed () {
      return this.bestScore !== null && this.bestScore >= this.item.passScore
    },
    submissions () {
      return this.$store.getters['compete/submissionsForId'](this.item._id)
    },
    submissionsSortedByScore () {
      return [...this.submissions].sort(function (a, b) { return b.score - a.score })
    },
    bestScore () {
      if (this.submissions.length === 0) return null
      return this.submissionsSortedByScore[0].score
    },
    numberOfAllowedAttempts () {
      if (this.item.unlimitedAttempts) {
        return 'unlimited'
      } else {
        return this.item.numberOfAttemptsAllowed
      }
    },
  },
  methods: {
  },
}
</script>

<style lang="postcss">
.card-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 1);
}
</style>
