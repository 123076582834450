<template>
  <div v-if="visible" class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center">
    <div
      class="w-full max-h-full flex flex-col lg:w-1/2 lg:max-h-90 p-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative overflow-auto"
    >
      <div class="h-1/4 lg:h-1/4">
        <div class="flex justify-between text-th-text-secondary">
            <h2 class="font-bold text-xl pt-1">{{ item.title }}</h2>
          <CloseIcon
            class="cursor-pointer flex-none w-10 h-10 p-1 border-th-text-secondary fill-current ml-4"
            @click.stop="close"
          />
        </div>

        <div v-if="theme.contactUsEnabled" class="flex flex-col sm:flex-row text-th-text-secondary sm:space-x-2 mb-4">
          <div class="">Know a better resource?</div>
          <a
            :href="`mailto:${theme.contactEmail}?subject=${emailSubject}`"
            class="underline cursor-pointer font-semibold"
            >Provide a Suggestion</a
          >
        </div>

        <textarea
          class="bg-th-secondary border-2 border-th-text-secondary text-th-text-secondary px-3 py-2 mt-4 rounded-sm focus:outline-none w-full resize-none placeholder-th-text-secondary"
          v-model="commentText"
          placeholder="Add a comment..."
        />
        <div class="flex justify-end">
          <button
            class="bg-th-text-secondary text-th-secondary h-full w-24 py-2 text-sm font-bold text-center rounded-md cursor-pointer uppercase focus:outline-none mt-3"
            @click.stop="addComment()"
          >Publish</button>
        </div>
      </div>
      <div class="overflow-auto h-3/4 lg:h-3/4">
        <div class="mt-4 font-semibold text-sm text-th-text-secondary uppercase tracking-wide mb-1">Comments</div>
        <div v-show="!sortedComents.length" class="text-center text-th-text-secondary border-t border-th-text-secondary">No comments yet...</div>
        <div
          v-for="commentObj in sortedComents"
          :key="commentObj.comment + commentObj.timestamp"
          class="text-th-text-secondary border-t border-th-text-secondary pb-2 pt-2"
        >
          <div>
            <span
              class="font-bold mr-2"
              :class="{'opacity-40': !commentObj.adminApproved && commentsRequireAdminApproval}"
            >{{ commentObj.name }}</span>
            <span
              :class="{'opacity-40': !commentObj.adminApproved && commentsRequireAdminApproval}"
            >{{ getTimePassed(commentObj.timestamp) }}</span>
            <span
              v-show="!commentObj.adminApproved && commentsRequireAdminApproval"
              class="ml-2 text-sm italic font-bold"
            >comment pending...</span>
            <button
              class="bg-th-text-secondary text-th-secondary h-full pl-2 pr-3 text-sm font-bold text-center rounded-md cursor-pointer uppercase focus:outline-none float-right"
              @click.stop="removeComment(commentObj)"
              v-if="user.id === commentObj.userId"
            >Delete</button>
          </div>
          <div>
            <span
              :class="{'opacity-40': !commentObj.adminApproved && commentsRequireAdminApproval}"
            >{{ commentObj.comment }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'
import { DateTime } from 'luxon'

export default {
  components: {
    CloseIcon,
  },
  computed: {
    theme () {
      return this.$store.getters['metadata/siteMetadata']
    },
    emailSubject () {
      return `${this.theme.portalTitle}: Alternate Resource Suggestion for ${this.item.title}`
    },
    sortedComents () {
      const comments = this.commentsRequireAdminApproval ? this.item.comments.filter(comment => comment.adminApproved || (comment.userId === this.user._id.toString())) : this.item.comments
      return comments.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
    },
    user () {
      return this.$store.getters['auth/user']
    },
    commentsRequireAdminApproval () {
      return this.theme.requireCommentApproval
    },
  },
  props: {
    item: {
      type: Object,
    },
    practice: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      visible: false,
      commentText: '',
      commentError: false,
      commentErrorMsg: '',
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    },
    getTimePassed (thenDateTime) {
      var start = DateTime.fromISO(thenDateTime)
      var end = DateTime.local()
      const x = end.diff(start, ['years', 'months', 'days'])
      const months = x.months
      const days = x.days
      const years = x.years
      if (years >= 2) {
        return Math.floor(years) + ' years ago'
      } else if (years > 1) {
        return Math.floor(years) + ' year ago'
      } else if (months >= 2) {
        return Math.floor(months) + ' months ago'
      } else if (months > 1) {
        return Math.floor(months) + ' month ago'
      } else if (days >= 2) {
        return Math.floor(days) + ' days ago'
      } else if (days > 1) {
        return Math.floor(days) + ' day ago'
      } else {
        return 'today'
      }
    },
    async addComment () {
      // Error checking for comment:
      if (this.commentText === '' || this.commentText.length < 1) {
        this.commentError = true
        this.commentErrorMsg = 'Can\'t be empty'
      } else {
        this.commentError = false
        this.commentErrorMsg = ''
      }

      if (!this.commentError) {
        this.$emit('addComment', this.commentText)
        this.commentText = ''
      }
    },
    async removeComment (comment) {
      this.$emit('removeComment', comment)
      this.commentText = ''
      this.commentError = false
      this.commentErrorMsg = ''
    },
  },
}
</script>

<style></style>
