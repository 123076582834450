<template>
  <div v-if="siteMetadata" class="w-full h-full min-h-screen flex flex-col pt-12 items-center">
    <Toast width="w-1/3" type="danger" v-model="showSubmissionError" :autoClose="5">
      <template v-slot:title>An Error Occurred</template>
      <template
        v-slot:msg
      >There was an issue with requesting a password reset. Please try again or contact a system administrator.</template>
    </Toast>
    <BrandMark class="mb-8" :title="siteMetadata.portalTitle" :logoSize="siteMetadata.logoSize" />
    <div style="max-width:650px" class="flex flex-col space-y-6 px-8">
      <div class="text-th-text text-opacity-30 text-4xl font-extrabold uppercase">Forgot Password</div>
      <div v-if="submissionSuccess" class="space-y-6">
        <div>
          <div
            class="text-opacity-60 text-th-text"
          >Password reset request successfully sent. Please check the email provided for more information.</div>
        </div>
        <div
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded cursor-pointer uppercase"
          @click="$router.replace({name: 'login'})"
        >Return to Login</div>
      </div>
      <div v-if="!submissionSuccess" class="space-y-6">
        <div
          class="text-th-text"
        >Provide the email address associated with your account and a password reset link will be sent to you.</div>
        <div>
          <div class="text-th-text text-opacity-50 uppercase font-bold">Email Address</div>
          <input
            type="text"
            v-model="$v.email.$model"
            autocomplete="off"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary w-full rounded text-2xl px-2 py-1 focus:outline-none"
            :class="{'border-2 border-red-700': submissionError && $v.email.$invalid}"
          />
          <div
            class="text-white font-semibold text-xs uppercase py-1"
            v-if="submissionError && $v.email.$invalid"
          >Please provide a valid email address</div>
        </div>
        <div class="flex justify-between pt-4 border-t-2 border-th-text">
          <div
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded cursor-pointer uppercase"
            @click="$router.replace({name: 'login'})"
          >Cancel</div>
          <div
            @click="requestPasswordReset"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded cursor-pointer uppercase"
          >SEND RESET LINK</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AuthService from 'auth-service'

export default {
  created () {
    this.fetchSiteMetadata()
  },
  data () {
    return {
      submissionSuccess: false,
      submissionError: false,
      showSubmissionError: false,
      email: '',
      siteMetadata: undefined,
    }
  },
  validations: {
    email: {
      required, email,
    },
  },
  methods: {
    async requestPasswordReset () {
      this.submissionError = false
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Error....')
        this.submissionError = true
        return
      }

      const success = await AuthService.forgotPassword(this.$v.email.$model)
      if (success) {
        this.submissionSuccess = true
      } else {
        // Show an error toast
        this.showSubmissionError = true
      }
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.title = this.siteMetadata.portalTitle
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty(
        '--notification',
        this.siteMetadata.notificationColor,
      )
    },
  },
}
</script>

<style>
</style>
