<template>
  <div>
    <div>
      <div class="flex flex-row items-center">
        <component :is="iconComponent" class="w-10 h-10 text-dca-tan fill-current mr-3" />
        <div class="text-4xl font-bold">{{ item.title }}</div>
      </div>
      <div>{{ item.description }}</div>
    </div>
  </div>
</template>

<script>
import CodeIcon from '@/assets/code-icon.svg'
import QuestionIcon from '@/assets/question-icon.svg'

export default {
  components: {
    CodeIcon, QuestionIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    item () {
      return this.$store.getters.practiceModuleById(this.id)
    },
    iconComponent () {
      switch (this.item.type) {
        case 'code':
          return 'CodeIcon'
        case 'trivia':
          return 'QuestionIcon'
        default:
          return undefined
      }
    },
  },
}
</script>

<style>
</style>
