export const complexPassword = (value) => {
  if (value.length < 8) {
    return false
  }
  if (!/[A-Z]/.test(value)) {
    return false
  }
  if (!/[a-z]/.test(value)) {
    return false
  }
  if (!/\d/.test(value)) {
    return false
  }
  if (!/[_!@#$%*()=+{}:',.?/-]/.test(value)) {
    return false
  }
  return true
}

export const passwordRequirements = [
  'Be at least 8 characters long',
  'Upper and lower case letters',
  'Numeral (0-9)',
  'Special Characters: _ ! @ # $ % * ( ) – = + { } : \' , . ? /',
]
