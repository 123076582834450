import Vue from 'vue'
import Vuex from 'vuex'

// Vuex Modules

import auth from '@/store/module.auth'
import learn from '@/store/module.learn'
import practice from '@/store/module.practice'
import compete from '@/store/module.compete'
import team from '@/store/module.team'
import metadata from '@/store/module.metadata'
import badges from '@/store/module.badges'
import search from '@/store/module.search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    learn,
    practice,
    compete,
    team,
    metadata,
    badges,
    search,
  },
})
