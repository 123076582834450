import Axios from 'axios'
import store from '../../store/index'

export default {
  async getAllChallenges () {
    try {
      const response = await Axios.get('/compete/challenges')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getAllCategories () {
    try {
      const response = await Axios.get('/compete/categories')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getAllContests () {
    try {
      const response = await Axios.get('/compete/contests')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async searchContests (query, limit = 10) {
    try {
      const response = await Axios.get('compete/contests/search', {
        params: {
          query: query,
          limit: limit,
        },
      })
      return response.data.data
    } catch (err) {
      console.error(err)
      return undefined
    }
  },
  async getAllScoreboards () {
    try {
      const response = await Axios.get('/scoreboard/all')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getGameBoard () {
    const userId = store.getters['auth/user'].id
    try {
      const response = await Axios.get(`/users/${userId}/board`)
      return { categories: response.data.data.categories }
    } catch (err) {
      return undefined
    }
  },
  async generateNewGameBoard () {
    const userId = store.getters['auth/user'].id
    try {
      const response = await Axios.post(`/users/${userId}/board`)
      return { categories: response.data.data.categories }
    } catch (err) {
      return undefined
    }
  },
  async checkAnswer (challengeId, answer) {
    try {
      const response = await Axios.post(`/compete/challenges/${challengeId}/verify`, { answer })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getScoresForScoreboard (id) {
    try {
      const response = await Axios.get(`/scoreboard/${id}/scores`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getAllTeamScoresForAllContests () {
    try {
      const response = await Axios.get('/scoreboard/all-teams')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getSingleTeamScoresForAllContests (teamId) {
    try {
      const response = await Axios.get(`/scoreboard/team/${teamId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getAllTeamScoresForScoreboard (scoreboardId) {
    try {
      const response = await Axios.get(`/scoreboard/${scoreboardId}/team-scores`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getSingleTeamScoresForScoreboard (scoreboardId, teamId) {
    try {
      const response = await Axios.get(`/scoreboard/${scoreboardId}/team/${teamId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async fetchUserScoreForContest (contestId, userId) {
    try {
      const response = await Axios.get(`/scoreboard/contest-score/${contestId}/${userId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async fetchHighScores (count = 3) {
    try {
      const response = await Axios.get(`/scoreboard/high-scores?count=${count}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async fetchScoreboard () {
    try {
      const response = await Axios.get('/scoreboard')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getAllAssessments () {
    try {
      const response = await Axios.get('/compete/assessments')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async searchAssessments (query, limit = 10) {
    try {
      const response = await Axios.get('compete/assessments/search', {
        params: {
          query: query,
          limit: limit,
        },
      })
      return response.data.data
    } catch (err) {
      console.error(err)
      return undefined
    }
  },
  async getUserSubmissions (userId) {
    try {
      const response = await Axios.get(`/compete/assessments/${userId}/user-submissions`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async startAssessment (assessmentId) {
    try {
      const response = await Axios.post(`/compete/assessments/${assessmentId}/start`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async submitAssessment (assessmentId, answers) {
    try {
      const response = await Axios.post(`/compete/assessments/${assessmentId}/submit`, { submittedAnswers: answers })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
}
