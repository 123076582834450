<template>
  <div v-if="siteMetadata"
    class="w-full h-full min-h-screen flex flex-col bg-th-primary justify-center items-center"
  >
    <Toast class="w-1/4" type="danger" v-model="showLoginError" :autoClose="5">
      <template v-slot:title>login error</template>
      <template v-slot:msg>{{ errorMessage }}</template>
    </Toast>
    <!-- <BrandMark :title="siteMetadata !== undefined ? siteMetadata.portalTitle : ''" /> -->
    <BrandMark
      class="mt-8"
      :title="this.siteMetadata.portalTitle"
      :logoSize="this.siteMetadata.logoSize"
    />
    <div
      class="w-full px-10 sm:px-0 sm:w-2/3 md:w-3/5 lg:w-1/4 flex flex-col flex-grow"
      style="max-width: 375px"
    >
      <form
        class="flex flex-col items-center space-y-4 mt-6"
        @submit.prevent="attemptLogin"
      >
        <div class="flex flex-col w-full">
          <span class="text-th-text text-opacity-50 uppercase pb-2">Email Address</span>
          <input
            type="text"
            v-model="email"
            autocomplete="username"
            class="bg-th-secondary text-th-text-secondary rounded text-2xl border-2 border-th-text-secondary px-2 py-1 text-center focus:outline-none"
          />
        </div>
        <div class="flex flex-col w-full pb-8 mb-4 border-b-2 border-th-text">
          <span class="text-th-text text-opacity-50 uppercase pb-2">Password</span>
            <input
              type="password"
              v-model="password"
              autocomplete="current-password"
              class="bg-th-secondary text-th-text-secondary rounded text-2xl border-2 border-th-text-secondary px-2 py-1 text-center focus:outline-none"
            />
        </div>
        <input
          type="submit"
          value="login"
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 w-full font-bold text-center rounded cursor-pointer uppercase focus:outline-none"
        />
      </form>
      <div class="flex w-full space-x-2 text-xs uppercase mt-4">
        <div
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 w-full font-bold text-center rounded cursor-pointer"
          @click="$router.push({ name: 'forgot-password' })"
        >
          Forgot Password
        </div>
        <div
        v-if="!siteMetadata.userRegistrationDisabled"
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 w-full font-bold text-center rounded cursor-pointer"
          @click="$router.push({ name: 'register' })"
        >
          Register
        </div>
      </div>
      <div
        v-if="!siteMetadata.paywallEnabled"
        class="text-th-text-secondary bg-th-secondary border-2 border-th-text-secondary px-4 py-2 w-full font-bold text-center rounded cursor-pointer text-xs uppercase mt-4"
        @click="$router.push({ name: 'main' })"
      >Proceed without registering</div>
    </div>
    <CopyrightFooter class="mt-8" :copyrightText="siteMetadata.copyrightBy" />
  </div>
</template>

<script>
import AuthService from 'auth-service'

export default {
  props: {
    redirect: {
      type: String,
      default: 'main',
    },
    oauthData: {
      type: Object,
      default: () => {},
    },
  },
  created () {
    this.fetchSiteMetadata()
  },
  data () {
    return {
      email: '',
      password: '',
      errorMessage: '',
      showLoginError: false,
      passwordFieldType: 'password',
      siteMetadata: undefined,
    }
  },
  methods: {
    async attemptLogin () {
      // first check if domain is approved to login
      if (
        this.siteMetadata.registrationDomains.length > 0 &&
        !this.siteMetadata.registrationDomains.includes(this.email.split('@')[1])
      ) {
        this.errorMessage = 'Invalid email domain'
        this.showLoginError = true
        return
      }
      const result = await AuthService.login(this.email, this.password)
      if (result.success) {
        const redirect = { name: this.redirect }
        if (this.redirect === 'oauth-authorize') {
          // eslint-disable-next-line dot-notation
          redirect['params'] = { oauthData: this.oauthData }
        }
        this.$router.replace(redirect)
        return
      } else {
        if (
          result.errors.find((error) => {
            return error.type === 'EMAIL_NOT_VERIFIED'
          })
        ) {
          this.errorMessage =
            'Email has not been verified. Visit the registration page to resend verification link.'
        } else {
          this.errorMessage = 'Invalid email and password combination'
        }
      }
      this.showLoginError = true
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.title = this.siteMetadata.portalTitle
      document.documentElement.style.setProperty(
        '--primary',
        this.siteMetadata.primaryColor,
      )
      document.documentElement.style.setProperty(
        '--secondary',
        this.siteMetadata.secondaryColor,
      )
      document.documentElement.style.setProperty(
        '--navbar',
        this.siteMetadata.navbarColor,
      )
      document.documentElement.style.setProperty(
        '--text-navbar',
        this.siteMetadata.navbarTextColor,
      )
      document.documentElement.style.setProperty(
        '--accent',
        this.siteMetadata.accentColor,
      )
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty(
        '--text-secondary',
        this.siteMetadata.secondaryTextColor,
      )
      document.documentElement.style.setProperty(
        '--notification',
        this.siteMetadata.notificationColor,
      )
    },
  },
}
</script>

<style lang="postcss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  /* -webkit-text-fill-color: #f7eace !important;
  -webkit-box-shadow: 0 0 0px 1000px #a4895c inset; */
  transition: background-color 5000s ease-in-out 0s;
}
</style>
