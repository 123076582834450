<template>
  <div class="w-full px-4 py-2">
    <!-- secondary and border -->
    <div
      class="hover:bg-opacity-80 flex flex-col content-end px-4 py-2 bg-th-secondary border-th-text-secondary border-2 rounded-md shadow overflow-hidden"
      style="min-height: 16rem"
    >
      <!-- @click="$emit('click',item._id)" -->
      <div class="text-xl font-bold pb-2 mb-2 border-b-2 border-th-text-secondary flex flex-row items-center">
        <div class="flex-grow text-th-text-secondary">{{title}}</div>
      </div>
      <div class="text-sm font-hairline text-th-text-secondary">{{teaser}}</div>
          <div class="flex flex-col flex-grow justify-end">
            <div class="flex-1"  />
            <div class="text-sm text-th-text-secondary px-2 py-1 mr-2 mb-2">Contest Start: {{startDate}}</div>
            <div v-show="endDate" class="text-sm text-th-text-secondary px-2 py-1 mr-2 mb-2">Contest End: {{endDate}}</div>
          </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title () {
      return this.item.title
    },
    teaser () {
      return this.item.description ? this.item.description : this.item.title
    },
    startDate () {
      var contestStart = new Date(!this.item.startDate ? this.item.createdAt : this.item.startDate)
      return contestStart.toString().split(' ').slice(1, 4).join(' ')
    },
    endDate () {
      var contestEnd = this.item.endDate !== null ? new Date(this.item.endDate) : undefined
      if (contestEnd) {
        return contestEnd.toString().split(' ').slice(1, 4).join(' ')
      } else {
        return contestEnd
      }
    },
    moduleIcon () {
      switch (this.type) {
        case 'code':
          return 'ExternalLinkIcon'
        case 'trivia':
          return 'ExternalLinkIcon'
      }
      return undefined
    },
  },
}
</script>

<style lang="postcss">
.card-shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 1);
}
</style>
