<template>
  <div
    v-if="visible"
    class="bg-opacity-75 fixed inset-0 z-40 bg-black flex lg:items-center lg:justify-center text-th-text-secondary"
  >
    <div
      class="w-full lg:w-1/2 mx-6 mt-6 mb-auto lg:mt-auto bg-th-secondary rounded-lg border-4 border-th-text-secondary relative"
    >
      <CloseIcon class="cursor-pointer absolute right-0 w-8 h-8 text-th-text-secondary fill-current mr-2 mt-2" @click="close" />
      <div class="w-full h-full p-6 pt-8">
        <div class="flex flex-row items-center border-b border-th-text-secondary pb-4 mb-4">
          <div class="text-xl font-bold">Create Collection</div>
        </div>
        <div>
          <div class="flex flex-col w-full">
            <span class="uppercase pb-2 text-sm">Collection Name</span>
            <input
              type="text"
              v-model="collectionName"
              autocomplete="off"
              :disabled="submitted"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
            />
            <span class="uppercase pb-2 mt-4 text-sm">Collection Description</span>
            <input
              type="text"
              v-model="collectionDescription"
              autocomplete="off"
              :disabled="submitted"
              class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
            />
          </div>
        </div>
        <div class="mt-3">
          <div
            v-if="!submitted"
            v-on="validated ? {click: ()=>{submit()}} : {}"
            class="bg-th-text-secondary text-th-secondary px-4 py-2 w-full font-bold text-center rounded-md uppercase"
            :class="{'opacity-50' : !validated, 'opacity-100 cursor-pointer': validated}"
          >Create</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-x.svg'

export default {
  components: {
    CloseIcon,
  },
  computed: {
    validated () {
      return this.collectionName && this.collectionDescription && this.collectionName !== '' && this.collectionDescription !== ''
    },
  },
  data () {
    return {
      visible: false,
      item: undefined,
      categoryId: undefined,
      collectionName: undefined,
      collectionDescription: undefined,
      result: undefined,
      submitted: false,
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.collectionName = ''
      this.collectionDescription = ''
      this.visible = false
    },
    submit () {
      this.$emit('submit', Object.assign(this.item || {}, { name: this.collectionName, description: this.collectionDescription }))
    },
  },
}
</script>

<style>
</style>
