<template>
  <div>
    <div class="text-4xl font-bold mb-4">Scoreboard</div>
    <div class="flex flex-col md:flex-row">
      <SelectInput
        class="mb-4 w-96"
        v-model="selectedScoreboard"
        inputLabel="Scoreboard"
        :options="['All Contests', ...scoreboards.map(scoreboard => scoreboard.title)]"
      />
      <SelectInput
        class="mb-4 w-96"
        v-model="selectedSortOption"
        inputLabel="Filter By"
        :options="['All Users', 'All Teams', ...userTeams.map(team => team.name) ]"
      />
    </div>
    <div class="text-th-text hidden lg:block">
      <div
        class="flex flex-row items-center text-xl text-center justify-center uppercase font-bold pb-4 border-b-2 border-th-text"
      >
        <div class="w-1/12">Rank</div>
        <div class="flex-1">Name</div>
        <div class="flex-1">Score</div>
      </div>
      <div class="pt-6">
        <div v-show="!fetchingScores" class="pb-1">
          <div class="flex flex-col space-y-3">
            <div
              v-for="currentUser in currentUsers"
              :key="currentUser.id"
              class="flex flex-row items-center text-2xl h-20 text-center justify-center bg-th-secondary border-th-text-secondary text-th-text-secondary border-2 rounded-lg"
            >
              <div class="w-1/12">#{{currentUser.rank}}</div>
              <div
                class="flex-1"
              >{{ selectedSortOption === 'All Teams' ? `${currentUser.name}` : `${currentUser.firstName} ${currentUser.lastName}`}}</div>
              <div
                class="flex-1"
              >{{ currentUser.score }}</div>
            </div>
          </div>
        </div>
        <div v-show="!fetchingScores">
          <div
            v-for="(player, index) in selectedScores"
            :key="`player-row-${index}`"
            class="flex flex-row items-center text-2xl text-center justify-center h-20 border-th-text border-t-2 first:border-t-0"
          >
            <div class="w-1/12">#{{player.rank}}</div>
            <div
              class="flex-1"
            >{{ selectedSortOption === 'All Teams' ? `${player.name}` : `${player.firstName} ${player.lastName}`}}</div>
            <div
              class="flex-1"
            >{{ player.score }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:hidden">
      <div v-show="!fetchingScores" class="mb-2">
        <div v-for="currentUser in currentUsers" :key="currentUser.id" class="space-y-2">
          <ScoreboardCard
            :scoreboardView="true"
            :currentUser="true"
            :item="{name: selectedSortOption !== 'All Teams' ? `${currentUser.firstName} ${currentUser.lastName}` : `${currentUser.name}`, rank: currentUser.rank, score: currentUser.coinCounts, overallScore: currentUser.score}"
          />
        </div>
      </div>
      <div v-show="!fetchingScores" class="space-y-2">
        <ScoreboardCard
          v-for="(player, index) in selectedScores"
          :key="`player-card-${index}`"
          :scoreboardView="true"
          :item="{name: selectedSortOption !== 'All Teams' ? `${player.firstName} ${player.lastName}` : `${player.name}`, rank: player.rank, score: player.coinCounts, overallScore: player.score}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScoreboardCard from '@/components/scoreboard/ScoreboardCard.vue'
import SelectInput from '@/components/utilities/SelectInput.vue'
import CompeteService from 'compete-service'

export default {
  async mounted () {
    await this.$store.dispatch('compete/fetchScores')
    await this.$store.dispatch('compete/fetchScoreboards')
    if (this.scoreboardId) {
      const sb = this.scoreboards.find(e => { return e._id === this.scoreboardId })
      if (sb) { this.selectedScoreboard = sb.title }
    }

    if (this.filter) {
      if (this.filter === 'allTeams') { this.selectedSortOption = 'All Teams' } else {
        const f = this.userTeams.find(e => { return e._id === this.filter })
        if (f) { this.selectedSortOption = f.name }
      }
    }

    this.setScores()
  },
  components: {
    ScoreboardCard, SelectInput,
  },
  props: {
    scoreboardId: {
      type: String,
      required: false,
    },
    filter: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      selectedScoreboard: 'All Contests',
      selectedSortOption: 'All Users',
      selectedScores: [],
      currentUsers: [],
      fetchingScores: false,
    }
  },
  computed: {
    userTeams () {
      const { teams } = this.$store.getters['auth/user']
      return teams
    },
    scores () {
      return this.$store.getters['compete/allScores']
    },
    scoreboards () {
      return this.$store.getters['compete/scoreboards']
    },
  },
  watch: {
    selectedScoreboard () {
      this.updatePath()
      this.setScores()
    },
    selectedSortOption () {
      this.updatePath()
      this.setScores()
    },
  },
  methods: {
    async setScores () {
      let teamId
      this.fetchingScores = true
      if (this.selectedScoreboard === 'All Contests') {
        switch (this.selectedSortOption) {
          case 'All Users':
            this.selectedScores = this.scores
            break
          case 'All Teams':
            this.selectedScores = await CompeteService.getAllTeamScoresForAllContests()
            break
          default:
            teamId = this.userTeams.find(team => team.name === this.selectedSortOption)._id
            this.selectedScores = await CompeteService.getSingleTeamScoresForAllContests(teamId)
        }
      } else {
        let newScores
        const scoreboardId = this.scoreboards.find(scoreboard => scoreboard.title === this.selectedScoreboard)._id
        switch (this.selectedSortOption) {
          case 'All Users':
            this.selectedScores = await CompeteService.getScoresForScoreboard(scoreboardId)
            break
          case 'All Teams':
            newScores = await CompeteService.getAllTeamScoresForScoreboard(scoreboardId)
            this.selectedScores = [...newScores]
            break
          default:
            teamId = this.userTeams.find(team => team.name === this.selectedSortOption)._id
            this.selectedScores = await CompeteService.getSingleTeamScoresForScoreboard(scoreboardId, teamId)
        }
      }
      const user = this.$store.getters['auth/user']
      if (this.selectedSortOption !== 'All Teams') {
        const userScore = this.selectedScores.find(item => {
          return item.id === user._id
        })
        this.currentUsers = [userScore]
      } else {
        this.currentUsers = this.selectedScores.filter(score => user.teams.map(team => team._id).includes(score.id))
      }
      this.fetchingScores = false
    },
    updatePath () {
      let path = this.$route.path
      let scoreboardParam = null
      let filterParam = null
      if (this.selectedScoreboard !== 'All Contests') {
        scoreboardParam = this.scoreboards.find(scoreboard => scoreboard.title === this.selectedScoreboard)?._id
      }

      if (this.selectedSortOption !== 'All Users') {
        if (this.selectedSortOption === 'All Teams') {
          filterParam = 'allTeams'
        } else {
          filterParam = this.userTeams.find(team => team.name === this.selectedSortOption)?._id
        }
      }

      if (scoreboardParam) {
        path += `?scoreboard=${scoreboardParam}`
        if (filterParam) { path += `&filter=${filterParam}` }
      } else if (filterParam) { path += `?filter=${filterParam}` }

      history.replaceState(
        {},
        null,
        path,
      )
    },
  },
}
</script>

<style>
</style>
