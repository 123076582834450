<template>
  <div class="w-full h-full min-h-screen flex flex-col justify-center items-center">
    <Toast width="w-1/3" type="danger" v-model="showSubmissionError" :autoClose="5">
      <template v-slot:title>An Error Occurred</template>
      <template
        v-slot:msg
      >There was an issue with verifying your account. Please try again or contact a system administrator.</template>
    </Toast>
    <BrandMark class="mb-8" :title="siteMetadata !== undefined ? siteMetadata.portalTitle : ''" :logoSize="siteMetadata !== undefined ? siteMetadata.logoSize : 'medium'" />
    <div style="max-width:650px" class="flex flex-col space-y-6 px-8">
      <div class="text-th-text text-opacity-30 text-4xl font-extrabold uppercase">Verify Email</div>
      <div v-if="submissionSuccess" class="space-y-6">
        <div>
          <div
            class="text-opacity-60 text-th-text"
          >The account was successfully verified. Please return to the login screen to login.</div>
        </div>
        <div
          class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          @click="$router.replace({name: 'login'})"
        >Return to Login</div>
      </div>
      <div v-if="!submissionSuccess" class="space-y-6">
        <div class="text-opacity-60 text-th-text">Provide the email address and password associated with your account.</div>
        <div class="flex flex-col w-full">
          <span class="text-th-text text-opacity-50 uppercase pb-2">Email Address</span>
          <input
            type="text"
            v-model="$v.email.$model"
            autocomplete="username"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
          />
        </div>
        <div class="flex flex-col w-full pb-8 mb-4 border-b border-th-text">
          <span class="text-th-text text-opacity-50 uppercase pb-2">Password</span>
          <div class="flex items-center rounded">
            <input
              type="password"
              v-model="$v.password.$model"
              autocomplete="current-password"
              class="flex-grow bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary rounded text-2xl px-2 py-1 text-center focus:outline-none"
            />
          </div>
        </div>
        <div class="flex justify-between">
          <div
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
            @click="$router.replace({name: 'login'})"
          >Cancel</div>
          <div
            @click="processVerifyAccount"
            class="bg-th-secondary text-th-text-secondary border-2 border-th-text-secondary px-4 py-2 font-bold text-center rounded-md cursor-pointer uppercase"
          >Verify Account</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AuthService from 'auth-service'

export default {
  props: ['ident', 'today', 'token'],
  computed: {
    errors () {
      return this.$v.$dirty
    },
  },
  created () {
    this.fetchSiteMetadata()
  },
  data () {
    return {
      submissionSuccess: false,
      submissionError: false,
      showSubmissionError: false,
      email: '',
      password: '',
      siteMetadata: undefined,
    }
  },
  validations: {
    email: {
      required, email,
    },
    password: {
      required,
    },
  },
  methods: {
    async processVerifyAccount () {
      this.submissionError = false
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Error....')
        this.submissionError = true
        return
      }

      const success = await AuthService.verifyAccount(this.ident, this.today, this.token, this.$v.email.$model, this.$v.password.$model)
      if (success) {
        this.submissionSuccess = true
      } else {
        // Show an error toast
        this.showSubmissionError = true
      }
    },
    async fetchSiteMetadata () {
      this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      if (!this.siteMetadata) {
        await this.$store.dispatch('metadata/fetchSiteMetadata')
        this.siteMetadata = this.$store.getters['metadata/siteMetadata']
      }
      document.documentElement.style.setProperty('--primary', this.siteMetadata.primaryColor)
      document.documentElement.style.setProperty('--secondary', this.siteMetadata.secondaryColor)
      document.documentElement.style.setProperty('--navbar', this.siteMetadata.navbarColor)
      document.documentElement.style.setProperty('--accent', this.siteMetadata.accentColor)
      document.documentElement.style.setProperty('--text', this.siteMetadata.textColor)
      document.documentElement.style.setProperty('--text-secondary', this.siteMetadata.secondaryTextColor)
      document.documentElement.style.setProperty(
        '--notification',
        this.siteMetadata.notificationColor,
      )
    },
  },
}
</script>

<style>
</style>
